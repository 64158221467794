// @flow

import { List } from 'immutable';
// actions
import * as DocumentActions from '../documents/documentsActions';
import * as ApprovalActions from './actions';

import { rearrangeList } from 'lib/helpers';

// factories
import { ApprovalsStoreAdapter, PrevStateFactory } from './helpers';

// types
import type { ApprovalsRecord } from './types.js.flow';

type Action = {|
  type: typeof DocumentActions,
  payload: mixed,
|};

const MergeFactory = (approvals, state) =>
  approvals
    .setIn(['prevState'], state.prevState)
    .setIn(['rearrangingInProcess'], state.rearrangingInProcess)
    .setIn(['approvalGroups'], state.approvalGroups)
    .setIn(['approvalGroupsLoaded'], state.approvalGroupsLoaded)
    .setIn(['advancedApprovalGroups'], state.advancedApprovalGroups)
    .setIn(['advancedApprovalGroupsLoaded'], state.advancedApprovalGroupsLoaded)
    .setIn(['approvers'], state.approvers)
    .setIn(['approversLoaded'], state.approversLoaded);

export const reducer = {
  approvals(state: ApprovalsRecord = ApprovalsStoreAdapter(), action: Action) {
    switch (action.type) {
      case DocumentActions.documentGet.success:
      case DocumentActions.documentUpdate.success: {
        return MergeFactory(
          state
            // $FlowFixMe untyped payloads
            .merge(action.payload.document.approvals),
          state,
        );
      }
      // case signOutAction.success:
      //   return ApprovalsFactory();

      case ApprovalActions.changeOrderApprovalAction.type: {
        const { oldIndex, newIndex } = action.payload;

        return state
          .setIn(['prevState'], PrevStateFactory(state))
          .updateIn(['list'], (list) => rearrangeList({ oldIndex, newIndex, list }))
          .updateIn(['rearrangingInProcess'], (c) => c + 1);
      }

      case ApprovalActions.createApprovalAction.success:
      case ApprovalActions.updateApprovalAction.success:
      case ApprovalActions.removeApprovalAction.success:
      case ApprovalActions.addAdvancedApprovalGroupAction.success:
      case ApprovalActions.removeAdvancedApprovalGroupAction.success:
      case ApprovalActions.submitApprovalAction.success:
      case ApprovalActions.rejectApprovalAction.success: {
        return MergeFactory(
          state
            // $FlowFixMe untyped payloads
            .merge(action.payload),
          state,
        );
      }
      case ApprovalActions.changeOrderApprovalAction.success: {
        const process = state.rearrangingInProcess;
        return MergeFactory(state.merge(action.payload), state)
          .setIn(['rearrangingInProcess'], process ? process - 1 : 0)
          .updateIn(['list'], (l) => (state.rearrangingInProcess < 2 ? l : state.list))
          .setIn(
            ['prevState'],
            state.rearrangingInProcess < 2 ? ApprovalsStoreAdapter().prevState : PrevStateFactory(action.payload),
          );
      }
      case ApprovalActions.changeOrderApprovalAction.failure: {
        const newState =
          state.rearrangingInProcess < 2
            ? state.setIn(['list'], state.prevState.list).setIn(['prevState'], ApprovalsStoreAdapter().prevState)
            : state;
        return newState.updateIn(['rearrangingInProcess'], (c) => (c ? c - 1 : 0));
      }
      case ApprovalActions.approveApprovalAction.success:
        return (
          state
            // $FlowFixMe untyped payloads
            .setIn(['list'], action.payload)
        );

      case ApprovalActions.getApprovalApproveReasonsAction.success:
      case ApprovalActions.updateApprovalApproveReasonsAction.success:
        return (
          state
            // $FlowFixMe untyped payloads
            .set('approveFormFieldsList', action.payload)
        );

      case ApprovalActions.getApprovalGroupsAction.success: {
        return (
          state
            // $FlowFixMe untyped payloads
            .set('approvalGroups', action.payload)
            .set('approvalGroupsLoaded', true)
        );
      }
      case ApprovalActions.clearApprovalStatesAction.type: {
        return (
          state
            // $FlowFixMe untyped payloads
            .set('approvalGroups', new List())
            .set('approvalGroupsLoaded', false)
            .set('advancedApprovalGroups', new List())
            .set('advancedApprovalGroupsLoaded', false)
            .set('approvers', new List())
            .set('approversLoaded', false)
        );
      }

      case ApprovalActions.createApprovalsGroupAction.success: {
        return state.updateIn(['approvalGroups'], (g) => g.unshift(action.payload));
      }

      case ApprovalActions.deleteApprovalsGroupAction.success:
        return state.updateIn(['approvalGroups'], (groups) =>
          groups.filter((group) => {
            const { groupId } = action.payload;
            return group.group_id !== groupId;
          }),
        );

      case ApprovalActions.updateApprovalsGroupAction.success: {
        return state.updateIn(['approvalGroups'], (groups) =>
          groups.set(
            groups.findIndex((group) => group.group_id === action.payload.group_id),
            action.payload,
          ),
        );
      }

      case ApprovalActions.getApproversAction.success: {
        return state.set('approvers', action.payload).set('approversLoaded', true);
      }

      case ApprovalActions.createApproverAction.success: {
        return state.updateIn(['approvers'], (a) => a.push(action.payload));
      }

      case ApprovalActions.deleteApproverAction.success:
        return state.updateIn(['approvers'], (approversList) =>
          approversList.filter((approver) => {
            const { approverId } = action.payload;
            return approver.id !== approverId;
          }),
        );

      case ApprovalActions.getAdvancedApprovalGroupsAction.success: {
        return state.set('advancedApprovalGroups', action.payload).set('advancedApprovalGroupsLoaded', true);
      }

      case ApprovalActions.createAdvancedApprovalsGroupAction.success: {
        return state.updateIn(['advancedApprovalGroups'], (g) => g.unshift(action.payload));
      }

      case ApprovalActions.deleteAdvancedApprovalsGroupAction.success:
        return state.updateIn(['advancedApprovalGroups'], (groups) =>
          groups.filter((group) => {
            const { id } = action.payload;
            return group.id !== id;
          }),
        );

      case ApprovalActions.updateAdvancedApprovalsGroupAction.success: {
        return state.updateIn(['advancedApprovalGroups'], (groups) =>
          groups.set(
            groups.findIndex((group) => group.id === action.payload.id),
            action.payload,
          ),
        );
      }

      default:
        return state;
    }
  },
};

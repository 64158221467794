// @flow
import React from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import elements from 'components/elements';

import { alpha } from '@mui/material/styles';
import { useTheme } from '@mui/material';

type TEmptyScreen = {
  title: string,
  description: string,
  buttonText: string,
  onClick: () => void,
};

const EmptyScreen: React$StatelessFunctionalComponent<TEmptyScreen> = ({ title, description, buttonText, onClick }) => {
  const theme = useTheme();

  return (
    <Stack justifyContent="center" alignItems="center" flex="1" my={7}>
      <Typography
        variant="h6"
        color="primary"
        fontWeight={500}
        data-element={elements.configuration.company.approvals.emptyScreen.title}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        color={alpha(theme.palette.common.black, 0.6)}
        sx={{ mb: 3.25, textAlign: 'center' }}
        width={400}
        data-element={elements.configuration.company.approvals.emptyScreen.description}
      >
        {description}
      </Typography>
      <Button
        startIcon={<AddOutlinedIcon />}
        onClick={onClick}
        data-element={elements.configuration.company.approvals.emptyScreen.createButton}
      >
        {buttonText}
      </Button>
    </Stack>
  );
};

export default EmptyScreen;

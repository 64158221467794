// @flow
// $FlowFixMe outdated react types
import React, { useState, useCallback, useEffect } from 'react';
// $FlowFixMe outdated react types
import { useDispatch } from 'react-redux';
// $FlowFixMe outdated react types
import { useIntl, FormattedMessage } from 'react-intl';

import { styled } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
// import SortableList from './dnd/SortableList';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ListItemTextMui from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Stack from '@mui/material/Stack';

import { filterOptions, messages } from 'hooks/approval/useApprovals';
import elements from 'components/elements';

import Autocomplete from 'pages/components/ApproversAutocomplete';
import Tooltip from 'components/mui/Tooltip';
import Approver from 'pages/components/Approver';
import SortableList from 'pages/document/DocumentTopPanel/approval/dnd/SortableList';
import { arrayMove } from '@dnd-kit/sortable';
import { getApprovalGroupsAction } from 'domain/approvals/actions';

const MenuList = styled('ul')(() => ({ padding: 0 }));
const ListItemText = styled(ListItemTextMui)(() => ({ whiteSpace: 'normal', wordWrap: 'break-word' }));

const useStyles = makeStyles((theme) =>
  createStyles({
    bodyTags: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: '1 1 100%',
    },
    approvalDraggableElem: {
      outline: `2px solid ${theme.palette.primary.main}`,
    },
  }),
);

type Props = {|
  processedValue: Array<*>,
  approvers: Array<*>,
  currentValue: Array<*>,
  setValue: (value: any) => Array<*>,
  setGroupId: (groupId: string) => void,
  groupId: string,
|};

const StandalontApprovalManager: React$StatelessFunctionalComponent<Props> = ({
  processedValue,
  approvers,
  setValue,
  currentValue,
  setGroupId,
  groupId,
}) => {
  const { formatMessage } = useIntl();
  // @to-do move to useApprovals hook
  const approvalsLimit = 8;
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getApprovalGroupsAction());
  }, []);

  // eslint-disable-next-line no-unused-vard
  const onChange = useCallback(
    (_, value) => {
      if (groupId) {
        setGroupId('');
      }
      // in case of group selection convert group to array
      // of corresponding approvers
      const groupToApprovers = value
        .map((entity) => {
          if (!entity?.group_id) {
            return entity;
          } else {
            if (!currentValue.length) {
              setGroupId(entity.group_id);
            }
            return entity.approvers;
          }
        })
        .flat();

      if (groupToApprovers.length > approvalsLimit) {
        groupToApprovers.length = approvalsLimit;
      }

      setValue(groupToApprovers);
    },
    [currentValue],
  );

  const handleDelete = (id) => {
    if (groupId) {
      setGroupId('');
    }
    setValue((current) => current.filter((value) => value.id !== id));
  };

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setValue((list) => arrayMove(list, oldIndex, newIndex));
  }, []);

  const groupDisabled = useCallback((group) =>
    group.approvers.every(({ id }) => processedValue.map(({ id }) => id).includes(id)),
  );

  return (
    <Autocomplete
      fullWidth
      margin="normal"
      noOptionsText={
        processedValue.length + 1 <= approvalsLimit ? (
          <FormattedMessage
            id="document.approval.noSuggestions"
            defaultMessage="No more users available for selection"
          />
        ) : (
          <FormattedMessage
            id="document.approval.limitReached"
            defaultMessage={`${approvalsLimit} users limit exceeded`}
            values={{ limit: approvalsLimit }}
          />
        )
      }
      options={processedValue.length + 1 <= approvalsLimit ? approvers : []}
      getOptionLabel={(o) => o.group_id || o.id}
      groupBy={(option) => option.group}
      value={processedValue}
      onChange={onChange}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          variant="filled"
          size="small"
          data-element={elements.content.documents.approvals.input}
          label={formatMessage({
            id: 'configurations.company.approvals.groups.select.placeholder',
            defaultMessage: 'Select approvers...',
          })}
        />
      )}
      getOptionDisabled={(o) => !!(o.group_id && o.approvers.every((a) => processedValue.some((v) => v.id === a.id)))}
      renderOption={(params, option, { selected, disabled }) =>
        option.group_id ? (
          <MenuItem
            {...params}
            selected
            disabled={groupDisabled(option)}
            data-element={elements.content.documents.approvals.autocompleteOptions.approvalGroupItem.container}
          >
            <ListItemText
              data-element={elements.content.documents.approvals.autocompleteOptions.approvalGroupItem.name}
            >
              {option.group_title}
            </ListItemText>
            <AvatarGroup
              max={8}
              data-element={elements.content.documents.approvals.autocompleteOptions.approvalGroupItem.listOfApprovals}
            >
              {option.approvers.map((a) => (
                <Tooltip key={a.id} t={a.fullName}>
                  <Avatar src={a.picture} alt={a.fullName} sx={{ width: 24, height: 24 }} />
                </Tooltip>
              ))}
            </AvatarGroup>
          </MenuItem>
        ) : (
          <MenuItem
            {...params}
            {...{ selected, disabled }}
            data-element={elements.content.documents.approvals.autocompleteOptions.approvalItem}
          >
            <ListItemText>
              {option.fullName}
              <br />({option.id})
            </ListItemText>
          </MenuItem>
        )
      }
      renderTags={(selected) => (
        <SortableList
          items={selected}
          className={classes.bodyTags}
          idKey="id"
          onSortOver={() => {}}
          onSortEnd={onSortEnd}
          Element={(props) => (
            <Stack
              gap={1}
              flexWrap="wrap"
              direction="row"
              mt={1}
              {...props}
              data-element={elements.popup.approvalGroups.selectedApproversContainer}
            >
              {props.children}
            </Stack>
          )}
          helperClass={classes.approvalDraggableElem}
        >
          {({ fullName, picture, id, className, style, idx, dragging }) => (
            <Approver
              avatar={<Avatar alt={fullName} src={picture} />}
              label={fullName}
              variant="outlined"
              onDelete={() => {
                handleDelete(id);
              }}
              className={className}
              style={style}
              hasArrow={idx + 1 !== selected.length && !dragging}
            />
          )}
        </SortableList>
      )}
      renderGroup={(params) => (
        <li
          key={params.group}
          data-element={`${elements.content.documents.approvals.autocompleteOptions.groupsContainer}.${params.group}`}
        >
          <ListSubheader component="div" sx={{ top: (theme) => theme.spacing(-1) }}>
            {formatMessage(messages[params.group], { count: params.children.length })}
          </ListSubheader>
          <MenuList>{params.children}</MenuList>
        </li>
      )}
    />
  );
};

export default StandalontApprovalManager;

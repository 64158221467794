// @flow

import { asyncAction, syncAction } from 'lib/action';

export const signInAction = asyncAction('auth/SIGN_IN', (data) => ({ data }));
export const signInWithOrganizationAction = asyncAction('auth/SIGN_IN_WITH_ORGANIZATION');
export const getUserProfileAction = asyncAction('auth/GET_USER_PROFILE');
export const signOutAction = asyncAction('auth/SIGN_OUT');

// action that cleans sttore but doesnt redirect user to login page
// used for resetpasswd and similar rotesto prevent login redirect
// when user tries to restore password with expired token
export const silentSignOutAction = asyncAction('auth/SILENT_SIGN_OUT');
export const expiredAction = syncAction('auth/SET_EXPIRED');
export const saveOnboardingInfoAction = syncAction('auth/SAVE_ONBOARDING_INFO');
export const setOrganizationIdAction = syncAction('auth/SET_ORGANIZATION_ID');
export const resetPasswordAction = asyncAction('auth/RESET_PASSWORD');
export const checkPasswordAction = asyncAction('auth/CHECK_PASSWORD_TOKEN');
export const updatePasswordAction = asyncAction('auth/UPDATE_PASSWORD');
export const healthCheckAction = asyncAction('env/HEALTH_CHECK');
export const locationChangeAction = syncAction('env/LOCATION_CHANGE');
export const saveBackSearchUrlAction = syncAction('env/SAVE_BACK_SEARCH_URL');
export const savePersistedBackUrlAction = syncAction('env/SAVE_PERSISTED_BACK_URL');
export const setSagaDataLoadingAction = syncAction('env/SET_SAGA_DATA_LOADING');
export const addDataLoadingAction = syncAction('env/ADD_DATA_LOADING');
export const removeDataLoadingAction = syncAction('env/REMOVE_DATA_LOADING');

export const setNavigationAction = syncAction('env/SET_NAVIGATION');
export const updateNavigationAction = syncAction('env/UPDATE_NAVIGATION');
export const clearNavigationAction = syncAction('env/CLEAR_NAVIGATION');
export const documentChangeLinkedWidthAction = syncAction('env/CHANGE_LINKED_WIDTH');
export const documentUpdateViewAction = syncAction('env/UPDATE_VIEW');
export const setGoogleSearchTextAction = syncAction('env/SET_GOOGLE_SEARCH_TEXT');
export const setGooglePanelStatusAction = syncAction('env/SET_GOOGLE_PANEL_STATUS');
export const addAppStatusAction = syncAction('env/ADD_APP_STATUS');
export const removeAppStatusAction = syncAction('env/REMOVE_APP_STATUS');
export const getUserDataComplete = syncAction('env/GET_USER_DATA_COMPLETE');
export const updateUserFeaturesAction = asyncAction('env/UPDATE_USER_FEATURES');
export const updateSupplierAliasesAction = asyncAction('env/UPDATE_SUPPLIER_ALIASES');

export const setTFADataAction = syncAction('auth/SET_TFA_DATA_ACTION');
export const removeTFADataAction = syncAction('auth/REMOVE_TFA_DATA_ACTION');

export const TFASubmitAction = asyncAction('auth/TFA_SUBMIT_ACTION');
export const TFAResetAction = asyncAction('auth/TFA_RESET_ACTION');
export const updateWorkSpaceAction = syncAction('env/UPDATE_WORK_SPACE');
// cognito
export const cognitoAuthInitAction = syncAction('env/COGNITO_AUTH_INIT');
export const cognitoIDpInitiatedLoginAction = syncAction('env/COGNITO_IDP_INITIATED_LOGIN');
export const cognitoCleanAuthDataAction = syncAction('env/COGNITO_CLEAN_AUTH_DATA');

export const passwordLessAuthAccessLinkAction = asyncAction('auth/PASSWORD_LESS_AUTH_ACCESS_LINK');
export const passwordLessAuthLoginAction = asyncAction('auth/PASSWORD_LESS_AUTH_LOGIN');
export const setErrorMessage = syncAction('env/SET_ERROR_MESSAGE');
export const connectionHealthAction = syncAction('env/CONNECTION_HEALTH');

export const requestAccessToModuleAction = asyncAction('env/REQUEST_ACCESS_TO_MODULE');

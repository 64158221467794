/* @flow */
import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Outlet, generatePath, useNavigate } from 'react-router-dom';

import { configurationCompanySelector, companyFeatureSetSelector } from 'domain/companies/companiesSelector';
import ROUTES_PATH from 'domain/router/routesPathConfig';

import Tabs from 'components/Tabs';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import FeatureDisabledMessage from 'pages/configurations/components/FeatureDisabledMessage';

import { APPROVAL_TABS } from './helpers';

const mapStateToProps = (state) => ({
  featureSet: companyFeatureSetSelector(state),
});

const ApprovalsPage = () => {
  const navigate = useNavigate();
  const company = useSelector(configurationCompanySelector);
  const { '*': asterisk, companyId } = useParams();
  const [, section] = asterisk.split('/');

  const { featureSet } = useSelector(mapStateToProps);

  const tabs = useMemo(
    () => [
      {
        to: generatePath(ROUTES_PATH.COMPANY_SETTINGS_APPROVALS_APPROVERS.absolute, { companyId }),
        id: 'configurations.company.approvals.tabs.approvers',
        defaultMessage: 'List of Approvers',
        tabValue: APPROVAL_TABS.APPROVERS,
      },
      {
        to: generatePath(ROUTES_PATH.COMPANY_SETTINGS_APPROVALS_GROUPS.absolute, { companyId }),
        id: 'configurations.company.approvals.tabs.groups',
        defaultMessage: 'Basic Groups',
        tabValue: APPROVAL_TABS.GROUPS,
      },
      {
        to: generatePath(ROUTES_PATH.COMPANY_SETTINGS_APPROVALS_ADVANCED_GROUPS.absolute, { companyId }),
        id: 'configurations.company.approvals.tabs.advancedGroups',
        defaultMessage: 'Advanced Groups',
        tabValue: APPROVAL_TABS.ADVANCED_GROUPS,
      },
      {
        to: generatePath(ROUTES_PATH.COMPANY_SETTINGS_APPROVALS_FLOW.absolute, { companyId }),
        id: 'configurations.company.approvals.tabs.flow',
        defaultMessage: 'Vendor based approval',
        tabValue: APPROVAL_TABS.FLOW,
        disabled: true,
      },
    ],
    [companyId],
  );

  useEffect(() => {
    if (!section && featureSet.get('approvals', false)) {
      navigate(generatePath(ROUTES_PATH.COMPANY_SETTINGS_APPROVALS_APPROVERS.absolute, { companyId }));
    }
  }, [section, companyId, navigate, featureSet]);

  return featureSet.get('approvals', false) ? (
    <Stack alignItems="flex-start" direction="row">
      <Paper variant="outlined" sx={{ width: 270, border: 2, mr: 5, p: 1, pr: 0, borderColor: 'grey.200' }}>
        <Tabs value={section} tabs={tabs} orientation="vertical" textAlign="flex-end" />
      </Paper>
      <Stack component={Paper} flex={1} sx={{ p: 3 }}>
        {company.id ? <Outlet /> : null}
      </Stack>
    </Stack>
  ) : (
    <FeatureDisabledMessage />
  );
};

export default ApprovalsPage;

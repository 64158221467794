import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => {
  const isRtl = theme.direction === 'rtl';
  const LEFT = isRtl ? 'right' : 'left';
  const RIGHT = isRtl ? 'left' : 'right';

  return {
    '@global': {
      '.ag-theme-material': {
        '&& .ag-header': {
          backgroundColor: 'var(--ag-header-background-color)',
          border: '1px solid var(--ag-border-color)',
          borderRadius: 8,

          '& .ag-header-row': {
            '& .ag-header-cell, & .ag-header-group-cell': {
              backgroundColor: 'var(--ag-header-background-color)',

              '&.ag-cell-hover': {
                backgroundColor: 'var(--ag-header-background-color)',
              },
            },
          },
        },
        [`&& .ag-pinned-${LEFT}-cols-container`]: {
          '& .ag-row': {
            '& .ag-cell': {
              borderRadius: '8px 0 0 8px',
              borderLeft: '1px solid var(--ag-border-color)!important',
            },

            '&.ag-row-level-0': {
              borderRight: '1px solid var(--ag-border-color)!important',
              zIndex: 1, // sibling cell overlap divider shadow
            },
          },
        },
        '&& .ag-header-cell-sortable': {
          padding: 0,
        },
        '&& .ag-header-cell-resize:after': {
          content: '""',
          position: 'absolute',
          zIndex: 1,
          left: 'calc(50% - 1px)',
          width: 2,
          height: '30%',
          top: '35%',
          backgroundColor: theme.palette.grey[300],
        },
        '&& .ag-rich-select-list': {
          minWidth: 350,
        },
        '&& .ag-header-cell-label': {
          justifyContent: 'center',
          fontWeight: 'bold',
        },
        '&& .ag-header-group-cell-label': {
          justifyContent: 'center',
          fontWeight: 'bold',
        },
        '&& .cell-negative-number': {
          '& $inputCell': {
            // Minus must be always on the left side DA-5915
            unicodeBidi: 'plaintext',
          },
        },
        '& .ag-center-cols-container': {
          '& .ag-row .ag-cell': {
            padding: theme.spacing(0, 0.5),
            border: 'none',

            '&:last-child': {
              paddingRight: theme.spacing(1),
            },
          },
        },

        '&& .ag-row .ag-cell.__cell-is-readonly': {
          background: theme.palette.grey[200],
          color: '#5A6872',
        },
        '&& .ag-row .ag-cell.ag-cell-inline-editing': {
          boxShadow: 'none',
          border: 'none',
          height: 46,
          padding: 0,
          backgroundColor: 'transparent',
        },
        '&& .ag-body-horizontal-scroll.ag-scrollbar-invisible': {
          pointerEvents: 'all !important',
        },
        '&& .ag-root-wrapper': {
          overflow: 'inherit',
        },
        '& .ag-checkbox-cell': {
          justifyContent: 'center',
        },
      },
    },
  };
});

/* eslint-disable camelcase */
// @flow
import * as React from 'react';
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link, type Match, matchPath } from 'react-router-dom';
import { debounce } from 'throttle-debounce';
import {
  signOutAction,
  saveBackSearchUrlAction,
  isAdminSelector,
  userSelector,
  userNamesSelector,
  isDokkaSupportSelector,
} from 'domain/env';
import type { CurrentUserType } from 'domain/env/types.js.flow';
import {
  /* searchAction, */
  searchTermSelector,
  doesCurrentCompanySupportGeneralDocsOnlySelector,
  companyEnableClientChatFeatureSelector,
} from 'domain/companies';
import { currentCompanySelector, documentHotkeysIsLoadingSelector, documentHotkeysSelector } from 'domain/documents';
import {
  setOpenAction,
  setActiveChannelAction,
  supportUsersSelector,
  chatReadyToRenderOnDocumentSelector,
} from 'domain/chat';

import { type ChatUserRecords } from 'domain/chat/types.js.flow';
import { toggleEventPanelAction } from 'domain/events';
// import { routesById } from 'domain/router/routes';
import * as ACL from 'domain/restriction';
import BarIcon from 'components/BarIcon';
import BarDropdown from 'components/BarDropdown';
import BarDropdownItem from 'components/BarDropdown/Item';
import SearchBar from 'components/Form/Search';
import Tooltip from 'components/Tooltip';
import FullscreenButton from 'pages/common/header__legacy/components/fullscreen';
import Delimiter from 'pages/common/header__legacy/components/delimiter';
import HelpCenterButton from 'pages/common/header__legacy/components/helpCenter';
import HotkeysButton from 'pages/common/header__legacy/components/hotkeys';
import LocaleSelect from 'components/mui/LocaleSelect';
import ChatTop from 'components/ChatTop__legacy';
import Avatar from 'components/mui/Avatar';

import type { THotkeyList } from 'domain/documents/types.js.flow';

import elements from 'components/elements';
import cx from 'classnames';
import { withStyles } from '@mui/styles';
import sheet from './sheet';

// FreshDesk data
import { organizationNameSelector, localeSelector, helpdeskLinkSelector } from 'domain/env/envSelector';
import { currentCompanyNameSelector } from 'domain/companies/companiesSelector';
import { currentConnectedERPselector } from 'domain/settings/settingsSelector';
import type { ERPKeysNameType } from 'domain/settings/types.js.flow';
import openChat from 'lib/freshdeskChat/openFreshDeskChat';

// whiteLabeliing
import type { TLabelOptions } from 'labels/type.js.flow';

export type Props = {
  classes: {
    [key: string]: string,
  },
  signOut: Dispatch<signOutAction>,
  search: (s: string) => void,
  value: string,
  left?: React$Node,
  right?: React$Node,
  center?: React$Node,
  searchBar?: boolean,
  query: {
    [key: string]: string,
  },
  match: {
    ...$Exact<Match>,
    params: {
      companyId?: string,
      documentId?: string,
    },
  },
  // eslint-disable-next-line react/no-unused-prop-types
  isAdmin: boolean,
  companyType?: string,
  white: boolean,
  saveBackUrl?: Dispatch<saveBackSearchUrlAction>,
  isGranted: (r: number | number[]) => boolean,
  // eslint-disable-next-line react/no-unused-prop-types
  disableFinancialFeatures: boolean,
  toggleEventPanel: Dispatch<typeof toggleEventPanelAction>,
  setActiveChannel: Dispatch<typeof setActiveChannelAction>,
  currentCompanyId: ?string,
  currentUser: CurrentUserType,
  userName: {|
    firstName: string,
    lastName: string,
  |},
  supportUsers: ChatUserRecords,
  isScriptLoaded: boolean,
  isScriptLoadSucceed: boolean,
  organizationName: string,
  currentCompanyName: string,
  currentConnectedERP: ERPKeysNameType,
  locale: 'en' | 'he',
  realLocale: 'en' | 'he',
  helpdeskLink: ?string,
  isChatReadyToRenderOnDocument: boolean,
  theme: { labelOptions: TLabelOptions },
  createBtnRef?: (el: Node) => void,
  allowFreshdesk?: boolean,
  isLoadingHotkeys: boolean,
  hotkeys: THotkeyList,
  isEnableChatClient: boolean,
  headerRef?: React.RefObject<HTMLElement>,
  isGhost: boolean,
};

type State = {|
  freshDeskLoaded: boolean,
|};

class Header extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      freshDeskLoaded: false,
    };
  }

  UNSAFE_componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
    const { isScriptLoaded: isCurrentScriptLoadedProp, allowFreshdesk } = this.props;

    if (isCurrentScriptLoadedProp && isScriptLoaded && allowFreshdesk) {
      // load finished
      if (isScriptLoadSucceed) {
        // eslint-disable-next-line no-console
        console.log('Freshdesk script loaded');
        window.FreshworksWidget('hide', 'launcher');
        this.setState({ freshDeskLoaded: true });
      }
    }
  }

  onSearch = (e: SyntheticInputEvent<HTMLInputElement>): void => {
    this.search(e.target.value);
  };

  onClick = () => {
    const { query, match, saveBackUrl, companyType } = this.props;
    if (typeof saveBackUrl === 'function') {
      saveBackUrl({
        query,
        params: {
          ...match.params,
          companyType,
        },
      });
    }
  };

  // eslint-disable-next-line class-methods-use-this
  get isDocumentPage() {
    // return matchPath(
    //   {
    //     end: 'end' in routesById.document.end ? routesById.document.end : true,
    //     path: routesById.document.path.pathname,
    //   },
    //   window.location.pathname,
    // );
  }

  get allowedHelpDropdownItems() {
    const { props, isDocumentPage } = this;
    const { helpdeskLink, hotkeys, isLoadingHotkeys, isGranted } = props;

    return {
      contactSupport: this.allowedContactSupport,
      helpDesk: Boolean(helpdeskLink),
      hotKeys: isDocumentPage && !isLoadingHotkeys && hotkeys.size > 0 && isGranted(ACL.IS_ACCOUNT),
    };
  }

  get allowedContactSupport() {
    const {
      allowFreshdesk,
      theme: {
        labelOptions: { name },
      },
    } = this.props;
    return allowFreshdesk || name === 'AlPrime';
  }

  get isSupplier() {
    const { isGranted } = this.props;
    return isGranted(ACL.IS_SUPPLIER_USER);
  }

  get allowedHelpDropdownItemsCount() {
    if (this.isSupplier) {
      return false;
    }
    return Object.values(this.allowedHelpDropdownItems).filter(Boolean).length;
  }

  // eslint-disable-next-line react/sort-comp
  search = debounce(300, (val: string) => {
    const { search } = this.props;
    search(val);
  });

  logout = () => {
    // explicit log out should not save back url. reason = 403 prevent saving.
    const { signOut } = this.props;
    signOut({
      reason: process.env.REACT_APP_ACCESS_DENIED_CODE,
    });
  };

  sendEmail = () => {
    const {
      theme: {
        labelOptions: { supportEmail },
      },
    } = this.props;
    window.location = `mailto:${supportEmail}`;
  };

  openFreshDeskChat = () => {
    const {
      currentUser: { userId: email },
      userName,
      organizationName,
      currentCompanyName,
      currentConnectedERP,
      locale,
    } = this.props;

    openChat({ email, organizationName, currentCompanyName, currentConnectedERP, userName, locale });
  };

  companyLink = () => {
    const { currentCompanyId, disableFinancialFeatures } = this.props;
    return (disableFinancialFeatures = false);
    // ? `${routesById.companyWithDefaultCategory.path.pathMaker({ companyId: currentCompanyId })}`
    // : `${routesById.companyWithDefaultCategory.path.pathMaker({ companyId: currentCompanyId })}`;
  };

  allowHistory = () => {
    const { match } = this.props;
    const allowRouteNames = ['company', 'companyConfidential', 'document'];
    return [];
    // return allowRouteNames.map((route) => routesById[route].path.pathname).includes(match.path);
  };

  createRef = (el: Node) => {
    const { createBtnRef } = this.props;
    if (createBtnRef) {
      createBtnRef(el);
    }
  };

  renderChatButton = () => {
    const {
      white,
      theme: {
        labelOptions: { name },
      },
    } = this.props;
    const { freshDeskLoaded } = this.state;
    return (
      <BarDropdownItem title="Contact support" titleLangId="header.menu.info.support" isWhite={white}>
        <BarIcon
          disabled={!freshDeskLoaded}
          icon="support"
          white={!white}
          data-element={elements.header.ask.support}
          onClick={name !== 'AlPrime' ? this.openFreshDeskChat : this.sendEmail}
        />
      </BarDropdownItem>
    );
  };

  renderButton =
    (type: string, tooltip: {| id: string, defaultMessage: string |}) => (isOpen: boolean, toggle: () => void) => {
      const { white, classes } = this.props;
      const isWhite = isOpen ? !white : white;
      return (
        <Tooltip {...tooltip} disable={isOpen} className={classes.tooltipPosition}>
          <BarIcon icon={type} white={isWhite} action={toggle} data-element={elements.header[type]} />
        </Tooltip>
      );
    };

  render() {
    const { props, state, allowedHelpDropdownItems, isSupplier, allowedContactSupport } = this;
    const {
      classes,
      left,
      white,
      right,
      center,
      helpdeskLink,
      currentCompanyId,
      isChatReadyToRenderOnDocument,
      match,
      theme: { labelOptions },
      isGhost,
      isEnableChatClient,
      toggleEventPanel,
      headerRef,
      searchBar,
      value,
    } = props;

    const { freshDeskLoaded } = state;
    const { documentId, companyId } = match.params;

    return (
      <header className={classes.header} ref={headerRef}>
        <div className={classes.top}>
          {left || (
            <div className={classes.leftBar}>
              <Link className={classes.title} data-element={elements.header.logo} to="/" />
            </div>
          )}
          {center ? <div className={classes.center}>{center}</div> : null}
          <div className={classes.rightBar}>
            {searchBar ? <SearchBar onChange={this.onSearch} white={white} value={value} /> : null}

            {!isSupplier && <div className={classes.additionalBtnSet} ref={this.createRef} />}

            {right || (
              <div className={classes.mainBtnSet}>
                {!isSupplier && (
                  <div className={classes.menuBox}>
                    <FullscreenButton isWhite={white} />
                    <Delimiter />
                  </div>
                )}
                {currentCompanyId && !isSupplier && (
                  <>
                    {this.allowHistory() && (
                      <>
                        {!isGhost && isEnableChatClient && (
                          <div className={classes.buttonBox}>
                            <ChatTop
                              isDisable={!(!documentId || isChatReadyToRenderOnDocument)}
                              companyId={companyId}
                              documentId={documentId}
                            />
                          </div>
                        )}
                        <div className={classes.buttonBox}>
                          <Tooltip id="header.menu.history" defaultMessage="History">
                            <BarIcon icon="events" action={toggleEventPanel} data-element={elements.header.events} />
                          </Tooltip>
                        </div>
                      </>
                    )}
                  </>
                )}

                {Boolean(this.allowedHelpDropdownItemsCount) && (
                  <div className={classes.menuBox}>
                    <BarDropdown
                      button={this.renderButton(
                        'ask',
                        freshDeskLoaded || !allowedHelpDropdownItems.contactSupport
                          ? {
                              id: 'header.menu.help',
                              defaultMessage: 'Help',
                            }
                          : {
                              id: 'header.menu.helpDisabled',
                              defaultMessage: 'Experiencing help widget issues',
                            },
                      )}
                      isWhite={!white}
                    >
                      {() => (
                        <>
                          {allowedHelpDropdownItems.helpDesk && (
                            <HelpCenterButton helpdeskLink={helpdeskLink} isWhite={white} />
                          )}
                          {allowedHelpDropdownItems.contactSupport && this.renderChatButton()}
                          {allowedHelpDropdownItems.hotKeys && <HotkeysButton isWhite={white} />}
                        </>
                      )}
                    </BarDropdown>
                  </div>
                )}
                {allowedContactSupport && isSupplier && (
                  <div className={classes.menuBox}>
                    <BarIcon
                      icon="ask"
                      white={white}
                      disabled={!freshDeskLoaded}
                      onClick={labelOptions.name !== 'AlPrime' ? this.openFreshDeskChat : this.sendEmail}
                    />
                  </div>
                )}

                {labelOptions.loginLangSelect && (
                  <div className={classes.menuBox}>
                    <LocaleSelect flagOnly />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </header>
    );
  }
}

Header.defaultProps = {
  searchBar: true,
};

const mapStateToProps = (state) => ({
  value: searchTermSelector(state),
  isGranted: ACL.isGranted(state),
  isAdmin: isAdminSelector(state),
  disableFinancialFeatures: doesCurrentCompanySupportGeneralDocsOnlySelector(state),
  currentCompanyId: currentCompanySelector(state),
  currentUser: userSelector(state),
  isGhost: isDokkaSupportSelector(state),
  userName: userNamesSelector(state),
  supportUsers: supportUsersSelector(state),
  organizationName: organizationNameSelector(state),
  currentCompanyName: currentCompanyNameSelector(state),
  currentConnectedERP: currentConnectedERPselector(state),
  locale: localeSelector(state),
  helpdeskLink: helpdeskLinkSelector(state),
  isChatReadyToRenderOnDocument: chatReadyToRenderOnDocumentSelector(state),
  isLoadingHotkeys: documentHotkeysIsLoadingSelector(state),
  hotkeys: documentHotkeysSelector(state),
  isEnableChatClient: companyEnableClientChatFeatureSelector(state),
});

export default compose(
  connect(mapStateToProps, {
    toggleEventPanel: toggleEventPanelAction,
    signOut: signOutAction,
    // search: searchAction,
    saveBackUrl: saveBackSearchUrlAction,
    setChatOpen: setOpenAction,
    setActiveChannel: setActiveChannelAction,
  }),
  withStyles(sheet, { withTheme: true }),
)(Header);

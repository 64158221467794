// @flow
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import useLinkedPanel, { type TLinkedPanel } from 'pages/company/LinkedDocumentsPanel/useLinkedPanel';
import useResize from 'pages/company/LinkedDocumentsPanel/useResize';
import useDebounceValue from 'hooks/useDebounceValue';

import { DnDItemSource, LINKED } from 'components/DnDItem';
import {
  Drawer,
  DrawerContent,
  DropTargetArea,
  DrawerDragger,
  DEFAULT_PANEL_WIDTH,
  MAX_PANEL_WIDTH,
  MIN_PANEL_WIDTH,
} from 'pages/company/LinkedDocumentsPanel/components/StyledComponents';
import { TileCardGridList, GridTileCard } from 'pages/company/TileGrid/TileCardList/StyledComponents';
import DragTileCard from 'pages/company/TileGrid/TileCard/DragTileCard';
import TileCard from 'pages/company/TileGrid/TileCard/TileCard';
import LinkedNote from 'pages/company/LinkedDocumentsPanel/components/LinkedNote';
import LinkedActions from 'pages/company/LinkedDocumentsPanel/components/LinkedActions';
import LoadMoreObserver from 'pages/company/LinkedDocumentsPanel/components/LoadMoreObserver';
import DocumentPreviewNavigation from 'pages/company/DocumentPreviewNavigation/DocumentPreviewNavigation';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';

const DragSource = DnDItemSource(LINKED);

const LinkedPanel: React$StatelessFunctionalComponent<TLinkedPanel> = (props) => {
  const {
    list,
    note,
    linkID,
    total,
    panelWidth,
    isOpen,
    isOver,
    isLocked,
    isPreviewOpen,
    isRtl,
    onDndOver,
    onDndLeave,
    onDndDrop,
    onClose,
    handleContextMenu,
    handleDocumentClick,
    handlePreviewClick,
    handleNoteClick,
    handleResize,
    fetchNext,
  } = useLinkedPanel(props);
  const { selectedDocuments, clearSelection, isContextMenuOpen, preview, onMoveToCompany, onOpenSendByEmailDialog } = props;
  const { formatMessage } = useIntl();

  const { handleMouseDown, width } = useResize({
    current: panelWidth || DEFAULT_PANEL_WIDTH,
    min: MIN_PANEL_WIDTH,
    max: MAX_PANEL_WIDTH,
    // onChange: handleResize,
    isRtl,
  });

  const debounceWidth = useDebounceValue(width, 500);

  useEffect(() => {
    handleResize(debounceWidth);
  }, [handleResize, debounceWidth]);

  return (
    <Drawer
      open={isOpen}
      anchor="right"
      variant="persistent"
      onClose={onClose}
      onClick={clearSelection}
      sx={{ width: isOpen ? width : 0 }}
      PaperProps={{
        style: {
          width,
        },
      }}
    >
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">
            {formatMessage({ id: 'documents.linkedPanel.title', defaultMessage: 'Linked Documents' })}
          </Typography>
          <IconButton edge="end" onClick={onClose} color="inherit" sx={{ ml: 'auto' }}>
            <CloseOutlinedIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DrawerContent isLocked={isLocked}>
        <DrawerDragger onMouseDown={handleMouseDown}>
          <DragIndicatorOutlinedIcon sx={{ fontSize: 12 }} />
        </DrawerDragger>

        <Box px={2} pt={3}>
          <LinkedNote note={note} linkID={linkID} />
          <LinkedActions linkID={linkID} total={total} onMoveToCompany={onMoveToCompany} onOpenSendByEmailDialog={onOpenSendByEmailDialog} />
        </Box>

        <DropTargetArea
          stopPropagateDrop
          isOver={isOver} // css
          onDragOver={onDndOver}
          onDragLeave={onDndLeave}
          onDndDrop={onDndDrop}
        >
          <TileCardGridList sx={{ width: 1, m: 0 }}>
            {list.map((document, index) => (
              <GridTileCard key={document.documentID}>
                <DragTileCard
                  DragSource={DragSource}
                  srcType={LINKED}
                  document={document}
                  isMultiplyDND={selectedDocuments.has(document.documentID) && selectedDocuments.size > 1}
                  readOnly={isLocked}
                >
                  <TileCard
                    document={document}
                    selected={selectedDocuments.has(document.documentID)}
                    onContextMenu={handleContextMenu}
                    onClickPreview={handlePreviewClick}
                    onClickLink={handleDocumentClick}
                    onClickNote={handleNoteClick}
                    readOnly={isLocked}
                    isFavorite={index === 0}
                  />
                </DragTileCard>
              </GridTileCard>
            ))}
          </TileCardGridList>
          <LoadMoreObserver />
        </DropTargetArea>
      </DrawerContent>

      {isPreviewOpen && (
        <DocumentPreviewNavigation
          documentId={preview.documentId}
          setPreview={handlePreviewClick}
          list={list}
          allCount={total}
          isContextMenuOpen={isContextMenuOpen}
          onLoadNext={fetchNext}
          onClick={handleDocumentClick}
          onContextMenu={handleContextMenu}
        />
      )}
    </Drawer>
  );
};

export default LinkedPanel;

// @flow
import React from 'react';

import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Approver from 'pages/components/Approver';

import type { ApproverType } from 'domain/approvals/types';

type TApproverCell = {
  value: ApproverType,
};

const ApproverCell: React$StatelessFunctionalComponent<TApproverCell> = ({ value }) => (
  <Stack alignItems="flex-start">
    <Approver
      key={value.id}
      avatar={<Avatar alt={value.fullName} src={value.picture} />}
      label={value.fullName}
      variant="outlined"
      hasArrow={false}
    />
  </Stack>
);

export default ApproverCell;

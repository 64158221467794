import { styled } from '@mui/material/styles';

import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';
import MuiAvatarGroup from '@mui/material/AvatarGroup';

import { MAX_AVATARS_FOR_GROUPS } from './constants';

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.primary.main,
    boxSizing: 'border-box',
  },
}));

export const SMenuItem = styled(MenuItem)(({ theme }) => ({
  '&&.Mui-selected': {
    backgroundColor: theme.palette.common.white,
  },
}));

export const MenuList = styled('ul')(() => ({ padding: 0 }));

export const AvatarGroup = styled(MuiAvatarGroup)(() => ({
  '&&.MuiAvatarGroup-root .MuiAvatarGroup-avatar': {
    height: 24,
    width: 24,
    fontSize: 15,
  },
  '& .MuiAvatar-root:nth-of-type(1)': {
    zIndex: MAX_AVATARS_FOR_GROUPS,
  },
}));

// @flow
import React, { useEffect, useCallback, useState } from 'react';
import { List } from 'immutable';
import { useParams } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

// components
import Category from './components/Category';
import Upload from './components/Upload';
import LinkedSidebar from 'pages/company/linkedSidebar__legacy';
import DocPreview from 'pages/company/DocumentPreviewNavigation/DocumentPreviewNavigation';

// selectors
import {
  supplierDocumentsSelector,
  linkingTagSelector,
  documentLinkedOpenStatusSelector,
  documentSortedLinkedSelector,
  linkedSelector,
  supplierPageTokensSelector,
} from 'domain/documents/documentSelector';

// actions
import {
  documentHideLinkedDocs,
  setLinkingTagAction,
  documentGetLinkedAction,
  supplierDocumentSearchAction,
} from 'domain/documents/documentsActions';

// types
import { type DocumentsType } from 'domain/documents';
import { type PreviewStateT } from 'pages/company/type.js.flow';

// helpers
import { getDocumentUrl, getPreviewSrc } from 'pages/company/workSpace/helpers';
import { getPreviewContextName } from 'pages/company/TileGrid';
import { promisify } from 'lib/helpers';
import { useApiToken } from 'lib/apiTokenKeeper/'

// styles
import useStyles from './sheet';

export const supplierCategories = [
  {
    title: 'Received',
    id: 'received',
  },
  {
    title: 'In Process',
    id: 'in_process',
  },
  {
    title: 'Processed',
    id: 'processed',
  },
  {
    title: 'Paid',
    id: 'paid',
  },
  {
    title: 'Rejected',
    id: 'rejected',
  },
];

type Props = {
  closeUploadPopup: () => void,
  setNavigation: (id: string, fromLinked?: boolean) => void,
  isOpenUpload: boolean,
  tagsComponentHeight: number,
};

const mapStateToProps = (state) => ({
  documents: supplierDocumentsSelector(state),
  isLinkedOpen: documentLinkedOpenStatusSelector(state),
  linkingTag: linkingTagSelector(state),
  linkedItems: documentSortedLinkedSelector(state),
  linked: linkedSelector(state),
  supplierPageTokens: supplierPageTokensSelector(state),
});

const Workspace: React$StatelessFunctionalComponent<Props> = ({
  closeUploadPopup,
  isOpenUpload,
  setNavigation,
  tagsComponentHeight,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const dokkaToken = useApiToken();

  const [preview, setPreview] = useState({ documentId: null, contextName: '' });
  const [previewData, setPreviewData] = useState({ list: new List(), count: 0 });
  const [previewCategory, setPreviewCategory] = useState(null);
  const [isLinkedDocPreview, setIsLinkedDocPreview] = useState(false);

  const { documents, linkingTag, isLinkedOpen, linkedItems, linked, supplierPageTokens } =
    useSelector(mapStateToProps);

  useEffect(() => {
    if (linked.count) {
      setPreviewData({
        list: linkedItems.toList(),
        count: linked.count,
      });
    }
  }, [linked, linkedItems]);

  const previewDocumentID = preview?.documentId;

  const getDocUrl = (documentID: string) => getDocumentUrl(companyId, documentID);

  const getPreviewSrcDoc = useCallback(
    (documentID: string, dokkaToken: string, preview?: boolean) =>
      getPreviewSrc(companyId, documentID, dokkaToken, preview),
    [companyId],
  );

  const onLinkingSidebarClose = useCallback(() => {
    dispatch(documentHideLinkedDocs());
    dispatch(setLinkingTagAction(''));
  }, [dispatch]);

  const handleClick = useCallback(
    (e: SyntheticMouseEvent<HTMLElement>, doc: DocumentsType, isLinkedPanelItem: boolean) => {
      setNavigation(doc.documentID, isLinkedPanelItem);
    },
    [setNavigation],
  );

  const onPreview = (preview: PreviewStateT) => {
    setPreview(preview);
    if (!preview.documentId) {
      setPreviewData({ list: new List(), count: 0 });
      setPreviewCategory(null);
      setIsLinkedDocPreview(false);
    }
  };

  const workspacePreview = (doc: Document | null) => {
    const [documentId, contextName] = doc ? [doc.documentID, getPreviewContextName(doc)] : [null, ''];
    onPreview({ documentId, contextName });
  };
  const getNextPage = useCallback(() => {
    if (typeof supplierPageTokens[previewCategory] === 'string' && supplierPageTokens[previewCategory].length) {
      dispatch(
        supplierDocumentSearchAction({
          pageToken: supplierPageTokens[previewCategory],
          infiniteScroll: true,
          stateColumn: previewCategory,
        }),
      ); // true indicates infinite scroll
    }
  }, [supplierPageTokens, previewCategory, dispatch]);

  const getNextLinkedPage = async () => {
    const { pageToken, tag } = linked;
    const fn = (arg) => dispatch(documentGetLinkedAction({ ...arg }));
    await promisify(fn, { tag, pageToken, isOpen: false });
    dispatch(setLinkingTagAction());
  };

  const fakeFunction = useCallback(() => {}, []);

  const handleOpenDocument = useCallback(() => {
    if (preview) {
      setNavigation(preview.documentId, isLinkedDocPreview || !!linkingTag);
    }
  }, [preview, setNavigation, isLinkedDocPreview, linkingTag]);

  return (
    <>
      <div className={classes.workspace}>
        {supplierCategories.map(({ title, id }) => {
          const docs = documents.get(id);
          const dSeq = docs?.keySeq();
          const dList = docs?.toList();
          return (
            <Category
              key={id}
              dList={dList}
              dSeq={dSeq}
              id={id}
              title={title}
              linkingTag={linkingTag}
              getPreviewSrcDoc={getPreviewSrcDoc}
              onDocumentClick={handleClick}
              onPreview={workspacePreview}
              closeUploadPopup={closeUploadPopup}
              fakeFunction={fakeFunction}
              setIsLinkedDocPreview={setIsLinkedDocPreview}
              tagsComponentHeight={tagsComponentHeight}
              setPreviewData={setPreviewData}
              setPreviewCategory={setPreviewCategory}
              previewCategory={previewCategory}
              previewData={previewData}
              isLinkedDocPreview={isLinkedDocPreview}
            />
          );
        })}
      </div>
      {isLinkedOpen && (
        <LinkedSidebar
          disabled={previewDocumentID}
          getDocumentUrl={getDocUrl}
          getPreviewSrc={getPreviewSrcDoc}
          dokkaToken={dokkaToken}
          onContextMenu={fakeFunction}
          onClick={handleClick}
          onPreview={onPreview}
          onClose={onLinkingSidebarClose}
          selectedDocuments={new Set()}
          preview={preview}
          previewOpened={preview}
          companyId={companyId}
        />
      )}
      {previewDocumentID && preview.contextName !== 'LINKED_PANEL_PREVIEW' ? (
        <DocPreview
          documentId={previewDocumentID}
          setPreview={workspacePreview}
          list={previewData.list}
          allCount={previewData.count}
          onContextMenu={fakeFunction}
          isContextMenuOpen={false}
          onLoadNext={isLinkedDocPreview ? getNextLinkedPage : getNextPage}
          onClick={handleOpenDocument}
        />
      ) : null}

      {isOpenUpload && <Upload open={isOpenUpload} companyId={companyId} close={closeUploadPopup} />}
    </>
  );
};

export default Workspace;

/* @flow */
import * as React from 'react';
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';
import { debounce } from 'throttle-debounce';
import moment from 'moment';
import type { GetOptionListParams } from 'components/Form/VirtualSelectAsync';
import toast from 'components/Toast';
import Api from 'domain/api';
import { documentSelector } from 'domain/documents/documentSelector';
import { documentUpdateNotes } from 'domain/documents/documentsActions';
import type { DocumentsType } from 'domain/documents';
import { approveFormFieldsListSelector } from 'domain/approvals/selectors';
import {
  getApprovalApproveReasonsAction,
  updateApprovalApproveReasonsAction,
  approveApprovalAction,
} from 'domain/approvals/actions';
import { forceErpSyncAction } from 'domain/journal/actions';
import { FormattedMessage, injectIntl, type IntlShape } from 'react-intl';
import { withRedirectWhenApprovalsEnd } from 'hooks/approval/useRedirectWhenApprovalsEnd';
import * as ACL from 'domain/restriction';

import Dialog from 'components/mui/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';
import CircularProgressWithBackdrop from 'components/mui/CircularProgressWithBackdrop';
import MuiVirtualSelectAsync from 'components/Form/VirtualSelectAsync/MuiVirtualSelectAsync';
import MuiVirtualSelect from 'components/Form/VirtualSelect/MuiVirtualSelect';
import { withApiToken } from 'lib/apiTokenKeeper';

import type { FormFields, FormField } from 'domain/approvals/types.js.flow';

type Props = {|
  onClose?: () => void,
  onManageApprovals?: () => void,
  isMobile?: boolean,
  intl: IntlShape,

  // redux
  formFields: FormFields,
  document: DocumentsType,
  approveApproval: Dispatch<typeof approveApprovalAction>,
  updateReasonForm: Dispatch<typeof updateApprovalApproveReasonsAction>,
  getReasonForm: Dispatch<typeof getApprovalApproveReasonsAction>,
  updateNotes: Dispatch<typeof documentUpdateNotes>,
  forceErpSync: Dispatch<typeof forceErpSyncAction>,
  apiToken: string,
  isGoNext: boolean,
  isAllowRedirectAfterApprove: boolean, // HOC withRedirectWhenApprovalsEnd
  redirectToApprove: () => void, // HOC withRedirectWhenApprovalsEnd
  isGranted: (l: number | number[]) => boolean,
|};

type State = {
  values: {
    [key: string]: string,
  },
  details: string | null,
  isLoading: boolean,
  isInitialLoading: boolean,
};

const PROGRESS_DELAY = 700;

class DialogApprovalFormApprove extends React.Component<Props, State> {
  state = {
    values: {},
    details: null,
    isLoading: false,
    isInitialLoading: true,
  };

  componentDidMount(): * {
    this.loadForm();
  }

  componentWillUnmount(): * {
    this.stopProgress();
  }

  onChange = (fieldName: string, value: string) => {
    const { values } = this.state;
    return new Promise((resolve) => {
      this.setState({ values: { ...values, [fieldName]: value } }, () => {
        resolve({ [fieldName]: value });
      });
    });
  };

  onUpdate = debounce(200, (fieldName: string, value: string) => {
    this.onChange(fieldName, value)
      .then(this.bulkUpdate)
      .catch(() => {});
  });

  get isReadOnlyBK(): boolean {
    const { isGranted } = this.props;
    return isGranted(ACL.IS_READ_ONLY_ACCOUNTANT);
  }

  onClickBtnApprove = () => {
    if (this.isEditValues()) {
      this.bulkUpdate(this.state.values)
        .then(this.onSubmit)
        .catch(() => {});
    } else if (this.isAllowSubmit()) {
      this.onSubmit();
    } else {
      this.showError();
    }
  };

  onSubmit = () => {
    const {
      approveApproval,
      updateNotes,
      document,
      onClose = (x) => x,
      onManageApprovals = (x) => x,
      forceErpSync,
      isMobile,
      isGoNext,
      isAllowRedirectAfterApprove,
      redirectToApprove,
    } = this.props;
    if (this.isAllowSubmit()) {
      this.setState({ isLoading: true });

      const notes = this.getDetailsValue();
      if (notes) {
        const notePromise = new Promise((resolve, reject) => {
          updateNotes({ notes, documentID: document.documentID, cb: { resolve, reject } });
        });
        notePromise.then(() => {}).catch(() => {});
      }

      const isAwaitApprove = !isGoNext && !isMobile;

      new Promise((resolve, reject) => {
        approveApproval({ resolve, reject });
      })
        .then(() => {
          if (isAllowRedirectAfterApprove) {
            redirectToApprove();
          } else if (isAwaitApprove) {
            // forceErpSync invocation below will nullify approval form fields with its response
            // resulting in empty form shown until its closed. Closing form first
            onClose();
            new Promise((resolve, reject) => {
              forceErpSync({ documentID: document.documentID, reindexCache: false, resolve, reject });
            }).then(() => {
              onManageApprovals();
            });
          } else {
            onClose();
            onManageApprovals();
          }
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    } else {
      this.showError();
    }
  };

  onChangeDetails = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ details: e.currentTarget.value });
  };

  getStoredValue = (fieldName: string) => this.getPropsValues()[fieldName];

  getPropsValues = () => {
    const { formFields } = this.props;
    return formFields.reduce((res, field) => ({ ...res, [field.name]: field.value }), {});
  };

  getEditingValue = (fieldName: string, defaultValue: string) => {
    const { values } = this.state;
    return this.isTouched(fieldName) && typeof values[fieldName] === 'string' ? values[fieldName] : defaultValue;
  };

  getDetailsValue = () => (typeof this.state.details === 'string' ? this.state.details : this.props.document.notes);

  loadForm = () => {
    const {
      getReasonForm,
      document: { documentID },
    } = this.props;
    this.setState({ isInitialLoading: true });
    const loadPromoise = new Promise((resolve, reject) => {
      getReasonForm({ documentID, resolve, reject });
    });
    loadPromoise.then(() => {
      this.setState({ isInitialLoading: false });
    });
  };

  showError = () => {
    toast.error(
      this.props.intl.formatMessage({
        id: 'document.approval.form.approve.errorMessage',
        defaultMessage: 'Mandatory field(s) missing',
      }),
    );
  };

  bulkUpdate = async (data: { [key: string]: string }) => {
    const entries = Object.entries(data);
    if (entries.length === 0 || this.isUpdateProcess) return null;
    this.isUpdateProcess = true;
    const update = Object.fromEntries(entries.map(([fieldName, value]) => [fieldName, { value }]));

    const promise = new Promise((resolve, reject) => {
      const payload = { update, resolve, reject };
      this.props.updateReasonForm(payload);
      this.startProgress();
    });
    promise.finally(() => {
      this.clearFieldState(Object.keys(data));
      this.stopProgress();
      this.isUpdateProcess = false;
    });
    return promise;
  };

  startProgress = () => {
    this.progress = setTimeout(() => {
      this.setState({ isLoading: true });
    }, PROGRESS_DELAY);
  };

  stopProgress = () => {
    if (this.progress) {
      clearTimeout(this.progress);
      this.progress = null;
      this.setState({ isLoading: false });
    }
  };

  clearFieldState = (fields: string[]) => {
    const { values } = this.state;
    const clearEntries = Object.entries(values).filter(([key]) => !fields.includes(key));
    const clearedValues = Object.fromEntries(clearEntries);
    this.setState({ values: clearedValues });
  };

  isEditValues = () => !!Object.values(this.state.values).length;

  isTouched = (fieldName: string) => typeof this.state.values[fieldName] !== 'undefined';

  isAllowSubmit = () => {
    const { formFields } = this.props;

    if (this.isReadOnlyBK) {
      return true;
    }

    return formFields.size ? formFields.every(({ mandatory, value }) => (mandatory ? !!value : true)) : true;
  };

  loadOptionsList = (cellName) => async (queryParams: GetOptionListParams) => {
    const { apiToken: dokkaToken, document } = this.props;
    const params = {
      ...queryParams,
      cellName,
      dokkaToken,
      documentID: document.documentID,
    };

    const {
      data: { list, pageToken },
    } = await Api.getJEList({ params });
    return { options: list.map(({ display, value }) => ({ label: display, value })), pageToken };
  };

  progress: TimeoutID | null;

  isUpdateProcess: boolean = false;

  renderInput = (fieldData: FormField) => {
    const value = this.getEditingValue(fieldData.name, this.getStoredValue(fieldData.name));
    return (
      <TextFieldBase
        value={value}
        onChange={(event) => this.onChange(fieldData.name, event.currentTarget.value)}
        label={fieldData.label}
        onBlur={(event) => this.onUpdate(fieldData.name, event.currentTarget.value)}
        disabled={this.isReadOnlyBK || fieldData.readonly}
        required={fieldData.mandatory}
        fullWidth
      />
    );
  };

  renderSelect = (fieldData: FormField) => {
    const value = this.getEditingValue(fieldData.name, this.getStoredValue(fieldData.name));
    const onChange = (val: string): void => {
      this.onChange(fieldData.name, val)
        .then(this.bulkUpdate)
        .catch(() => {});
    };

    return (
      <MuiVirtualSelect
        options={fieldData.options.toJS()}
        disabled={this.isReadOnlyBK || fieldData.readonly}
        input={{
          value,
          onChange,
          name: '',
        }}
        label={fieldData.label}
        required={fieldData.mandatory}
        maxVisibleOptionsCount={7}
        disablePopper={false}
      />
    );
  };

  renderAsyncSelect = (fieldData: FormField) => {
    const value = this.getEditingValue(fieldData.name, this.getStoredValue(fieldData.name));
    const onChange = (val: string): void => {
      this.onChange(fieldData.name, val)
        .then(this.bulkUpdate)
        .catch(() => {});
    };

    return (
      <MuiVirtualSelectAsync
        loadData={this.loadOptionsList(fieldData.name)}
        defaultLabel={fieldData.display_value || ''}
        disabled={this.isReadOnlyBK || fieldData.readonly}
        label={fieldData.label}
        required={fieldData.mandatory}
        maxVisibleOptionsCount={7}
        disablePopper={false}
        input={{
          value,
          onChange,
          name: '',
        }}
      />
    );
  };

  renderDate = (fieldData: FormField) => {
    const currentValue = this.getEditingValue(
      fieldData.name,
      moment(this.getStoredValue(fieldData.name), 'YYYY-MM-DD').format('DD/MM/YYYY'),
    );

    return (
      <TextFieldBase
        value={currentValue}
        onChange={(event) => this.onChange(fieldData.name, event.currentTarget.value)}
        label={fieldData.label}
        onBlur={(event) => this.onUpdate(fieldData.name, event.currentTarget.value)}
        disabled={this.isReadOnlyBK || fieldData.readonly}
        required={fieldData.mandatory}
        fullWidth
      />
    );
  };

  renderField = (fieldData: FormField) => {
    const renderInputs = {
      datetime: this.renderDate,
      text: this.renderInput,
      list: this.renderSelect,
      doc_dynamic_list: this.renderAsyncSelect,
    };

    const errorTypeField = () => console.warn(`Undefined field type: ${fieldData.type}`);

    const renderInput = renderInputs[fieldData.type] ? renderInputs[fieldData.type] : errorTypeField;

    return <React.Fragment key={fieldData.name}>{renderInput(fieldData)}</React.Fragment>;
  };

  render() {
    const {
      onClose,
      formFields,
      intl: { formatMessage },
    } = this.props;
    const { isLoading, isInitialLoading } = this.state;

    return (
      <>
        {isInitialLoading ? (
          <CircularProgressWithBackdrop />
        ) : (
          <Dialog
            open
            onClose={onClose}
            title={formatMessage({ id: 'document.approval.form.approve.title', defaultMessage: 'Approve document' })}
            maxWidth="sm"
            okText={formatMessage({ id: 'document.approval.form.approve.okBtn', defaultMessage: 'Approve' })}
            onOk={this.onClickBtnApprove}
            withContent={false}
          >
            {isLoading && <CircularProgressWithBackdrop />}
            <DialogContent>
              <Typography variant="subtitle1" color="grey.600">
                <FormattedMessage
                  id="document.approval.form.approve.subtitle"
                  defaultMessage="Please, fill out the information below:"
                />
              </Typography>
              <Stack spacing={3} mt={3}>
                {formFields.map(this.renderField)}
                <TextFieldBase
                  value={this.getDetailsValue()}
                  onChange={this.onChangeDetails}
                  label={formatMessage({ id: 'document.approval.form.approve.details', defaultMessage: 'Details' })}
                  fullWidth
                />
              </Stack>
            </DialogContent>
          </Dialog>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  formFields: approveFormFieldsListSelector(state),
  document: documentSelector(state),
  isGranted: ACL.isGranted(state),
});

const mapDispatchToProps = {
  approveApproval: approveApprovalAction,
  updateReasonForm: updateApprovalApproveReasonsAction,
  getReasonForm: getApprovalApproveReasonsAction,
  updateNotes: documentUpdateNotes,
  forceErpSync: forceErpSyncAction,
};

export default compose(
  injectIntl,
  withApiToken,
  connect(mapStateToProps, mapDispatchToProps),
  withRedirectWhenApprovalsEnd,
)(DialogApprovalFormApprove);

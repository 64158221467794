// @flow
import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';
import { update } from 'lodash/fp';
import { useIntl } from 'react-intl';

import {
  deleteApprovalsGroupAction,
  updateApprovalsGroupAction,
  createApprovalsGroupAction,
} from 'domain/approvals/actions';
import { approvalGroupListSelector, approversSelector } from 'domain/approvals/selectors';

import ApprovalGroupDialog from './components/ApprovalGroupDialog';
import ControlsCell from 'pages/configurations/company/pages/approvals/components/Table/components/ControlsCell';
import ApprovalsCell from 'pages/configurations/company/pages/approvals/components/Table/components/ApprovalsCell';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import EmptyScreen from '../../components/EmptyScreen';
import Table from 'pages/configurations/company/pages/approvals/components/Table';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import { HeadersAdapter, RowsAdapter, Fields } from './helpers';
import { ColumnTypes } from 'pages/configurations/company/pages/approvals/components/Table/helpers';

import elements from 'components/elements';

const mapStateToProps = (state) => ({
  approvalGroups: approvalGroupListSelector(state),
  users: approversSelector(state),
});

const ApprovalGroupsPage = () => {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);

  const dispatch = useDispatch();
  const confirm = useConfirm();
  const { formatMessage } = useIntl();

  const { approvalGroups, users } = useSelector(mapStateToProps);

  const handleDeleteGroup = useCallback(
    (groupId) => {
      dispatch(deleteApprovalsGroupAction({ groupId }));
    },
    [dispatch],
  );

  const handleEditGroup = useCallback((group) => {
    setSelectedGroup(group);
    setIsGroupModalOpen(true);
  }, []);

  const onDeleteGroupClick = useCallback(
    (group) => {
      confirm({
        title: formatMessage(
          {
            id: 'configurations.company.approval.deleteModal.title',
            defaultMessage: `Remove ${group.group_title}?`,
          },
          { groupName: group.group_title },
        ),
        description: formatMessage(
          {
            id: 'configurations.company.approval.deleteModal.body',
            defaultMessage: `${group.group_title} will be removed. It will not affect existing approval flows`,
          },
          { groupName: group.group_title },
        ),
        confirmationText: formatMessage(
          {
            id: 'configurations.company.approval.deleteModal.confirmButton',
            defaultMessage: 'Remove',
          },
          { groupName: group.group_title },
        ),
        confirmationButtonProps: { color: 'error' },
        cancellationText: formatMessage({
          id: 'configurations.company.approval.deleteModal.cancelButton',
          defaultMessage: 'Cancel',
        }),
      }).then(() => {
        handleDeleteGroup(group.group_id);
      });
    },
    [confirm, formatMessage, handleDeleteGroup],
  );

  const handleClose = useCallback(() => {
    setSelectedGroup(null);
    setIsGroupModalOpen(false);
  }, []);

  const onSubmit = useCallback(
    async (values) =>
      new Promise((resolve, reject) => {
        const payload = {
          resolve,
          reject,
          ...update([Fields.approvers], (a) => a.map((i) => i.id))(values),
          ...(selectedGroup?.group_id && { groupId: selectedGroup.group_id }),
        };
        const action = selectedGroup ? updateApprovalsGroupAction : createApprovalsGroupAction;
        dispatch(action(payload));
      }).then(() => {
        handleClose();
      }),
    [dispatch, handleClose, selectedGroup],
  );

  const onNewGroupClick = useCallback(() => {
    setIsGroupModalOpen(true);
  }, []);

  const disableEdit = users.size < 1;

  const rowData = useMemo(() => RowsAdapter(approvalGroups, users), [approvalGroups, users]);
  const columnDefs = useMemo(
    () => HeadersAdapter({ formatMessage, handleDeleteGroup: onDeleteGroupClick, handleEditGroup, disableEdit }),
    [formatMessage, onDeleteGroupClick, handleEditGroup, disableEdit],
  );

  const frameworkComponents = useMemo(
    () => ({
      [ColumnTypes.controls]: ControlsCell,
      [ColumnTypes.approvals]: ApprovalsCell,
    }),
    [],
  );

  return (
    <>
      {rowData?.length ? (
        <>
          <Box display="flex" mb={2}>
            <Typography variant="subtitle1" fontWeight={500}>
              {formatMessage(
                {
                  id: 'configurations.company.approvals.title',
                  defaultMessage: `Approval groups (${rowData?.length})`,
                },
                { count: rowData?.length },
              )}
            </Typography>
            <Button
              type="button"
              variant="outlined"
              startIcon={<AddOutlinedIcon />}
              onClick={onNewGroupClick}
              sx={{ ml: 'auto' }}
              data-element={elements.configuration.company.approvals.groups.createGroupButton}
            >
              {formatMessage({ id: 'configurations.company.createGroupBtn', defaultMessage: 'Add groups' })}
            </Button>
          </Box>
          <Box data-element={elements.configuration.company.approvals.groups.groupList}>
            <Table columnDefs={columnDefs} rowData={rowData} frameworkComponents={frameworkComponents} />
          </Box>
        </>
      ) : (
        <EmptyScreen
          title={formatMessage({
            id: 'configurations.company.approvals.groups.empty_list.title',
            defaultMessage: 'No Groups yet',
          })}
          description={formatMessage({
            id: 'configurations.company.approvals.groups.emptyListText',
            defaultMessage:
              'Approval groups allow you to quick setup approval flow with the defined lists of approvers',
          })}
          buttonText={formatMessage({ id: 'configurations.company.createGroupBtn', defaultMessage: 'Add groups' })}
          onClick={onNewGroupClick}
        />
      )}
      {isGroupModalOpen && (
        <ApprovalGroupDialog
          open={isGroupModalOpen}
          title={
            selectedGroup
              ? formatMessage({
                  id: 'configurations.company.approvals.groups.modal.title.edit',
                  defaultMessage: 'Edit group',
                })
              : formatMessage({
                  id: 'configurations.company.approvals.groups.modal.title.create',
                  defaultMessage: 'Create group',
                })
          }
          handleClose={handleClose}
          initialValues={selectedGroup || {}}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

export default ApprovalGroupsPage;

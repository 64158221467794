// @flow
import React from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import bg from './images/bg.png';

const EmptyCompaniesList = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Divider sx={{ my: 1, mb: 5 }} />
      <Box textAlign="center" mx="auto">
        <img src={bg} alt="no companies" />
        <Typography variant="h5" mt={2}>
          {formatMessage({
            id: 'companies.emptyList.title',
            defaultMessage: 'No companies has been found',
          })}
        </Typography>
      </Box>
    </>
  );
};

export default EmptyCompaniesList;

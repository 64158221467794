import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { update } from 'lodash/fp';
import { useIntl } from 'react-intl';
import { useConfirm } from 'material-ui-confirm';

import {
  deleteAdvancedApprovalsGroupAction,
  updateAdvancedApprovalsGroupAction,
  createAdvancedApprovalsGroupAction,
} from 'domain/approvals/actions';
import { advancedApprovalGroupListSelector, approversSelector } from 'domain/approvals/selectors';
import { organizationUsersSelector } from 'domain/organizationUser/selectors';

import ControlsCell from 'pages/configurations/company/pages/approvals/components/Table/components/ControlsCell';
import ApproverCell from 'pages/configurations/company/pages/approvals/components/Table/components/ApproverCell';
import ApproverLevels from 'pages/components/ApproverLevels';
import UserCell from 'pages/configurations/company/pages/approvals/components/Table/components/UserCell';
import AdvancedGroupDialog from './components/AdvancedGroupDialog';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import EmptyScreen from '../../components/EmptyScreen';
import Table from 'pages/configurations/company/pages/approvals/components/Table';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import { HeadersAdapter, RowsAdapter, Fields } from './helpers';
import { ColumnTypes } from 'pages/configurations/company/pages/approvals/components/Table/helpers';

import elements from 'components/elements';

const mapStateToProps = (state) => ({
  advancedApprovalGroups: advancedApprovalGroupListSelector(state),
  companyApprovers: approversSelector(state),
  organizationUsers: organizationUsersSelector(state),
});

const AdvancedGroupsPage = () => {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);

  const dispatch = useDispatch();
  const confirm = useConfirm();
  const { formatMessage } = useIntl();

  const { advancedApprovalGroups, companyApprovers, organizationUsers } = useSelector(mapStateToProps);

  const handleDeleteGroup = useCallback(
    (id) => {
      dispatch(deleteAdvancedApprovalsGroupAction({ id }));
    },
    [dispatch],
  );

  const handleEditGroup = useCallback((group) => {
    setSelectedGroup(group);
    setIsGroupModalOpen(true);
  }, []);

  const onDeleteGroupClick = useCallback(
    (group) => {
      confirm({
        title: formatMessage(
          {
            id: 'configurations.company.approvals.deleteGroupModal.title',
            defaultMessage: `Remove ${group.name}?`,
          },
          { groupName: group.name },
        ),
        description: formatMessage(
          {
            id: 'configurations.company.approvals.deleteGroupModal.body',
            defaultMessage: `${group.name} will be removed. It will not affect existing approval flows`,
          },
          { groupName: group.name },
        ),
        confirmationText: formatMessage(
          {
            id: 'button.remove',
            defaultMessage: 'Remove',
          },
          { groupName: group.name },
        ),
        confirmationButtonProps: { color: 'error' },
        cancellationText: formatMessage({
          id: 'button.cancel',
          defaultMessage: 'Cancel',
        }),
      }).then(() => {
        handleDeleteGroup(group.id);
      });
    },
    [confirm, formatMessage, handleDeleteGroup],
  );

  const handleClose = useCallback(() => {
    setSelectedGroup(null);
    setIsGroupModalOpen(false);
  }, []);

  const onSubmit = useCallback(
    async (values) =>
      new Promise((resolve, reject) => {
        const payload = {
          resolve,
          reject,
          ...update(
            [Fields.levels],
            (levels) =>
              levels.map((level) => {
                const {
                  [Fields.minAmount]: minAmount,
                  [Fields.minApprovers]: minApprovers,
                  [Fields.flowType]: flowType,
                  id,
                  ...rest
                } = level;

                return {
                  ...rest,
                  [Fields.approvers]: level[Fields.approvers].map((approver) => approver.id),
                  min_amount: Number(minAmount),
                  min_approvers: minApprovers,
                  flow_type: flowType,
                };
              }),
            values,
          ),
          ...(selectedGroup?.id && { id: selectedGroup.id }),
        };
        const action = selectedGroup ? updateAdvancedApprovalsGroupAction : createAdvancedApprovalsGroupAction;
        dispatch(action(payload));
      }).then(() => {
        handleClose();
      }),
    [dispatch, handleClose, selectedGroup],
  );

  const onNewGroupClick = useCallback(() => {
    setIsGroupModalOpen(true);
  }, []);

  const rowData = useMemo(
    () => RowsAdapter(advancedApprovalGroups, companyApprovers, organizationUsers),
    [advancedApprovalGroups, companyApprovers, organizationUsers],
  );

  const columnDefs = useMemo(
    () => HeadersAdapter({ formatMessage, handleDeleteGroup: onDeleteGroupClick, handleEditGroup }),
    [formatMessage, onDeleteGroupClick, handleEditGroup],
  );
  const frameworkComponents = useMemo(
    () => ({
      [ColumnTypes.controls]: (props) => <ControlsCell {...props} />,
      [ColumnTypes.approver]: (props) => <ApproverCell {...props} />,
      [ColumnTypes.levels]: (props) => <ApproverLevels {...props} />,
      [ColumnTypes.user]: (props) => <UserCell {...props} />,
    }),
    [],
  );

  return (
    <>
      {rowData?.length ? (
        <>
          <Box display="flex" mb={2}>
            <Typography variant="subtitle1" fontWeight={500}>
              {formatMessage({
                id: 'configurations.company.approvals.advancedGroups.title',
                defaultMessage: 'Advanced groups',
              })}
            </Typography>
            <Button
              type="button"
              variant="outlined"
              startIcon={<AddOutlinedIcon />}
              onClick={onNewGroupClick}
              sx={{ ml: 'auto' }}
              data-element={elements.configuration.company.approvals.groups.createGroupButton}
            >
              {formatMessage({
                id: 'configurations.company.approvals.advancedGroups.createAdvancedGroupBtn',
                defaultMessage: 'New approval group',
              })}
            </Button>
          </Box>
          <Box data-element={elements.configuration.company.approvals.groups.groupList}>
            <Table columnDefs={columnDefs} rowData={rowData} frameworkComponents={frameworkComponents} />
          </Box>
        </>
      ) : (
        <EmptyScreen
          title={formatMessage({
            id: 'configurations.company.approvals.advancedGroups.empty_list.title',
            defaultMessage: 'No Advanced Approval Groups Yet',
          })}
          description={formatMessage({
            id: 'configurations.company.approvals.advancedGroups.emptyListText',
            defaultMessage: 'No advanced approval groups have been created yet',
          })}
          buttonText={formatMessage({
            id: 'configurations.company.approvals.advancedGroups.createAdvancedGroupBtn',
            defaultMessage: 'New approval group',
          })}
          onClick={onNewGroupClick}
        />
      )}
      {isGroupModalOpen && (
        <AdvancedGroupDialog
          open={isGroupModalOpen}
          title={
            selectedGroup
              ? formatMessage({
                  id: 'configurations.company.approvals.advancedGroups.modal.title.edit',
                  defaultMessage: 'Edit Advanced Group',
                })
              : formatMessage({
                  id: 'configurations.company.approvals.advancedGroups.modal.title.create',
                  defaultMessage: 'Add Advanced Group',
                })
          }
          handleClose={handleClose}
          initialValues={selectedGroup || {}}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

export default AdvancedGroupsPage;

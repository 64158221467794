// @flow
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { List } from 'immutable';
import { PageParamsFactory, createRange, getPageParams, createPageParams, findIndex, cancellation } from './helpers';
import { getRotateByDocID, setRotateByDocID } from 'lib/pdfHelpers';
import { isDocPaid } from 'domain/documents/helpers';
import { updateStyle } from 'lib/domHelpers';
import { BORDER_WIDTH, MAX_DOC_WIDTH } from 'pages/document/page';
import { useSelector, useDispatch } from 'react-redux';
import { polygonsSelector, pagesSelector } from 'domain/journal/selectors';
import { PageFactory } from 'domain/journal/helper';
import { getApprovalStampModeByApprovals } from 'domain/approvals';
import { getUiAFlStampModeSelector, setApprovalStampModeAction, isOpenAFPanelSelector } from 'domain/ui';
import Api from 'domain/api';

import { type DocumentsType, documentReadAction, constants } from 'domain/documents';
import type { TSignatureStatus } from 'pages/document/DocumentComponent/types.js.flow';
import type { RecordOf } from 'immutable';
import type { PageParams } from 'pages/document/types.js.flow';
import type { RenderSuccessData } from 'react-pdf';
import type { AngleType } from 'lib/pdfHelpers';
import { companyApprovalsStampFeatureEnabledSelector } from 'domain/companies';

type TUseDocumentHook = {|
  data: DocumentType,
  isFinancial: boolean,
  onDocRef: Function,
  signatureStatus: TSignatureStatus,
  width: number,
  startResizeWidth?: number,
  dokkaToken: string,
  companyId: string,
  scale: number,
|};

const generatePageParams = (document: DocumentsType) =>
  createPageParams(document.viewinfo.rotations, getRotateByDocID(document.documentID));

const mapStateToProps = (state) => ({
  polygons: polygonsSelector(state),
  pages: pagesSelector(state),
  stampModeByAF: getApprovalStampModeByApprovals(state),
  stampMode: getUiAFlStampModeSelector(state),
  isOpenAFPanel: isOpenAFPanelSelector(state),
  isApprovalStampFeatureEnabled: companyApprovalsStampFeatureEnabledSelector(state),
});

const useDocumentHook = ({
  data,
  isFinancial,
  onDocRef,
  width,
  startResizeWidth,
  dokkaToken,
  companyId,
  scale,
  signatureStatus,
  textractEnabledForDocument,
}: TUseDocumentHook) => {
  const { polygons, pages, stampModeByAF, stampMode, isOpenAFPanel, isApprovalStampFeatureEnabled } =
    useSelector(mapStateToProps);
  const dispatch = useDispatch();
  const [pageParams, setPageParams] = useState(List([]));
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [focusedPage, setFocusedPage] = useState(0);
  const [isPreviewOpen, setPreviewOpenState] = useState(true);
  const observersRef = useRef([]);
  const loadCancelableRef = useRef(null);
  const renderCancelableRef = useRef(null);
  const scrollViewRef = useRef();
  const scrollPositionRef = useRef(0);
  const pagesRef = useRef([]);
  const { documentID, protected: docProtected } = data;

  const isPaid = useMemo(() => isDocPaid(data), [data]);

  const isProtected = useMemo(() => docProtected && !password, [docProtected, password]);

  const isVisibleStamp = useMemo(
    () => isOpenAFPanel && stampMode !== 'hidden' && isApprovalStampFeatureEnabled,
    [isOpenAFPanel, stampMode, isApprovalStampFeatureEnabled],
  );

  const viewportWidth = useMemo(() => Math.min(MAX_DOC_WIDTH, startResizeWidth || width), [startResizeWidth, width]);

  const documentUrl = useMemo(() => {
    const urlParams = { documentID, dokkaToken, companyId };
    if (dokkaToken) {
      const params = ['disabled', 'hide'].includes(signatureStatus) ? { ...urlParams, unsigned: 1 } : urlParams;
      return Api.getDocumentUrl(params);
    }
    return null;
  }, [documentID, dokkaToken, companyId, signatureStatus]);

  const fileObj = useMemo(() => ({ url: documentUrl, password }), [documentUrl, password]);

  const markDocumentAsRead = useCallback(() => {
    if (data) {
      if (data.tags.has(constants.tags.new) && data.doctype === constants.doctype.general) {
        dispatch(documentReadAction(data.documentID));
      }
    }
  }, [data, dispatch]);

  const frameResizeScale = useMemo(() => {
    if (startResizeWidth) {
      const maxDocWidth = MAX_DOC_WIDTH - BORDER_WIDTH;
      const currentDocWidth = width - BORDER_WIDTH;
      const startDocWidth =
        maxDocWidth > startResizeWidth - BORDER_WIDTH ? startResizeWidth - BORDER_WIDTH : maxDocWidth;
      const currentWidth = Math.min(maxDocWidth, currentDocWidth);
      return startResizeWidth >= maxDocWidth && currentDocWidth >= maxDocWidth ? 1 : currentWidth / startDocWidth;
    }
    return 1;
  }, [startResizeWidth, width]);

  const handleOnLoad = useCallback(
    ({ numPages }) => {
      markDocumentAsRead();
      const pageParamsData = generatePageParams(data);
      const nextPageParams = createRange(0, numPages)
        .map(pageParamsData)
        .map((e) => (e.idx === 0 ? e.set('status', 'rendering') : e));
      setPageParams(nextPageParams);
    },
    [data, markDocumentAsRead],
  );

  const handlePageSuccess = useCallback(
    (docID, { pageInfo, pageIndex }: RenderSuccessData) => {
      if (docID !== documentID) {
        console.warn('render from previous document was omitted', docID, documentID);
        return;
      }
      const item = getPageParams(
        pageInfo,
        PageParamsFactory({
          idx: pageIndex,
          status: 'complete',
          rotate: pageParams.getIn([pageIndex, 'rotate'], 0),
          embdRotate: pageParams.getIn([pageIndex, 'embdRotate'], 0),
        }),
        viewportWidth - BORDER_WIDTH,
        scale,
      );
      updateStyle(pagesRef.current[pageIndex], [{ name: 'height', value: `${item.height}px` }]);
      setPageParams((prevPageParams) => prevPageParams.set(pageIndex, item));
    },
    [viewportWidth, documentID, pageParams, scale],
  );

  const handleRotate = useCallback(
    (idx: number, n: AngleType) => {
      setPageParams((prevPageParams) => prevPageParams.setIn([idx, 'rotate'], n));
      setRotateByDocID(documentID, { [idx]: n });
    },
    [documentID],
  );

  // define focused page from visible.
  const resolveFocusedPage = useCallback(() => {
    const box = scrollViewRef.current.getBoundingClientRect();
    const overlapMap = pagesRef.current.map((el, idx) => {
      const { top, bottom, height } = el.getBoundingClientRect();
      const cTop = Math.max(box.top, top);
      const cBottom = Math.min(box.bottom, bottom);
      const overlapRatio = (cBottom - cTop) / height;
      return { idx, overlapRatio };
    });

    const focused = overlapMap.reduce((res, item) => (item.overlapRatio > res.overlapRatio ? item : res), {
      idx: 0,
      overlapRatio: 0,
    });
    setFocusedPage(focused.idx);
  }, []);

  const handleVisible = useCallback(
    (entries) => {
      const { isIntersecting, target } = entries[0];
      const finder = findIndex(target);
      const idx = pagesRef.current.reduce(finder, undefined);

      if (isIntersecting && typeof idx === 'number') {
        if (pageParams.getIn([idx, 'status']) === null) {
          setPageParams((prevPageParams) => prevPageParams.setIn([idx, 'status'], 'rendering'));
        }
      }
    },
    [pageParams],
  );

  const handlePageRef = useCallback(
    (index: number) => (element: ?HTMLElement) => {
      if (element) {
        pagesRef.current[index] = element;
        // eslint-disable-next-line no-undef
        if (typeof observersRef.current[index] === 'undefined') {
          observersRef.current[index] = new IntersectionObserver(handleVisible, {
            root: scrollViewRef.current,
            rootMargin: '500px',
            threshold: 0,
          });
          updateStyle(element, [
            {
              name: 'height',
              value: `${viewportWidth * Math.sqrt(2)}px`,
            },
          ]);
          observersRef.current[index].observe(element);
        }
      }
    },
    [handleVisible, viewportWidth],
  );

  const onScroll = useCallback(
    (e: MouseEvent) => {
      if (Math.abs(scrollPositionRef.current - e.currentTarget.scrollTop) > 80 || e.currentTarget.scrollTop === 0) {
        scrollPositionRef.current = e.currentTarget.scrollTop;
        resolveFocusedPage();
      }
    },
    [resolveFocusedPage],
  );

  const onPasswordEnter = useCallback((passwordInput: string) => {
    setPassword(passwordInput);
    setPasswordError(false);
  }, []);

  const onDocumentLoadError = useCallback((error) => {
    console.warn('PDF LOAD ERROR: ', error);
    if (error.name === 'PasswordException') {
      setPassword('');
      setPasswordError(true);
    }
  }, []);

  const preparePolygonsForPage = useCallback(
    (index) => {
      const pd = pages.get((index + 1).toString());
      if (!pd) return PageFactory();
      const polygonData = pd.get('data', List()).reduce((a, v) => a.push(polygons.get(v)), List());
      return PageFactory({
        height: pd.height,
        width: pd.width,
        data: polygonData,
      });
    },
    [polygons, pages],
  );

  const isPageShow = useCallback((params: RecordOf<PageParams>): boolean => Boolean(params.status), []);

  const handleViewportRef = useCallback(
    (ref: HTMLElement) => {
      if (typeof onDocRef === 'function') onDocRef(ref);

      if (!isFinancial) {
        scrollViewRef.current = ref;
      }
    },
    [isFinancial, onDocRef],
  );

  const handleFinancialRef = useCallback(
    (ref) => {
      if (isFinancial) {
        scrollViewRef.current = ref;
      }
    },
    [isFinancial],
  );

  const setPreviewOpen = useCallback((isOpenState: boolean) => {
    setPreviewOpenState(isOpenState);
  }, []);

  useEffect(
    () => () => {
      if (loadCancelableRef.current) {
        loadCancelableRef.current.onCancel();
      }
      if (renderCancelableRef.current) {
        renderCancelableRef.current.onCancel();
      }
      observersRef.current.forEach((o) => o.disconnect());
    },
    [],
  );

  useEffect(() => {
    // TODO: if (prevProps.signatureStatus !== this.props.signatureStatus) {
    //  check this condition iw you will have bugs
    observersRef.current = [];
    pagesRef.current = [];
    setPageParams(List([]));
  }, [signatureStatus]);

  useEffect(() => {
    // change approval stamp mode according approvals flow state
    dispatch(setApprovalStampModeAction(stampModeByAF));
  }, [dispatch, stampModeByAF]);

  // render all pages when 2wm is enable
  useEffect(() => {
    if (textractEnabledForDocument) {
      setPageParams((prevPageParams) =>
        prevPageParams.map((page) => (page.status !== null ? page : page.set('status', 'rendering'))),
      );
    }
  }, [textractEnabledForDocument, pageParams]);

  const selectPage = useCallback((index: number) => {
    const pageEl = pagesRef.current[index];
    if (pageEl) {
      pageEl.scrollIntoView(true);
      setFocusedPage(index);
    }
  }, []);

  loadCancelableRef.current = cancellation(handleOnLoad);
  renderCancelableRef.current = cancellation(handlePageSuccess);

  return {
    selectPage,
    pageParams,
    password,
    passwordError,
    onPasswordEnter,
    onDocumentLoadError,
    frameResizeScale,
    preparePolygonsForPage,
    isPaid,
    isProtected,
    isPageShow,
    isVisibleStamp,
    viewportWidth,
    fileObj,
    stampMode,
    handleFinancialRef,
    handleViewportRef,
    handlePageRef,
    handleRotate,
    scrollViewRef: scrollViewRef.current,
    renderCancelable: renderCancelableRef.current,
    loadCancelable: loadCancelableRef.current,
    setPasswordError,
    pagesRef,
    onScroll,
    focusedPage,
    isPreviewOpen,
    setPreviewOpen,
  };
};

export default useDocumentHook;

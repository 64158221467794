// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { documentSelector } from 'domain/documents';

import useStickyNotes from 'pages/common/Dialog/DialogNotes/useStickyNotes';

import { NoteBar, NoteField, NotePaletteBar, NotePaletteRadio, Puller, ToggleButton } from './StyledComponents';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Button from '@mui/material/Button';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import { NOTE_PALETTE, NOTE_PALETTE_DEFAULT_KEY } from 'components/LabeledThemeProvider/notesPalette';

const Notes = () => {
  const { formatMessage } = useIntl();

  const { notes: content, notesColor: noteColorKey } = useSelector(documentSelector);

  const [open, setOpen] = useState(false);

  const openDrawer = useCallback(() => {
    setOpen(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setOpen(false);
  }, []);

  const {
    value,
    setValue,
    colorKey,
    setColorKey,
    defaultValidNotePaletteKey,
    loading,
    handleChange,
    handleChangeRadio,
    onNotesUpdate,
    onNotesRemove,
    disableUpdate,
  } = useStickyNotes({ onClose: closeDrawer, content, noteColorKey, closeAfterSave: true });

  useEffect(() => {
    if (!open) {
      setValue(content);
      setColorKey(defaultValidNotePaletteKey);
    } else {
      setValue(content);
    }
  }, [content, open, defaultValidNotePaletteKey, setValue, setColorKey]);
  return (
    <>
      {!open && (
        <ToggleButton
          onClick={openDrawer}
          borderColor={NOTE_PALETTE[colorKey].border}
          bgColor={NOTE_PALETTE[colorKey].color}
        >
          <div style={{ transform: 'rotate(-90deg)' }}>
            <Typography variant="subtitle2">
              {formatMessage({ id: 'document.show.stickyNote', defaultMessage: 'Note' })}
            </Typography>
          </div>
        </ToggleButton>
      )}

      <SwipeableDrawer
        PaperProps={{ sx: { height: '40vh', borderTopLeftRadius: 8, borderTopRightRadius: 8 } }}
        anchor="bottom"
        open={open}
        onOpen={openDrawer}
        onClose={closeDrawer}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box>
          <Puller />
          <Stack direction="row" justifyContent="space-between" p={3} pb={1} alignItems="center">
            <Typography variant="body1">
              {formatMessage({ id: 'document.show.stickyNote', defaultMessage: 'Note' })}
            </Typography>
            <Button variant="text" onClick={onNotesUpdate} disabled={disableUpdate}>
              {formatMessage({
                id: 'button.save',
                defaultMessage: 'Save',
              })}
            </Button>
          </Stack>
        </Box>
        <Divider sx={{ mb: 2, mx: 2 }} />
        <Box
          sx={{
            px: 2,
            pb: 2,
            height: '100%',
            overflow: 'auto',
          }}
        >
          <Stack height="100%">
            <NoteField
              value={value}
              onChange={handleChange}
              placeholder={formatMessage({
                id: 'notes.placeholder',
                defaultMessage: 'Write note text',
              })}
              style={{ backgroundColor: NOTE_PALETTE[colorKey].color }}
            />
            <Box bgcolor={NOTE_PALETTE[colorKey].color} sx={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}>
              <NoteBar>
                <NotePaletteBar
                  onChange={handleChangeRadio}
                  value={colorKey}
                  defaultChecked={NOTE_PALETTE_DEFAULT_KEY}
                  name="color"
                  sx={{ position: 'static' }}
                >
                  {Object.entries(NOTE_PALETTE).map(([key, { color }]) => (
                    <NotePaletteRadio
                      value={key}
                      key={key}
                      icon={<CircleIcon sx={{ color }} />}
                      checkedIcon={<CheckCircleIcon />}
                      size="small"
                    />
                  ))}
                </NotePaletteBar>
                <IconButton aria-label="delete" onClick={onNotesRemove} disabled={!content || loading}>
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </NoteBar>
            </Box>
          </Stack>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default Notes;

// @flow
import React, { useCallback } from 'react';

import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';

type TProps = {
  linkid: string,
  onClick: (tag: string) => Promise<*>,
};

const LinkedButton = ({ linkid, onClick }: TProps) => {
  const handleClick = useCallback(() => {
    onClick(linkid);
  }, [linkid, onClick]);

  if (!linkid) return null;

  return (
    <IconButton onClick={handleClick} sx={{ fontSize: '22px' }}>
      <AttachFileIcon fontSize="inherit" />
    </IconButton>
  );
};
export default LinkedButton;

import { Fields } from '../../helpers';
import { LEVEL_FLOW_TYPE } from 'domain/approvals/constatns';
import { nanoid } from 'nanoid';

export const levelItemFactory = (values) => ({
  id: nanoid(10),
  [Fields.approvers]: values?.[Fields.approvers] || [],
  [Fields.minApprovers]: values?.[Fields.minApprovers] || 1,
  [Fields.minAmount]: values?.[Fields.minAmount] || 0,
  [Fields.flowType]: values?.[Fields.flowType] || LEVEL_FLOW_TYPE.SEQUENTIAL,
});

export const levelItemTouchedFactory = () => ({
  [Fields.approvers]: false,
  [Fields.minApprovers]: false,
  [Fields.minAmount]: false,
  [Fields.flowType]: false,
});

export const levelItemErrorFactory = (values) => ({
  [Fields.approvers]: values?.[Fields.approvers] || null,
  [Fields.minApprovers]: values?.[Fields.minApprovers] || null,
  [Fields.minAmount]: values?.[Fields.minAmount] || null,
  [Fields.flowType]: values?.[Fields.flowType] || null,
});

export const formDataFactory = (values) => ({
  [Fields.name]: values?.[Fields.name] || '',
  [Fields.levels]: values?.[Fields.levels] ? values?.[Fields.levels].map(levelItemFactory) : [levelItemFactory()],
});

export const ErrorsFactory = (values) => ({
  [Fields.name]: values?.[Fields.name] || null,
  [Fields.levels]: values?.[Fields.levels] || [levelItemErrorFactory()],
});

export const TouchedFactory = (values) => ({
  [Fields.name]: false,
  [Fields.levels]: values?.[Fields.levels]
    ? values[Fields.levels].map(levelItemTouchedFactory)
    : [levelItemTouchedFactory()],
});

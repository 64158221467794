// @flow
import { List, OrderedMap, Set, type RecordOf } from 'immutable';
import { compose } from 'redux';
import moment from 'moment';
// eslint-disable-next-line import/no-cycle
import {
  type DocumentsList,
  type Document,
  type RawValidatedDocument,
  type DocumentsStore,
  type DocumentsType,
  type ValidatedDocumentsType,
  type TypeTag,
  type OptionalTag,
  type ViewinfoType,
  DocumentFactory,
  ValidatedDocumentFactory,
  DocumentsFactory,
  TypeTags,
  StatusTags,
  OptionalTags,
  ViewinfoFactory,
  PairsTagsStatuses,
  StatusTagsSet,
} from './documentsModel';
import { ApprovalsStoreAdapter } from 'domain/approvals';
import type { GridHeaderItemType, TOperators } from 'domain/documents/types.js.flow';
import type { TColumnDefs, Column } from 'pages/company/grid/types.js.flow';
import isObject from 'lodash/isObject';
import { testChangeProps } from 'lib/propHelpers';
import CONST, { GRID_CONFIG_CHANGES_STORAGE_KEY } from './constants';
import { type TPublishTypes } from 'pages/document/components/DocumentPublishButton/DropdownButton/types.js.flow';

export type TFilterItem = {|
  name: string,
  operator: TOperators,
  type: GridHeaderItemType,
|};

const OPERATORS = {
  eq: 'equals',
  neq: 'notEqual',
  contains: 'contains',
  notContains: 'notContains',
  startsWith: 'startsWith',
  endsWith: 'endsWith',
  exist: 'exist',
  notExist: 'notExist',
  gt: 'greaterThan',
  gte: 'greaterThanOrEqual',
  lt: 'lessThan',
  lte: 'lessThanOrEqual',
  inrange: 'inRange',
  after: 'after',
  afterOrOn: 'afterOrOn',
  before: 'before',
  beforeOrOn: 'beforeOrOn',
};

const withCustomOperatos = {
  ...OPERATORS,
  exist: {
    displayKey: 'exist',
    displayName: 'Exist',
    predicate: ([filterValue], cellValue) => !!cellValue,
    hideFilterInput: true,
  },
  notExist: {
    displayKey: 'notExist',
    displayName: 'Not exist',
    predicate: ([filterValue], cellValue) => !cellValue,
    hideFilterInput: true,
  },
  after: {
    displayKey: 'after',
    displayName: 'After',
    predicate: ([filterValue], cellValue) =>
      moment(cellValue).startOf('day').isAfter(moment(filterValue).startOf('day')),
  },
  afterOrOn: {
    displayKey: 'afterOrOn',
    displayName: 'After or on',
    predicate: ([filterValue], cellValue) =>
      moment(cellValue).startOf('day').isSameOrAfter(moment(filterValue).startOf('day')),
  },
  before: {
    displayKey: 'before',
    displayName: 'Before',
    predicate: ([filterValue], cellValue) =>
      moment(cellValue).startOf('day').isBefore(moment(filterValue).startOf('day')),
  },
  beforeOrOn: {
    displayKey: 'beforeOrOn',
    displayName: 'Before or on',
    predicate: ([filterValue], cellValue) =>
      moment(cellValue).startOf('day').isSameOrBefore(moment(filterValue).startOf('day')),
  },
};

const operatorsByType = {
  tag: ['contains', 'notContains', 'startsWith', 'endsWith', 'exist', 'notExist'],
  approval: ['contains', 'notContains', 'startsWith', 'endsWith', 'exist', 'notExist'],
  string: ['contains', 'notContains', 'startsWith', 'endsWith', 'exist', 'notExist'],
  number: ['eq', 'neq', 'gt', 'gte', 'lt', 'lte', 'inrange'],
  date: ['beforeOrOn', 'afterOrOn', 'inrange'],
  select: [],
  extraSelect: [],
};

const DEEFAULT_OPTION_LIST = {
  string: OPERATORS.startsWith,
  number: OPERATORS.eq,
  integer: OPERATORS.eq,
  date: OPERATORS.beforeOrOn,
};

const createFiltersWithDefaultOption = (operator: ?TOperators, filter, format: string) => ({
  ...filter,
  filterParams: {
    ...(filter.filterParams || {}),
    ...(operator && { defaultOption: OPERATORS[operator] }),
    closeOnApply: true,
    format,
  },
});

const filterParams = {
  date: {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      if (!cellValue) return -1;
      const filterDate = moment(filterLocalDateAtMidnight);
      const cellDate = moment(cellValue);
      if (filterDate.isSame(cellDate, 'date')) {
        return 0;
      }
      if (filterDate.isAfter(cellDate)) {
        return -1;
      }
      if (filterDate.isBefore(cellDate)) {
        return 1;
      }
      return 0;
    },
    inRangeInclusive: true,
  },
};

const filters = [
  {
    tag: { filter: 'agTextColumnFilter' },
  },
  {
    approval: { filter: 'agTextColumnFilter' },
  },
  {
    string: { filter: 'agTextColumnFilter' },
  },
  {
    select: { filter: 'agSetColumnFilter' },
  },
  {
    extraSelect: { filter: 'agSetColumnFilter', filterParams: { cellRenderer: 'filterSetRender' } },
  },
  {
    number: { filter: 'agNumberColumnFilter' },
  },
  {
    amount: { filter: 'agNumberColumnFilter' },
  },
  {
    integer: { filter: 'agNumberColumnFilter' },
  },
  {
    date: {
      filter: 'agDateColumnFilter',
      filterParams: filterParams.date,
    },
  },
];

const DEFAULT_FILTER_TYPE = 'string';

const FILTERS_LIST = filters.reduce((acc, v) => {
  const [type, filter] = Object.entries(v)[0];
  const operators = operatorsByType[type];

  acc[type] = {
    ...filter,
    filterParams: {
      ...(filter.filterParams || {}),
      defaultOption: DEEFAULT_OPTION_LIST[type],
      filterOptions: operators && operators.map((operator) => withCustomOperatos[operator]),
      showTooltips: true,
    },
  };

  return acc;
}, {});

export type Data = {|
  count: number,
  processing: number,
  company_total: number,
  count_without_link_panels: number,
  document?: Document,
  items: Array<Document>,
  pageToken: string,
|};

type DocumentGroup = OrderedMap<string, List<Document | void>>;

function checkStatus(tags) {
  const statusTags = StatusTagsSet.intersect(tags);
  let statuses = statusTags.map((tag) => StatusTags[tag]);
  PairsTagsStatuses.forEach((pair) => {
    if (pair.tags.intersect(tags).size === pair.tags.size) {
      statuses = statuses.add(pair.status);
    }
  });
  return statuses.toArray();
}

// eslint-disable-next-line max-len
export function parseTags(document: Document | RawValidatedDocument): Document | RawValidatedDocument {
  // we know tags is Array, not Set
  let tags = ((document.tags || []: any): Array<string>);
  tags = tags.map((item) => item.trim());
  // @to-do quick fallback. return empty values for type and status if no tags present
  const TypeTagsSet: Set<TypeTag> = Set.fromKeys(TypeTags);
  const typeKey = TypeTagsSet.intersect(tags).toArray();
  const OptionalTagsSet: Set<OptionalTag> = Set.fromKeys(OptionalTags);
  const protectedKey = OptionalTagsSet.intersect(tags).toArray();
  return Object.assign(document, {
    doctype: typeKey.length ? TypeTags[typeKey[0]] : undefined,
    status: checkStatus(tags),
    protected: protectedKey.length ? Boolean(OptionalTags[protectedKey[0]]) : false,
  });
}

function castTagsToSet(document: Document | RawValidatedDocument): Document | RawValidatedDocument {
  const castedDocument = document;
  if (document && Array.isArray(document.tags)) {
    castedDocument.tags = Set(castedDocument.tags);
  }
  return castedDocument;
}

function castSnakeCaseProps(document: Document | RawValidatedDocument): Document | RawValidatedDocument {
  const { notes_color: notesColor, ...tail } = document;
  return { ...tail, notesColor };
}

export function documentCompositionFunctionsAdapter(d: Document) {
  return compose(DocumentFactory, castTagsToSet, parseTags, castSnakeCaseProps)(d);
}

export function documentsListAdapter(l: Array<Document>): DocumentsList {
  // eslint-disable-next-line max-len
  return l.reduce((a, v: Document) => a.set(v.documentID, documentCompositionFunctionsAdapter(v)), new OrderedMap());
}

export function documentsAdapter({
  items,
  pageToken,
  count,
  processing,
  // eslint-disable-next-line camelcase
  company_total,
  // eslint-disable-next-line camelcase
  count_without_link_panels,
}: Data): DocumentsStore {
  return DocumentsFactory({
    list: documentsListAdapter(items),
    pageToken,
    count,
    processing,
    company_total,
    count_without_link_panels,
  });
}

export function viewInfoAdapter({ pages, rotations }: ViewinfoType): RecordOf<ViewinfoType> {
  const positiveRotations = (rotations || []).map((angle) => (angle < 0 ? angle + 360 : angle));
  return ViewinfoFactory({
    pages,
    rotations: List(positiveRotations),
  });
}

export function documentAdapter(document: Document): { document: DocumentsType } {
  return {
    document: DocumentFactory({
      ...compose(castTagsToSet, parseTags, castSnakeCaseProps)(document),
      viewinfo: viewInfoAdapter(document.viewinfo),
    }),
  };
}

// eslint-disable-next-line max-len
export function documentAdapterValidated(document: RawValidatedDocument): { document: ValidatedDocumentsType } {
  return {
    document: ValidatedDocumentFactory({
      ...compose(castTagsToSet, parseTags, castSnakeCaseProps)(document),
      viewinfo: viewInfoAdapter(document.viewinfo),
      approvals: ApprovalsStoreAdapter(document.approvals),
      // eslint-disable-next-line max-len
      acceptValidationError: document.details && document.details.error ? document.details.error : null,
    }),
  };
}

export function sorterByCreated<T: { created: string, documentID: string }>(a: T, b: T) {
  const aCreated = new Date(a.created).getTime();
  const bCreated = new Date(b.created).getTime();

  return bCreated === aCreated ? a.documentID.localeCompare(b.documentID) : bCreated - aCreated;
}

export const sorterByFavoriteOrCreated = <T: { +created: string, favorite: boolean }>(a: T, b: T) =>
  // $FlowFixMe
  b.favorite - a.favorite || sorterByCreated(a, b);

export function documentGroup(list: List<Document>): DocumentGroup {
  return OrderedMap(list.groupBy((e) => moment(e.created).format('MM-YYYY')));
}

// @to-do use function composition for next 2 functions
export function documentsWithUniqLinkedUngrouped(d: List<Document>): List<Document | void> {
  // filter linked document and group by linkID
  const groupedLinkedDocs = d.filter((doc) => doc.linkid).groupBy((doc) => doc.linkid);

  // sort linked documents by favorites and created fields
  const uniqLinkedDocs = groupedLinkedDocs.reduce((a, v, key) => {
    // favorite => fresh doc => old doc - always
    const sortedLinkedList = v.sort(sorterByFavoriteOrCreated);

    return a.set(key, sortedLinkedList);
  }, new OrderedMap());

  // list of linked ids which were set
  let alreadySetCoverForLinkedIDs = new List();

  return d.reduce((list, document) => {
    if (alreadySetCoverForLinkedIDs.includes(document.linkid)) return list;

    if (document.linkid) {
      alreadySetCoverForLinkedIDs = alreadySetCoverForLinkedIDs.push(document.linkid);

      if (!document.favorite) {
        const linkedDocuments = uniqLinkedDocs.get(document.linkid);
        // first document will be always favorite or more fresh by date
        return list.push(linkedDocuments.first());
      }
    }

    return list.push(document);
  }, new List());
}

export function documentGroupSupplier(list: List<Document>) {
  return list
    .groupBy((e) => e.stateColumn)
    .map((grouppedList) => {
      // group linked inside each supplier dashboard column as opposed to standard workspace
      // where we group by linked first.
      // this allows 2 linked docs to appear on 2 separate columns
      const grouppedListUnique = documentsWithUniqLinkedUngrouped(grouppedList);
      return OrderedMap(
        grouppedListUnique.sort(sorterByFavoriteOrCreated).groupBy((e) => moment(e.created).format('MM-YYYY')),
      );
    });
}

export const documentsWithUniqLinkedSupplier = documentGroupSupplier;

export const documentsWithUniqLinked = (d: List<Document>): DocumentGroup => {
  const list = documentsWithUniqLinkedUngrouped(d);

  return documentGroup(list);
};

export function documentsWithoutLinkedAndAccepted(d: List<Document>): DocumentGroup {
  return d.filter((doc) => !doc.linkid);
}

export const acceptedSignTypes = Set(['pdf', 'jpg', 'jpeg', 'png', 'docx', 'xlsx', 'xlsm', 'pptx', 'tiff', 'tif']);

export function checkAcceptSignType(id: string): boolean {
  const extension = id.split('.').pop();
  return acceptedSignTypes.has(extension);
}

export const isDocPaid = (doc: Document): boolean => doc.tags.includes(CONST.tags.paid);

// grid
export const addFilters = (columns: GridHeaderItemType[]): Array<*> =>
  columns.map(({ format, ...column }) => {
    const filter = FILTERS_LIST[column.type] || FILTERS_LIST[DEFAULT_FILTER_TYPE];
    const operators = operatorsByType[column.type];
    const operator: ?TOperators = (operators && operators.includes(column.operator) && column.operator) || undefined;

    return {
      ...column,
      ...createFiltersWithDefaultOption(operator, filter, format),
    };
  });

export const GridHeadersFactory = (columns: GridHeaderItemType[]): TColumnDefs[] =>
  columns.map(({ operator, locale, ...rest }) => ({ ...rest }));

export const GRID_SERVICE_KEYS = {
  PREVIEW_CHECKBOX_COLUMN_NAME: '__SERVICE_CHECKBOX_PREVIEW_COLUMN',
  PREVIEW_BTN_COLUMN_NAME: '__SERVICE_BTN_PREVIEW_COLUMN',
  CONTEXT_MENU_COLUMN_NAME: '__SERVICE_CONTEXT_MENU_COLUMN',
  COLUMNS_VISIBILITY_MENU: '__SERVICE_COLUMNS_VISIBILITY_MENU',
  COLUMNS_MASTER_VIEW: '__SERVICE_COLUMNS_MASTER_VIEW',
  COLUMNS_LINKED_ICON: '__SERVICE_COLUMNS_LINKED_ICON',
};

export const addSelectionMasterDetailToFirstColumn = (columns: TColumnDefs[]): TColumnDefs[] =>
  columns.length
    ? [
        {
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
          lockPinned: true,
          lockPosition: true,
          pinned: 'left',
          maxWidth: 80,
          minWidth: 55,
          resizable: false,
          hide: false,
          filterParams: {},
          cellRenderer: 'agGroupCellRenderer',
          cellStyle: {
            border: 'none !important',
          },
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          field: GRID_SERVICE_KEYS.PREVIEW_CHECKBOX_COLUMN_NAME,
          valueFormatter: () => '',
          headerName: '',
        },
        ...columns,
      ]
    : columns;

export const allowedExportFormatsForGrid = ['csv', 'xlsx'];

export const convertToHashMap = (obj) =>
  Object.entries(obj).reduce((res, [key, val]) => ({ ...res, [key]: isObject(val) ? JSON.stringify(val) : val }), {});

export const compareFiltersChange = (filersOne, filtersTwo) => {
  const filersOneHashMap = convertToHashMap(filersOne);
  const filersTwoHashMap = convertToHashMap(filtersTwo);
  return testChangeProps(filersOneHashMap, filersTwoHashMap);
};

export const convertToSortHash = (sortParams) =>
  sortParams.reduce((res, { colId, sort }) => ({ ...res, [colId]: sort }), {});

export const orderedColumns = (columns: TColumnDefs[]) => {
  const { left, center, right } = columns.reduce(
    (res, column) => {
      const pinned = column.pinned ? column.pinned : 'center';
      return { ...res, [pinned]: [...res[pinned], column] };
    },
    { left: [], center: [], right: [] },
  );
  return [...left, ...center, ...right];
};

export const isColumnsStateChange = (prevState, nextState) => {
  const orderedPrevState = orderedColumns(prevState);
  const orderedNextState = orderedColumns(nextState);

  return orderedPrevState.some((prev, index) => {
    const next = orderedNextState[index] || {};
    const diff = testChangeProps(prev, next);
    return Object.keys(diff).length;
  });
};

export const getCurrentStoredConfig = () => {
  const record = window.localStorage.getItem(GRID_CONFIG_CHANGES_STORAGE_KEY);
  return record ? JSON.parse(record) : {};
};

export const updateGridConfigChanges = (params) => {
  const { localStorage } = window;
  const defaultState = {
    gridColumns: null,
    gridSorting: null,
    gridFilters: null,
  };

  // companyId, currentPresetId
  const currentStoredConfig = getCurrentStoredConfig();
  const storeCurrentConfig =
    currentStoredConfig.companyId === params.companyId && currentStoredConfig.currentPresetId === params.currentPresetId
      ? currentStoredConfig
      : {};

  const update = { ...defaultState, ...storeCurrentConfig, ...params };
  localStorage.setItem(GRID_CONFIG_CHANGES_STORAGE_KEY, JSON.stringify(update));
};

export const deleteGridConfigChanges = () => {
  window.localStorage.removeItem(GRID_CONFIG_CHANGES_STORAGE_KEY);
};

const getCurrentPublishTypeID = (userId: string) => `publishType-${userId}`;

export const getCurrentPublishType = (userId: string) => window.localStorage.getItem(getCurrentPublishTypeID(userId));

export const updateCurrentPublishType = (userId: string, publishType: TPublishTypes) =>
  window.localStorage.setItem(getCurrentPublishTypeID(userId), publishType);

export const decodeArrayBufferText = (value) => {
  try {
    const dataView = new DataView(value);
    const decoder = new TextDecoder('utf8');
    return JSON.parse(decoder.decode(dataView));
  } catch (e) {
    return e;
  }
};

export const requiresColumnMigration = (columns?: Column[]): boolean => {
  if (!Array.isArray(columns)) return false;
  return columns?.findIndex(({ colId }) => colId === GRID_SERVICE_KEYS.COLUMNS_VISIBILITY_MENU) === -1;
};

export const changeColumnsPositionForGridRedesign = (columns: Column[]) => {
  const cols = columns.filter(({ colId }) => colId !== GRID_SERVICE_KEYS.CONTEXT_MENU_COLUMN_NAME);

  return [
    {
      aggFunc: null,
      colId: GRID_SERVICE_KEYS.CONTEXT_MENU_COLUMN_NAME,
      flex: null,
      hide: false,
      pinned: 'left',
      pivot: false,
      pivotIndex: null,
      rowGroup: false,
      rowGroupIndex: null,
      sort: null,
      sortIndex: null,
      width: 50,
    },
    ...cols,
    {
      aggFunc: null,
      colId: GRID_SERVICE_KEYS.COLUMNS_VISIBILITY_MENU,
      flex: null,
      hide: false,
      pinned: 'right',
      pivot: false,
      pivotIndex: null,
      rowGroup: false,
      rowGroupIndex: null,
      sort: null,
      sortIndex: null,
      width: 50,
    },
  ];
};

import { useState, useEffect } from 'react';
import { useApiToken } from 'lib/apiTokenKeeper';
import Api from 'domain/api';
import { useParams } from 'react-router-dom';

export default () => {
  const [grid, loadGrid] = useState({});
  const dokkaToken = useApiToken();
  const { companyId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await Api.getSuppliersDefaults({
          params: {
            dokkaToken,
            companyId,
          },
        });
        loadGrid(data);
      } catch (error) {
        console.log('error fetching supplier defaults data');
      }
    };
    // effect might be run before token is retrieved
    if (dokkaToken) fetchData();
  }, [dokkaToken]);
  return { grid, updateGridData: loadGrid };
};

// @flow
import { selector } from 'lib/selectors';
import type { DokkaStore } from '../types.js.flow';
import { compose } from 'redux';
import find from 'lodash/find';
import get from 'lodash/get';
import identity from 'lodash/identity';

import {
  documentsWithUniqLinked,
  sorterByFavoriteOrCreated,
  addFilters,
  addSelectionMasterDetailToFirstColumn,
  GridHeadersFactory,
  compareFiltersChange,
  convertToSortHash,
  isColumnsStateChange,
  documentsWithUniqLinkedSupplier,
} from './helpers';
import { isDokkaSupportSelector, userGUIDSelector } from 'domain/env/envSelector';
import { chatAllUsersByIdSelector, chatParticipantsSelector } from 'domain/chat/chatSelector';
import CONST, { WS_GRID_DEFAULT_PRESET_ID, WS_GRID_PRESET_TYPES } from './constants';
import { wsGridPresetAdapter } from './adapters';
import { isChangeAnyProps } from 'lib/propHelpers';
// eslint-disable-next-line import/no-cycle
import { categoriesListSelector } from 'domain/categories';
// eslint-disable-next-line import/no-cycle
import {
  companiesByIdSelector,
  companyFeatureSetCompanyIdSelector,
  companySigningsCompanyIdSelector,
  configurationCompanySelector,
} from '../companies';
import { erpsSelector } from '../settings';
import { Set, type Map, List } from 'immutable';
import { cachedDocumentFactory, type Document, type DocumentsType } from 'domain/documents/documentsModel';
import { textractEnabledSelector } from 'domain/textract';
import { rtlEnable } from 'domain/env';
import { textractForVendorEnabledSelector } from 'domain/journal/selectors';
import { approversSelector } from 'domain/approvals/selectors';

type SelectorType<T> = (s: DokkaStore) => T;

const documents = (state) => state.documents;

export const tokenSelector = selector(documents, (d) => d.dokkaToken);
export const editableDocSelector = selector(documents, (d) => d.editableDoc);
export const recentTagsSelector = selector(documents, (d) => d.recentTags);
export const recentTagsCompanyIdSelector = selector(documents, (d) => d.recentTagsCompanyId);
export const pageTokenSelector = selector(documents, (d) => d.pageToken);
export const documentsByIdSelector = selector(documents, (d) => d.list);
export const linkedSelector = selector(documents, (d) => d.linked);
export const linkingTagSelector = selector(documents, (d) => d.linkingTag);
export const documentLinkedSelector = selector(linkedSelector, (l) => l.list);
export const documentLinkedCountSelector = selector(linkedSelector, (l) => l.count);
export const documentLinkedOpenStatusSelector = selector(linkedSelector, (l) => l.isOpen && !!l.list.size);
export const getDocumentsForBulkApprove = (documentIds: Set<string>) =>
  selector(documentsByIdSelector, userGUIDSelector, (documentList: Map<string, DocumentsType>, uID: string) =>
    documentList.filter((d) => {
      const isApprovalNext = d.getIn(['approvals', 'next']);
      const approvalStatus = d.getIn(['approvals', 'status']);
      const isFlowActive = approvalStatus !== 'draft' && approvalStatus !== null;
      const isForbiddenTags = [CONST.tags.isScheduledApproval, CONST.tags.isScheduledAcceptance].some((tag: string) =>
        d.tags.has(tag),
      );

      return (
        documentIds.includes(d.documentID) &&
        !isForbiddenTags &&
        isApprovalNext &&
        isApprovalNext === uID &&
        isFlowActive
      );
    }),
  );
export const getDocumentsForBulkPaid = (documentIds: Set<string>) =>
  selector(documentsByIdSelector, (documentList: Map<string, DocumentsType>) =>
    documentList.filter((d) => {
      // if b-end dont detect paid status of document, by default we mark as UNPAID, and we can change to PAID
      const isUnknownType = ![CONST.tags.unpaid, CONST.tags.paid].some((t) => d.tags.has(t));

      return (
        documentIds.includes(d.documentID) &&
        d.tags.has(CONST.tags.financial) &&
        (d.tags.has(CONST.tags.unpaid) || isUnknownType)
      );
    }),
  );
export const getDocumentsForBulkUnpaid = (documentIds: Set<string>) =>
  selector(documentsByIdSelector, (documentList: Map<string, DocumentsType>) =>
    documentList.filter(
      (d) => documentIds.includes(d.documentID) && d.tags.has(CONST.tags.financial) && d.tags.has(CONST.tags.paid),
    ),
  );
export const getDocumentsForBulkActivateApprovalFlow = (documentIds: Set<string>) =>
  selector(documentsByIdSelector, (documentList: Map<string, DocumentsType>) =>
    documentList.filter((d) => documentIds.includes(d.documentID) && d.approvals.status === 'draft'),
  );

export const getDocumentsForBulkDeactivateApprovalFlow = (documentIds: Set<string>) =>
  selector(documentsByIdSelector, (documentList: Map<string, DocumentsType>) =>
    documentList.filter((d) => documentIds.includes(d.documentID) && d.approvals.status === 'active'),
  );

export const getDocumentsApprovers = (documentIds: Set<string>) =>
  selector(documentsByIdSelector, (documentList: Map<string, DocumentsType>) =>
    documentList.reduce((acc, d) => {
      if (documentIds.includes(d.documentID) && d.approvals.nodes && !d.tags.has(CONST.tags.approvalComplete)) {
        d.approvals.nodes.forEach(({ username }) => {
          acc.push(username);
        });
      }
      return [...new Set(acc)];
    }, []),
  );

export const getDocumentsForBulkApproverReplace = (documentIds: Set<string>, user: string) =>
  selector(documentsByIdSelector, (documentList: Map<string, DocumentsType>) =>
    documentList
      .filter((d) => {
        if (documentIds.includes(d.documentID) && d.approvals.nodes && !d.tags.has(CONST.tags.approvalComplete)) {
          const a = d.approvals.nodes.filter(
            ({ status, username }) => username === user && (status === 'pending' || status === 'draft'),
          );
          return !!a.length;
        }
        return false;
      })
      .keySeq()
      .toArray(),
  );
// check document ids if they are mixed (single document and linked are together)
export const isMixedDocumentsForBulkMoveToCompanySelector = (documentIds: Set<string>) =>
  selector(documentsByIdSelector, (documentList: Map<string, DocumentsType>) =>
    Boolean(
      documentList
        .filter((d) => documentIds.includes(d.documentID) && d.linkid)
        .keySeq()
        .toArray().length,
    ),
  );

export const getApproveDocumentsCount = selector(
  documentsByIdSelector,
  userGUIDSelector,
  (documentList: Map<string, DocumentsType>, uID: string) =>
    documentList.filter((d) => d.tags.includes(CONST.tags.approvalPending) && d.approvals.next === uID).size,
);

export const savedGridDefaultColumnsSelector = selector(documents, (d) => d.gridDefaultColumns);

export const savedGridFiltersSelector = selector(documents, (d) => d.gridFilters);
export const savedGridSortingSelector = selector(documents, (d) => d.gridSorting);
export const savedGridColumnsSelector = selector(documents, (d) => d.gridColumns);

export const gridResetSelector = selector(documents, (d) => d.gridReset);
export const gridRawPresetsSelector = selector(documents, (d) => d.gridPresets);
export const gridRawPresetsLoadedSelector = selector(documents, (d) => d.gridPresetsLoaded);
export const gridSharePresetSelector = selector(documents, (d) => d.sharePreset);

export const gridDefaultPresetSelector = selector(savedGridDefaultColumnsSelector, (gridDefaultColumns) =>
  wsGridPresetAdapter({
    id: WS_GRID_DEFAULT_PRESET_ID,
    name: 'Default',
    isAll: false,
    type: WS_GRID_PRESET_TYPES.default,
    config: {
      gridColumns: gridDefaultColumns || [],
      gridFilters: {},
      gridSorting: [],
    },
  }),
);

export const gridPresetsSelector = selector(
  gridRawPresetsSelector,
  gridSharePresetSelector,
  gridDefaultPresetSelector,
  (gridPresets, sharePreset, gridDefaultPreset) => {
    const shared = sharePreset ? [sharePreset] : [];
    return [gridDefaultPreset, ...shared, ...gridPresets];
  },
);

export const gridCurrentPresetIdSelector = selector(documents, (d) => d.currentPresetId);

export const gridCurrentPresetSelector = selector(
  gridCurrentPresetIdSelector,
  gridPresetsSelector,
  (id, presets) => find(presets, (p) => p.id === id) || presets[0],
);

export const gridCurrentFiltersSelector = selector(
  gridCurrentPresetSelector,
  savedGridFiltersSelector,
  (currentPreset, changedGridFilters) => {
    const { gridFilters = null } = get(currentPreset, 'config', {});
    return changedGridFilters || gridFilters || {};
  },
);

export const gridCurrentSortSelector = selector(
  gridCurrentPresetSelector,
  savedGridSortingSelector,
  (currentPreset, changedGridSorting) => {
    const { gridSorting = null } = get(currentPreset, 'config', {});
    return changedGridSorting || gridSorting || [];
  },
);

export const gridCurrentColumnsStateSelector = selector(
  gridCurrentPresetSelector,
  savedGridDefaultColumnsSelector,
  savedGridColumnsSelector,
  (currentPreset, defaultGridColumnsState, changedGridColumnsState) => {
    const { gridColumns = null } = get(currentPreset, 'config', {});
    return changedGridColumnsState || gridColumns || defaultGridColumnsState || null;
  },
);

export const gridCurrentSettingsStateSelector = selector(
  gridCurrentFiltersSelector,
  gridCurrentSortSelector,
  gridCurrentColumnsStateSelector,
  (gridFilters, gridSorting, gridColumnsState) => ({ gridFilters, gridSorting, gridColumnsState }),
);

export const isFilterChangeSelector = selector(
  gridCurrentPresetSelector,
  gridCurrentFiltersSelector,
  (currentPreset, currentFilters) => {
    const { gridFilters } = get(currentPreset, 'config', {});
    const diff = compareFiltersChange(gridFilters || {}, currentFilters || {});
    return Object.keys(diff).length > 0;
  },
);

export const isSortChangeSelector = selector(
  gridCurrentPresetSelector,
  gridCurrentSortSelector,
  (currentPreset, currentSorting) => {
    const { gridSorting } = get(currentPreset, 'config', {});
    return isChangeAnyProps(convertToSortHash(gridSorting || []), convertToSortHash(currentSorting || []));
  },
);

export const isColumnsChangeSelector = selector(
  gridCurrentPresetSelector,
  gridCurrentColumnsStateSelector,
  (currentPreset, currentColumns) => {
    const { gridColumns } = get(currentPreset, 'config', {});
    return isColumnsStateChange(gridColumns || [], currentColumns || []);
  },
);

export const savedDocumentListWithFiltersSelector = selector(documents, (d) => d.gridDocumentListWithFilters);
export const justPublishedDocumentSelector = selector(documents, (d) => d.justPublished);
export const loadedSelector = selector(documents, (d) => d.loaded);
export const documentSortedLinkedSelector = selector(documentLinkedSelector, (d) =>
  d.toList().sort(sorterByFavoriteOrCreated),
);
export const unreadRequestsLoadedSelector = selector(documents, (d) => d.unreadRequestsLoaded);
export const isLinkedDocsLockedSelector = selector(documentLinkedSelector, (docs) =>
  docs.some((doc) => doc.linkid.endsWith('locked')),
);
export const documentLinkedTagSelector = selector(linkedSelector, (d) => d.tag);
export const documentSelector = selector(documents, (d) => d.document);
export const documentStatusesSelector = selector(documents, (d) => d.document.status);
export const documentLastModifiedDateSelector = selector(documents, (d) => d.document.lastModifiedDate);
export const documentDocumentIdSelector = selector(documents, (d) => d.document.documentID);
export const documentDoctypeSelector = selector(documents, (d) => d.document.doctype);
export const documentTagsSelector = selector(documents, (d) => d.document.tags);
export const documentCountSelector = selector(documents, (d) => d.count);
export const documentsListListSelector = selector(documentsByIdSelector, (d) => d.toList());
export const documentsSelector = selector(documentsListListSelector, (d) => documentsWithUniqLinked(d));
export const supplierDocumentsSelector = selector(documentsListListSelector, (d) => documentsWithUniqLinkedSupplier(d));
export const supplierStateDocCountsSelector = selector(documents, (d) => d.supplierStateDocCounts);
export const supplierStateDocCountsWithoutLinkPanelsSelector = selector(
  documents,
  (d) => d.supplierStateDocCountsWithoutLinkPanels,
);
export const supplierPageTokensSelector = selector(documents, (d) => d.supplierPageTokens);
export const uploadQueueSelector = selector(documents, (d) => d.uploadQueue);
export const currentCompanySelector = selector(documents, (d) => d.companyId);

export const erpDuplicatedSelector = selector(documents, (d) => d.erpDuplicated);
export const isOnCompanyPageSelector = selector(documents, (d) => !!d.dokkaToken);
export const unreadRequestsSelector = selector(documents, (d) => d.unreadRequests);
export const isDocumentConfidentialSelector = selector(documentSelector, (d) => d.tags.has('_S_CONFIDENTIAL'));
export const isDocumentScheduledApprovalSelector = selector(documentSelector, (d) =>
  d.tags.has(CONST.tags.isScheduledApproval),
);

export const textractEnabledForDocumentSelector = selector(
  textractEnabledSelector,
  documentSelector,
  textractForVendorEnabledSelector,
  (textractEnabled, d, textractForVendorEnabled) =>
    textractEnabled &&
    textractForVendorEnabled &&
    !d.tags.has(CONST.tags.isAccepted) &&
    !d.tags.has(CONST.tags.isBeingProcessed),
);

// if textract toggler enabled we force default view only
export const viewArrangementSelector = selector(
  documents,
  textractEnabledForDocumentSelector,
  rtlEnable,
  (d, twoWayMatchingTogglerEnabled, rtl) => (twoWayMatchingTogglerEnabled ? (rtl ? 'rtl' : 'ltr') : d.viewArrangement),
);

export const totalNumberOfDocumentsSelector = selector(documents, (d) => d.count_without_link_panels);

export const isPendingUploadSelector = selector(uploadQueueSelector, (d) => d.some((it) => it.status === 'pending'));

// grid
export const documentsGridListSelector = selector(
  documentsByIdSelector,
  chatAllUsersByIdSelector,
  approversSelector,
  (list, usersById, approvers) =>
    list.reduce(
      (a, v) => [
        ...a,
        {
          usersById,
          approvers,
          rowDocumentData: v,
          ...v.gridMeta.toJS(),
        },
      ],
      [],
    ),
);

export const documentsGridHeadersListSelector = selector(documents, (d) => d.gridHeadersList);
export const documentsGridHeadersListWithFiltersSelector = selector(documentsGridHeadersListSelector, (headers) =>
  compose(GridHeadersFactory, addSelectionMasterDetailToFirstColumn, addFilters)(headers.toJS()),
);

export const gridHasNewDocumentsSelector = selector(
  documents,
  (d) =>
    Boolean(d.fetchedDocumentsTimeStamp) &&
    Boolean(d.latestWorkerTimeStamp) &&
    d.fetchedDocumentsTimeStamp !== d.latestWorkerTimeStamp,
);

export const documentsGridLoaderSelector = selector(documents, (d) => d.gridLoader);
export const documentHotkeysSelector = selector(documents, (d) => d.hotkeys.data);
export const documentHotkeysCompanyIdSelector = selector(documents, (d) => d.hotkeys.companyId);
export const documentHotkeysIsLoadingSelector = selector(documents, (d) => d.hotkeys.isLoading);

export const cachedDocumentSelector = (docHash: string) =>
  selector(documents, (d) => d.cachedDocuments.find(({ hash }) => hash === docHash) || cachedDocumentFactory());

export const savedGridRowsCountSelector = selector(
  savedDocumentListWithFiltersSelector,
  documentsByIdSelector,
  (filteredDocIds, docs) => Math.min(filteredDocIds.size, docs.count()),
);

export const savedDocumentListDataWithFiltersSelector = selector(
  savedDocumentListWithFiltersSelector,
  documentsByIdSelector,
  (filteredDocIds, docs): List<Document> =>
    filteredDocIds
      .toList()
      .map((docId) => docs.get(docId))
      .filter(identity),
);

export const shouldRenewCompanyToken = (companyId: ?string): SelectorType<boolean> =>
  selector(tokenSelector, currentCompanySelector, (token, currentCompany) => companyId !== currentCompany);

export const shouldProcessingDocBeBlockedSelector: SelectorType<boolean> = selector(
  documentSelector,
  isDokkaSupportSelector,
  (d, isSupport) => d.tags.has(CONST.tags.isBeingProcessed) && !isSupport,
);

export const shouldRenewUsersList = (companyId: ?string): SelectorType<boolean> =>
  selector(
    (x) => x.organizationUser,
    currentCompanySelector,
    (users, currentCompanyId) => !users.size || currentCompanyId !== companyId,
  );

export const shouldRenewCategories = (companyId: ?string): SelectorType<boolean> =>
  selector(
    categoriesListSelector,
    currentCompanySelector,
    (list, currentCompanyId) => !list.size || currentCompanyId !== companyId,
  );

export const shouldRenewCompanyFeatures = (companyId: ?string): SelectorType<boolean> =>
  selector(companyFeatureSetCompanyIdSelector, (featureSetCompanyId) => featureSetCompanyId !== companyId);
export const shouldRenewConfigurationCompany = (companyId: ?string): SelectorType<boolean> =>
  selector(
    configurationCompanySelector,
    (configurationCompany) => !configurationCompany.get('id') || configurationCompany.get('id') !== companyId,
  );

export const shouldRenewCompanySignings = (companyId: ?string): SelectorType<boolean> =>
  selector(companySigningsCompanyIdSelector, (signingsCompanyId) => signingsCompanyId !== companyId);

export const shouldRenewCompanyHotkeys = (companyId: ?string): SelectorType<boolean> =>
  selector(documentHotkeysCompanyIdSelector, (hotkeysCompanyId) => hotkeysCompanyId !== companyId);

// work only if the user went to the document page via a direct link
export const shouldRenewCompanyPreferences = (companyId: ?string): SelectorType<boolean> =>
  selector(
    companiesByIdSelector,
    documentsByIdSelector,
    (companies, docs) => !companies.getIn([companyId, 'signParams', 'guid']) && !docs.size,
  );

export const shouldRenewCompanyPreferencesOnWorkspace = (companyId: ?string): SelectorType<boolean> =>
  selector(companiesByIdSelector, (companies) => !companies.getIn([companyId, 'signParams', 'guid']));

export const shouldRenewCompanyChatPears = (): SelectorType<boolean> =>
  selector(chatParticipantsSelector, (users) => !users.size);

export const shouldRenewCompanyConnections = (): SelectorType<boolean> => selector(erpsSelector, (erps) => !erps.size);
export const shouldRenewExportFormats = (): SelectorType<boolean> => selector(documents, (d) => !d.exportFormatsLoaded);
export const shouldRenewGridPresets = (): SelectorType<boolean> =>
  selector(gridRawPresetsLoadedSelector, (isLoaded) => !isLoaded);
export const shouldRenewUnreadRequests = (): SelectorType<boolean> =>
  selector(unreadRequestsLoadedSelector, (isLoaded) => !isLoaded);

export const shouldRenewMRUTags = (companyId: ?string): SelectorType<boolean> =>
  selector(recentTagsCompanyIdSelector, (recentTagsCompanyId) => recentTagsCompanyId !== companyId);

export const supplierDocumentsCountByColumn = (stateColumn: string): SelectorType<number> =>
  selector(
    documentsByIdSelector,
    (documentList: Map<string, DocumentsType>) => documentList.filter((d) => d.stateColumn === stateColumn).size,
  );

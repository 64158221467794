// @flow
import React, { useRef, useEffect, useCallback, Fragment, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// components
import Item from 'pages/company/item__legacy';

// selectors
import { tokenSelector, localeSelector } from 'domain/env/envSelector';
import {
  supplierPageTokensSelector,
  supplierStateDocCountsSelector,
  supplierStateDocCountsWithoutLinkPanelsSelector,
} from 'domain/documents/documentSelector';
import { getIndicateWarningByDocSelector } from 'domain/documents/selectors/getIndicateWarningByDocSelector';
import { chatAllUsersByIdSelector } from 'domain/chat/chatSelector';

// actions
import {
  documentHideLinkedDocs,
  documentGetLinkedAction,
  setLinkingTagAction,
  supplierDocumentSearchAction,
} from 'domain/documents/documentsActions';

// types
import { type DocumentsType, type Document, type DocumentsList } from 'domain/documents';

// helpers
import { getDocumentUrl } from 'pages/company/workSpace/helpers';
import { promisify } from 'lib/helpers';

// styles
import cx from 'classnames';
import useStyles from './sheet';

import moment from 'moment';

type Props = {
  dList: any,
  dSeq: any,
  id: string,
  title: string,
  linkingTag: string,
  getPreviewSrcDoc: (documentID: string, dokkaToken: string, preview?: boolean) => string,
  onDocumentClick: (e: SyntheticMouseEvent<HTMLElement>, d: DocumentsType, l: boolean) => void,
  onPreview: (doc: Document | null) => void,
  fakeFunction: () => void,
  setIsLinkedDocPreview: React.Dispatch<React.SetStateAction<boolean>>,
  tagsComponentHeight: number,
  setPreviewCategory: React.Dispatch<React.SetStateAction<string>>,
  setPreviewData: React.Dispatch<
    React.SetStateAction<{
      list: DocumentsList,
      count: number,
    }>,
  >,
  previewCategory: ?string,
  previewData: { list: DocumentsList, count: number },
  isLinkedDocPreview: boolean,
};

const mapStateToProps = (state) => ({
  supplierPageTokens: supplierPageTokensSelector(state),
  getIndicateWarningByDoc: getIndicateWarningByDocSelector(state),
  usersById: chatAllUsersByIdSelector(state),
  locale: localeSelector(state),
  supplierStateDocCounts: supplierStateDocCountsSelector(state),
  supplierStateDocCountsWithoutLinkPanels: supplierStateDocCountsWithoutLinkPanelsSelector(state),
});

const Category: React$StatelessFunctionalComponent<Props> = ({
  dList,
  dSeq,
  id,
  title,
  linkingTag,
  getPreviewSrcDoc,
  onDocumentClick,
  onPreview,
  fakeFunction,
  setIsLinkedDocPreview,
  tagsComponentHeight,
  setPreviewData,
  setPreviewCategory,
  previewCategory,
  previewData,
  isLinkedDocPreview,
}) => {
  const classes = useStyles({ tagsComponentHeight });
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const dokkaToken = useApiToken();

  const categoryRef = useRef(null);
  const proxyRef = useRef(null);

  const {
    supplierPageTokens,
    getIndicateWarningByDoc,
    usersById,
    locale,
    supplierStateDocCounts,
    supplierStateDocCountsWithoutLinkPanels,
  } = useSelector(mapStateToProps);

  const getTo = useCallback((documentID: string) => getDocumentUrl(companyId, documentID), [companyId]);

  const previewList = useMemo(() => (dList ? dList.flatten().filter((i) => !i.linkid) : null), [dList]);

  useEffect(() => {
    const { list, count } = previewData;
    if (
      !isLinkedDocPreview &&
      previewCategory === id &&
      previewList &&
      (list.size !== previewList.size || count !== supplierStateDocCountsWithoutLinkPanels[id])
    ) {
      setPreviewData({
        list: previewList,
        count: supplierStateDocCountsWithoutLinkPanels[id],
      });
    }
  }, [
    previewList,
    previewCategory,
    id,
    setPreviewData,
    supplierStateDocCountsWithoutLinkPanels,
    previewData,
    isLinkedDocPreview,
  ]);

  const onShowLinked = useCallback(
    async (tag, isOpen = true) => {
      if (!linkingTag) {
        dispatch(documentHideLinkedDocs());
      }
      const fn = (arg) => dispatch(documentGetLinkedAction({ ...arg }));
      await promisify(fn, { tag, isOpen });
      dispatch(setLinkingTagAction(tag));
    },
    [dispatch, linkingTag],
  );

  const getNextPage = useCallback(() => {
    if (typeof supplierPageTokens[id] === 'string' && supplierPageTokens[id].length) {
      dispatch(
        supplierDocumentSearchAction({ pageToken: supplierPageTokens[id], infiniteScroll: true, stateColumn: id }),
      ); // true indicates infinite scroll
    }
  }, [supplierPageTokens, id, dispatch]);

  const onPreviewStart = useCallback(
    (doc: Document) => {
      const { linkid } = doc;
      if (linkid) {
        onShowLinked(linkid, false);
        setIsLinkedDocPreview(true);
      }
      if (!linkid) {
        setPreviewData({
          list: previewList,
          count: supplierStateDocCountsWithoutLinkPanels[id],
        });
      }
      setPreviewCategory(id);
      onPreview(doc);
    },
    [
      onPreview,
      id,
      onShowLinked,
      setIsLinkedDocPreview,
      previewList,
      setPreviewData,
      supplierStateDocCountsWithoutLinkPanels,
      setPreviewCategory,
    ],
  );

  const getNext = useCallback(
    (entries: $ReadOnlyArray<IntersectionObserverEntry>) => {
      const { isIntersecting } = entries[0];
      if (isIntersecting) {
        getNextPage();
      }
    },
    [getNextPage],
  );

  useEffect(() => {
    const io = new IntersectionObserver(getNext, {
      root: null,
      rootMargin: '100px',
      threshold: 0.1,
    });
    if (proxyRef.current) {
      io.observe(proxyRef.current);
    }
    return () => {
      io.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierPageTokens[id]]);

  return (
    <div key={id} className={classes.categoryContainer} ref={categoryRef}>
      <h2 className={cx(classes.title, classes[id])}>
        <FormattedMessage id={`company.supplier.workspace.columnTitle.${id}`} defaultMessage={title} /> (
        {supplierStateDocCounts[id]})
      </h2>
      <div className={classes.list}>
        {dList &&
          dList.map((v, i) => (
            <Fragment key={`${id}-${dSeq.get(i)}`}>
              <h3 className={classes.period}>{moment(dSeq.get(i), 'MM-YYYY').locale(locale).format('MMMM YYYY')}</h3>
              {v.map((d) => (
                <div key={d.documentID} className={classes.documentItem}>
                  <Item
                    to={getTo(d.documentID)}
                    key={d.documentID}
                    document={d}
                    previewSrc={getPreviewSrcDoc(d.documentID, dokkaToken)}
                    getIndicateWarningByDoc={getIndicateWarningByDoc}
                    usersById={usersById}
                    onShowLinked={onShowLinked}
                    onClick={(event) => onDocumentClick(event, d)}
                    onPreview={onPreviewStart}
                    onContextMenu={fakeFunction}
                  />
                </div>
              ))}
            </Fragment>
          ))}
        <div ref={proxyRef} />
      </div>
    </div>
  );
};

export default Category;

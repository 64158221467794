// @flow
import React from 'react';

import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import type { ApproverType } from 'domain/approvals/types';

type TUserCell = {
  value: ApproverType,
};

const UserCell: React$StatelessFunctionalComponent<TUserCell> = ({ value }) => (
  <Stack direction="row" alignItems="center" gap={1}>
    <Avatar alt={value.fullName} src={value.picture} sx={{ height: 24, width: 24 }} />
    <Typography variant="body2">{value.fullName}</Typography>
  </Stack>
);

export default UserCell;

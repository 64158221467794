import { fork, all, put, cancel, call, take, takeLatest } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import { cognitoAuthInitAction } from 'domain/env';
import { persistTokenRefreshTime } from 'amplify/helpers';
// eslint-disable-next-line import/no-cycle
import { cognitoAuthHandler } from 'domain/env/sagas';
import { getUserDataComplete } from 'domain/env';
import { locationListener } from '../index.js';

// sagas
import router from './router';
import watchNavigate from './navigatorV6';
import auth from './auth';
import upload from './upload';
import chat from './chat';
import organization from './organization';
import watchMessage from './watchMessage';
import changeLanguage from './changeLanguage';
import approval from './approval';
import changeApplication from './changeApplication';

// helpers
import { navigate } from 'domain/router/redux/reduxActions';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { isMatchRoute } from 'domain/router/utils';
import { generatePath } from 'react-router-dom';
import { isValidBrowser, isMobile, isIPad, isIE } from 'lib/systemHelpers/browserHelpers';
import { storage } from 'lib/storage';

import { isRedirectFromSSO } from 'amplify/helpers';
import { cleanAuthData } from './idp';

const checkLegalPath = (path) => window.location.pathname.startsWith('/mobile') || isMatchRoute(path);

const checkIDpInitiatedLoginPath = () => isMatchRoute(ROUTES_PATH.AUTH_IDP_LOGIN.absolute);

const checkPasswordLessAuthLoginPath = () => isMatchRoute(ROUTES_PATH.AUTH_PLA_LOGIN.absolute);

const legalPaths = [
  ROUTES_PATH.REQUEST_DOCUMENT.absolute,
  ROUTES_PATH.NOT_SUPPORTED_MOBILE_BROWSER.absolute,
  ROUTES_PATH.ACCESS_LIMITED.absolute,
  ROUTES_PATH.REDIRECT.absolute,
];

const isLegalRoute = () => legalPaths.some((path) => checkLegalPath(path));

// this checks if redirect route is saved in backurl rather then present in window location
// the cas eis that we are redirected from login app to / and backurl redirection will happen
// later, however we should consider / route allowed for mobile access in case of approval
const isRedirectRoute = () => {
  const backUrl = storage().backUrl().get();
  if (!backUrl) return false;
  return isMatchRoute(ROUTES_PATH.REDIRECT.absolute, backUrl);
}

const isLegalPath = () => isLegalRoute() || isRedirectFromSSO() || isRedirectRoute();

export function* stopWithRedirect (redirectUrl: string) {
  yield put(navigate.push(redirectUrl));
  yield cancel();
}

export function* restrictBrowserAccess () {
  if (isIPad) {
    yield stopWithRedirect(generatePath(ROUTES_PATH.NOT_SUPPORTED_IPAD.absolute));
  } else if (isIE) {
    yield stopWithRedirect(generatePath(ROUTES_PATH.NOT_SUPPORTED_BROWSER.absolute));
  } else if (!isLegalPath()) {
    if (isMobile) {
      yield stopWithRedirect(generatePath(ROUTES_PATH.NOT_SUPPORTED_MOBILE_BROWSER.absolute, { action: 'restricted' }));
    } else if (!isValidBrowser) {
      yield stopWithRedirect(generatePath(ROUTES_PATH.NOT_SUPPORTED_BROWSER.absolute));
    }
  }
}

export default function* root (dispatch) {
  yield restrictBrowserAccess();
  // in case of IDp initiated login we must cleanup auth data
  // to ensure expired token wont force logout during workers start
  // also we need to remove the existing provider auth data before authorization via email link
  // because if we have an expired token in local storage, we can't login the first time
  if (checkIDpInitiatedLoginPath() || checkPasswordLessAuthLoginPath()) {
    yield call(cleanAuthData);
  }

  try {
    const userSession = yield call([Auth, 'currentAuthenticatedUser']);
    yield call(cognitoAuthHandler);
    persistTokenRefreshTime(new Date().getTime());
  } catch (error) {
    console.log('user session retrieval failed', error);
  }

  yield all([
    fork(router),
    fork(auth),
    fork(chat, dispatch),
    fork(organization),
    fork(watchNavigate, dispatch),
    fork(upload, dispatch),
    fork(watchMessage, dispatch),
    fork(changeLanguage),
    fork(approval),
    fork(changeApplication),
  ]);

  yield call(locationListener);
}

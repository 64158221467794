// @flow
import React, { useCallback, useState, useMemo } from 'react';
import { set, update } from 'lodash/fp';
import { useIntl } from 'react-intl';
import { arrayMove } from '@dnd-kit/sortable';

import useApprovals, { filterOptions, messages } from 'hooks/approval/useApprovals';

import Approver from 'pages/components/Approver';
import SortableList from 'pages/document/DocumentTopPanel/approval/dnd/SortableList';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from 'components/mui/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Box from '@mui/material/Box';
import Autocomplete from 'pages/components/ApproversAutocomplete';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Avatar from '@mui/material/Avatar';
import ListSubheader from '@mui/material/ListSubheader';

import Check from '@mui/icons-material/Check';

import { styled } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';

import { Fields } from 'pages/configurations/company/pages/approvals/pages/groups/helpers';

import elements from 'components/elements';

import type { ApprovalGroupsType } from 'domain/approvals/types';

const approvalsLimit = 8;

const useStyles = makeStyles((theme) =>
  createStyles({
    bodyTags: {
      bodyTags: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: '1 1 100%',
      },
    },
    approvalDraggableElem: {
      outline: `2px solid ${theme.palette.primary.main}`,
    },
  }),
);

const ValuesFactory = (values) => ({
  [Fields.name]: values?.[Fields.name] || '',
  [Fields.approvers]: values?.[Fields.approvers] || [],
});
const ErrorsFactory = (errors) => ({
  [Fields.name]: errors?.[Fields.name] || null,
  [Fields.approvers]: errors?.[Fields.approvers] || null,
});
const TouchedFactory = (values) => ({
  [Fields.name]: values?.[Fields.name] || false,
  [Fields.approvers]: values?.[Fields.approvers] || false,
});

const SMenuItem = styled(MenuItem)(({ theme }) => ({
  '&&.Mui-selected': {
    backgroundColor: theme.palette.common.white,
  },
}));

const MenuList = styled('ul')(() => ({ padding: 0 }));

const groupsOrder = ['clients', 'accountants'];

type TApprovalGroup = {
  open: boolean,
  handleClose: () => void,
  title: string,
  onSubmit: (values: ApprovalGroupsType) => Promise<void>,
  initialValues: ApprovalGroupsType,
};

const ApprovalGroup: React$StatelessFunctionalComponent<TApprovalGroup> = ({
  open,
  handleClose,
  title,
  onSubmit,
  initialValues,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const [values, setValues] = useState(ValuesFactory(initialValues));
  const { approvers, value: approversValue } = useApprovals(values[Fields.approvers], groupsOrder);

  const initialValidationErrors = useMemo(
    () =>
      ErrorsFactory({
        [Fields.name]: !values[Fields.name]
          ? intl.formatMessage({
              id: 'message.Group_title_can_not_be_empty',
              defaultMessage: 'Please set group name',
            })
          : null,
        [Fields.approvers]: values[Fields.approvers]?.length < 1 ? 'should have at least one approval!' : null,
      }),
    [intl, values],
  );
  const [errors, setErrors] = useState(initialValidationErrors);
  const [touched, setTouched] = useState(TouchedFactory());
  const [isSubmitting, setISSubmitting] = useState(false);

  const hasErrors = Object.values(errors).some(Boolean);
  const enableSubmit = !hasErrors && !isSubmitting;

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setValues((s) => set([name], value)(s));
      setErrors((s) =>
        set(
          [Fields.name],
          value
            ? null
            : intl.formatMessage({
                id: 'message.Group_title_can_not_be_empty',
                defaultMessage: 'Please set group name',
              }),
        )(s),
      );
    },
    [intl],
  );

  const handleAutocompleteChange = useCallback(
    (name) => (e, value) => {
      setValues((s) => set([name], value)(s));
      setErrors((s) => set([name], value.length ? null : 'should have at least one approval!')(s));
    },
    [],
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        setISSubmitting(true);
        await onSubmit(values);
        setTouched(TouchedFactory());
      } finally {
        setISSubmitting(false);
      }
    },
    [onSubmit, values],
  );

  const onBlur = useCallback((e) => {
    if (e.target.name) {
      setTouched((s) => set([e.target.name], true)(s));
    }
  }, []);

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setValues((s) => update([Fields.approvers], (a) => arrayMove(a, oldIndex, newIndex))(s));
  }, []);

  const handleDelete = useCallback(
    (id) => {
      handleAutocompleteChange([Fields.approvers])(
        undefined,
        values[Fields.approvers].filter((i) => i.id !== id),
      );
    },
    [handleAutocompleteChange, values],
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title={title}
      withActions={false}
      withContent={false}
      fullWidth
      maxWidth="lg"
    >
      <form onSubmit={handleSubmit}>
        <DialogContent sx={{ paddingTop: 1 }}>
          <FormControl fullWidth error={Boolean(errors[Fields.name] && touched[Fields.name])} sx={{ mb: 2 }}>
            <TextField
              autoFocus
              multiline
              disabled={isSubmitting}
              error={Boolean(errors[Fields.name] && touched[Fields.name])}
              value={values[Fields.name]}
              name={Fields.name}
              onChange={handleChange}
              onBlur={onBlur}
              label={intl.formatMessage({
                id: 'configurations.company.approvals.groups.placeholder',
                defaultMessage: 'Group Name',
              })}
              type="text"
              fullWidth
              data-element={elements.popup.approvalGroups.groupName}
            />
            {!!errors[Fields.name] && touched[Fields.name] && (
              <FormHelperText
                data-element={elements.popup.approvalGroups.groupNameValidationError}
                error={Boolean(errors[Fields.name])}
              >
                {errors[Fields.name]}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              margin="normal"
              disabled={isSubmitting}
              data-element={elements.popup.approvalGroups.selectApprovers}
              noOptionsText={
                approversValue.length < approvalsLimit
                  ? intl.formatMessage({
                      id: 'document.approval.noSuggestions',
                      defaultMessage: 'No company users available',
                    })
                  : intl.formatMessage(
                      {
                        id: 'document.approval.limitReached',
                        defaultMessage: `${approversValue.length} users limit exceeded`,
                      },
                      { limit: approversValue.length },
                    )
              }
              options={approversValue.length < approvalsLimit ? approvers : []}
              groupBy={(option) => option.group}
              value={approversValue}
              onChange={handleAutocompleteChange(Fields.approvers)}
              onBlur={onBlur}
              getOptionLabel={(o) => o.id}
              filterOptions={filterOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={Fields.approvers}
                  label={intl.formatMessage({
                    id: 'configurations.company.approvals.groups.select.placeholder',
                    defaultMessage: 'Select approvers...',
                  })}
                />
              )}
              renderOption={(params, option, state) => (
                <SMenuItem {...params} selected={state.selected} data-element={elements.popup.approvalGroups.option}>
                  <ListItemText sx={{ mr: 1 }}>
                    {option.fullName}
                    <br />({option.tag})
                  </ListItemText>
                  {state.selected && (
                    <ListItemIcon>
                      <Check sx={{ color: 'success.main' }} />
                    </ListItemIcon>
                  )}
                </SMenuItem>
              )}
              renderGroup={(params) => (
                <Box key={params.key} data-element={`${elements.popup.approvalGroups.listOf}.${params.group}`}>
                  <ListSubheader sx={{ top: '-8px' }}>
                    {intl.formatMessage(messages[params.group], { count: params.children.length })}
                  </ListSubheader>
                  <MenuList>{params.children}</MenuList>
                </Box>
              )}
              renderTags={(selected) => (
                <SortableList
                  items={selected}
                  className={classes.bodyTags}
                  idKey="id"
                  onSortOver={() => {}}
                  onSortEnd={onSortEnd}
                  Element={(props) => (
                    <Stack
                      gap={1}
                      flexWrap="wrap"
                      direction="row"
                      {...props}
                      data-element={elements.popup.approvalGroups.selectedApproversContainer}
                    >
                      {props.children}
                    </Stack>
                  )}
                  helperClass={classes.approvalDraggableElem}
                >
                  {({ fullName, picture, id, className, style, idx, dragging }) => (
                    <Approver
                      avatar={<Avatar alt={fullName} src={picture} />}
                      label={fullName}
                      variant="outlined"
                      onDelete={() => {
                        handleDelete(id);
                      }}
                      className={className}
                      style={style}
                      hasArrow={idx + 1 !== selected.length && !dragging}
                    />
                  )}
                </SortableList>
              )}
            />
            <FormHelperText data-element={elements.popup.approvalGroups.selectApproversDescription}>
              {intl.formatMessage({
                id: 'configurations.company.approvals.groups.modal.field.approver.description',
                defaultMessage: 'The order of selecting the approvers will define the order of the approval flow',
              })}
            </FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleClose} data-element={elements.popup.approvalGroups.cancel}>
            {intl.formatMessage({ id: 'button.cancel', defaultMessage: 'Cancel' })}
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            data-element={elements.popup.approvalGroups.save}
            loading={isSubmitting}
            disabled={!enableSubmit}
          >
            {intl.formatMessage({ id: 'button.save', defaultMessage: 'Save' })}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ApprovalGroup;

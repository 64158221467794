// @flow
import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { GRID_SERVICE_KEYS } from 'domain/documents/helpers';
// icons
import { ReactComponent as SortIcon } from './icons/sort-default.svg';
import { ReactComponent as SortAcsIcon } from './icons/sort-asc.svg';
import { ReactComponent as SortDescIcon } from './icons/sort-desc.svg';
// types
import type { TGridApi, Column, TSelectPosition } from '../../types.js.flow';

import cx from 'classnames';
import useStyles from './sheet';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';

type Props = {
  classes: {|
    [key: string]: string,
  |},
  api: TGridApi,
  column: Column,
  showColumnMenu: (menuButton: HTMLElement) => void,
  setSort: (sort: string, multiSort?: boolean) => void,
  enableMenu: boolean,
  enableSorting: boolean,
  displayName: string,
  handleSavedColumnEvent?: () => void,
};

const SORT_TYPES = {
  asc: 'asc',
  desc: 'desc',
  no: '',
};

const prepareColumnData = (rawData) => {
  const { columns, startRow, endRow } = rawData;
  return {
    columns,
    rowStartPinned: startRow.rowPinned,
    rowEndPinned: endRow.rowPinned,
    rowStartIndex: startRow.rowIndex,
    rowEndIndex: endRow.rowIndex,
  };
};

const columnSelect = (api, column) => {
  const getRowEndIndex = () => api.getDisplayedRowCount() - 1;

  const isSelectedColumn = (el: TSelectPosition) => {
    const { startRow, endRow, columns } = el;
    return startRow.rowIndex === 0 && endRow.rowIndex === getRowEndIndex() && columns[0]?.colId === column.colId;
  };

  const selectColumn = (isModifierPressed) => {
    if (!isModifierPressed) {
      api.clearRangeSelection();
    }
    api.addCellRange({
      columnStart: column.colId,
      columnEnd: column.colId,
      rowStartIndex: 0,
      rowEndIndex: getRowEndIndex(),
    });
  };

  const deselectColumn = () => {
    const filteredRanges = api.getCellRanges().filter((el) => !isSelectedColumn(el));
    api.clearRangeSelection();
    filteredRanges.forEach((el) => {
      api.addCellRange(prepareColumnData(el));
    });
  };

  const onClickColumn = (e) => {
    const isModifierPressed = e.ctrlKey || e.metaKey;
    const isSelected = api.getCellRanges().some((el) => isSelectedColumn(el));
    isSelected ? deselectColumn() : selectColumn(isModifierPressed);
  };
  return onClickColumn;
};

const AgGridColumnHeader = ({
  api,
  column,
  showColumnMenu,
  setSort,
  enableMenu,
  enableSorting,
  displayName,
  handleSavedColumnEvent,
}: Props): React$Node => {
  const classes = useStyles({ displayName });
  const [sortType, setSortType] = useState(SORT_TYPES.no);
  const [isFilterActive, setIsFilterActive] = useState(column.isFilterActive());
  const refButton = useRef(null);
  const refPinButton = useRef(null);
  const refFilterButton = useRef(null);

  const onClickColumn = columnSelect(api, column);

  const handlePinButtonClick = useCallback(
    (event) => {
      event.stopPropagation();
      column.setColDef({ ...column.colDef, menuTabs: ['generalMenuTab'] }, column.userProvidedColDef);
      showColumnMenu(refPinButton.current);
    },
    [showColumnMenu, column],
  );

  const handleFilterButtonClick = useCallback(
    (event) => {
      event.stopPropagation();
      column.setColDef({ ...column.colDef, menuTabs: ['filterMenuTab'] }, column.userProvidedColDef);
      showColumnMenu(refFilterButton.current);
    },
    [showColumnMenu, column],
  );

  const onMenuClicked = useCallback(() => {
    column.setColDef({ ...column.colDef, menuTabs: ['columnsMenuTab', 'generalMenuTab'] }, column.userProvidedColDef);
    showColumnMenu(refButton.current);
  }, [showColumnMenu]);

  const onSortChanged = () => {
    const isSortAscending = column.isSortAscending();
    const isSortDescending = column.isSortDescending();

    const initialSort = (isSortAscending && SORT_TYPES.asc) || (isSortDescending && SORT_TYPES.desc) || SORT_TYPES.no;
    setSortType(initialSort);
  };

  const onSortRequested = useCallback(
    (order, event) => {
      setSort(order, event.shiftKey);
    },
    [setSort],
  );

  const handleMenuClicked = useCallback(
    (event) => {
      event.stopPropagation();
      onMenuClicked();
    },
    [onMenuClicked],
  );

  const handleSort = useCallback(
    (event) => {
      event.stopPropagation();
      switch (sortType) {
        case SORT_TYPES.no:
          onSortRequested(SORT_TYPES.asc, event);
          break;
        case SORT_TYPES.asc:
          onSortRequested(SORT_TYPES.desc, event);
          break;
        case SORT_TYPES.desc:
          onSortRequested(SORT_TYPES.no, event);
          break;
        default:
          break;
      }
    },
    [onSortRequested, sortType],
  );

  useEffect(() => {
    column.addEventListener('sortChanged', onSortChanged);
    onSortChanged();
    return () => {
      column.removeEventListener('sortChanged', onSortChanged);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilterChanged = () => {
    setIsFilterActive(column.isFilterActive());
  };

  useEffect(() => {
    column.addEventListener('filterChanged', onFilterChanged);
    onFilterChanged();
    return () => {
      column.removeEventListener('filterChanged', onFilterChanged);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * allow filter to be applied
   * when 'Enter' pressed
   *
   * by default: if 'Apply' button is present
   * only click on 'Apply' button applies the filter
   */
  useEffect(() => {
    let cond1;
    let cond2;
    let handleFilterTextInput;
    const getFilterInstance = (event) => {
      if (!api.destroyCalled) {
        const filterInstance = api.getFilterInstance(column.colId);
        const { eCondition1Body, eCondition2Body } = event.filterInstance;

        handleFilterTextInput = (event) => {
          const { key } = event;

          if (key === 'Enter') {
            event.stopPropagation();
            filterInstance.applyModel();
            api.onFilterChanged();
            if (filterInstance.hidePopup && typeof filterInstance.hidePopup === 'function') {
              filterInstance.hidePopup();
            }
          }
        };

        if (eCondition1Body) {
          cond1 = eCondition1Body;
          cond1.addEventListener('keydown', handleFilterTextInput, { once: true });
        }

        if (eCondition2Body) {
          cond2 = eCondition2Body;
          cond2.addEventListener('keydown', handleFilterTextInput, { once: true });
        }
      }
    };

    api.addEventListener('filterModified', getFilterInstance);

    return () => {
      api.removeEventListener('filterModified', getFilterInstance);
      if (cond1) cond1.removeEventListener('keydown', handleFilterTextInput);
      if (cond2) cond2.removeEventListener('keydown', handleFilterTextInput);
    };
  }, [column, api]);

  useEffect(() => {
    const menuVisibleChangedHandler = (event) => {
      if (!event.column.menuVisible) {
        if (typeof handleSavedColumnEvent === 'function') handleSavedColumnEvent(event);
      }
    };

    column.addEventListener('menuVisibleChanged', menuVisibleChangedHandler);

    return () => column.removeEventListener('menuVisibleChanged', menuVisibleChangedHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sort = useMemo(() => {
    if (!enableSorting) return null;
    return (
      <button className={classes.sortOptions} onClick={handleSort} onTouchEnd={handleSort} type="button">
        {{
          [SORT_TYPES.asc]: <SortAcsIcon className={classes.sortIcon} />,
          [SORT_TYPES.desc]: <SortDescIcon className={classes.sortIcon} />,
        }[sortType] || <SortIcon className={classes.sortIcon} />}
      </button>
    );
  }, [enableSorting, sortType, classes, handleSort]);

  const menu = useMemo(() => {
    if (!enableMenu) return null;
    return (
      <IconButton ref={refButton} className={classes.menuButton} color="primary" onClick={handleMenuClicked}>
        <AddCircleIcon fontSize="24" />
      </IconButton>
    );
  }, [enableMenu, classes, handleMenuClicked]);

  const { field } = column.userProvidedColDef;
  const {
    PREVIEW_CHECKBOX_COLUMN_NAME,
    PREVIEW_BTN_COLUMN_NAME,
    COLUMNS_VISIBILITY_MENU,
    COLUMNS_MASTER_VIEW,
    COLUMNS_LINKED_ICON,
  } = GRID_SERVICE_KEYS;
  const gridServicePreviewValues = [
    PREVIEW_CHECKBOX_COLUMN_NAME,
    PREVIEW_BTN_COLUMN_NAME,
    COLUMNS_MASTER_VIEW,
    COLUMNS_LINKED_ICON,
  ];
  if (gridServicePreviewValues.includes(field)) return null;

  return (
    <div className="ag-cell-label-container" role="presentation" onClick={onClickColumn}>
      <div className={classes.sortAndFilter}>
        {column.colId !== COLUMNS_VISIBILITY_MENU ? (
          <>
            <span
              ref={refPinButton}
              onClick={handlePinButtonClick}
              className={cx('ag-icon', 'ag-icon-pin', classes.headerButton, { [classes.activeButton]: column.pinned })}
            />

            <span
              ref={refFilterButton}
              onClick={handleFilterButtonClick}
              className={cx('ag-icon', 'ag-icon-filter', classes.headerButton, {
                [classes.activeButton]: isFilterActive,
              })}
            />
          </>
        ) : (
          menu
        )}

        {sort}
      </div>
      <div className="ag-header-cell-label">
        <span className="ag-header-cell-text" role="columnheader" style={{ whiteSpace: 'nowrap' }}>
          {displayName}
        </span>
      </div>
    </div>
  );
};

AgGridColumnHeader.displayName = 'ColumnHeader';

export default AgGridColumnHeader;

// @flow
import React, { useEffect, useRef, forwardRef, useCallback, useMemo, useState, useContext } from 'react';
import { Set, Map, OrderedSet } from 'immutable';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import { debounce } from 'throttle-debounce';
import { compose, type Dispatch } from 'redux';
import { connect, useSelector } from 'react-redux';
import { rtlEnable } from 'domain/env/envSelector';
import {
  documentsGridListSelector,
  documentsGridHeadersListWithFiltersSelector,
  documentsGridLoaderSelector,
  documentsGridFilterAppliedAction,
  documentsGridSortingAppliedAction,
  documentsGridColumnsAppliedAction,
  gridCurrentFiltersSelector,
  gridCurrentSortSelector,
  gridCurrentColumnsStateSelector,
  documentsGridDefaultColumnsAppliedAction,
  documentsGridSetListIdWithFiltersAction,
  documentsByIdSelector,
  savedDocumentListDataWithFiltersSelector,
} from 'domain/documents';
import { shouldHighlightAmountSelector } from 'domain/companies';
import {
  addContextMenuColumn,
  addColumnsVisibilityMenuColumn,
  addPreviewColumn,
  addCustomColumns,
  changeWidthIfMasterViewNotAvailable,
  setExelModeFilter,
  addColDefaultParams,
  separateFilters,
  FiltersAdapter,
  mixSavedGridColumnsStateAdapter,
  addLinkedButtonColumn,
} from 'pages/company/grid/helpers';
import * as ACL from 'domain/restriction';
import { compareFiltersChange, orderedColumns, GRID_SERVICE_KEYS } from 'domain/documents/helpers';

// components
import { AgGridReact } from 'ag-grid-react';
import Box from '@mui/material/Box';
import DocumentPreviewNavigation from 'pages/company/DocumentPreviewNavigation/DocumentPreviewNavigation';
import CircularProgressWithBackdrop from 'components/mui/CircularProgressWithBackdrop';
import RowDetailsJE from './components/rowDetailsJE';
import WithDocumentViewButton from './components/withDocumentViewButton';
import ContextButton from './components/contextButton';
import AgGridDateFilter from './components/DateFilter';
import FilterSetRender from './components/FilterSetRender';
import AgGridApprovals from './components/Approvals';
import AgGridTags from './components/Tags';
import AgGridCustomTooltip from './components/CustomTooltip';
import AgGridStatusCell from './components/StatusCell';
import AgGridColumnHeader from './components/ColumnHeader';
import CustomSum from './components/CustomSum';
import Amount from './components/Amount';
import LinkedButton from './components/LinkedButton';

import CompanyContext from 'pages/company/CompanyContext';

import { useGridRef } from './customHooks';
import { usePrevious } from './usePrevious';
import useScrollManager from 'lib/scrollManager';
import useAgGridDefaultConfig from 'hooks/agGrid/useAgGridDefaultConfig';
import useAgGridManualConfig from 'hooks/agGrid/useAgGridManualConfig';
import useAgGridMethods from 'hooks/agGrid/useAgGridMethods';
import useAgGridExcelStyles from 'hooks/agGrid/useAgGridExcelStyles';
import { IS_OPEN_CONTEXT_MENU_CLASS } from 'hooks/agGrid/agGridThemeClasses';
import useWorkspaceGridStyles from './useWorkspaceGridStyles';
import { useRangeSelectionChanged } from './useRangeSelectionChanged';

import type { DocumentsType, Document } from 'domain/documents/documentsModel';
import type { TGridData, TColumnApi, TGridApi, GridRef } from './types.js.flow';
import type { PreviewStateT } from '../type.js.flow';

const ENABLED_UI_CHANGES = ['uiColumnDragged', 'contextMenu', 'toolPanelUi', 'columnMenu', 'autosizeColumns'];

export const PREVIEW_CONTEXT_NAME = 'gridList';

export interface IGridProps {
  selected: OrderedSet<string>;
  onChangeSelected: (newSelected: Set<string>) => void;
  onContextMenu: (e: SyntheticMouseEvent<HTMLElement>, d?: DocumentsType, l?: string) => void;
  onDocumentOpen: (documentID: string, isLinked?: ?boolean) => void;
  getDocumentUrl: (documentID: string) => string;
  documentsGridSetListIdWithFilters: Dispatch<typeof documentsGridSetListIdWithFiltersAction>;
  documentsGridFilterApplied: Dispatch<typeof documentsGridFilterAppliedAction>;
  documentsGridSortingApplied: Dispatch<typeof documentsGridSortingAppliedAction>;
  documentsGridColumnsApplied: Dispatch<typeof documentsGridColumnsAppliedAction>;
  documentsGridDefaultColumnsApplied: Dispatch<typeof documentsGridDefaultColumnsAppliedAction>;
  isContextMenuOpen?: string;
  onPreview: (p: PreviewStateT) => void;
  preview: PreviewStateT;
  onShowLinked: (tag: string) => Promise<*>;
  resetIsLinkedPanelSelected: () => void;
  isLinkedPanelSelected: string;
}

type TFrameworComponentProps = {
  ...TGridData,
  documentsById: Map<string, DocumentsType>,
};

const mapStateToProps = (state) => ({
  gridDataSource: documentsGridListSelector(state),
  savedDocumentListDataWithFilters: savedDocumentListDataWithFiltersSelector(state),
  documentsById: documentsByIdSelector(state),
  headers: documentsGridHeadersListWithFiltersSelector(state),
  isRtl: rtlEnable(state),
  isGranted: ACL.isGranted(state),
  loadingGridData: documentsGridLoaderSelector(state),
  gridFilters: gridCurrentFiltersSelector(state),
  gridSorting: gridCurrentSortSelector(state),
  gridColumnsState: gridCurrentColumnsStateSelector(state),
  shouldHighlightAmount: shouldHighlightAmountSelector(state),
});

const Grid = forwardRef(
  (
    {
      onContextMenu,
      onChangeSelected,
      onDocumentOpen,
      getDocumentUrl,
      selected,
      documentsGridSetListIdWithFilters,
      documentsGridFilterApplied,
      documentsGridSortingApplied,
      documentsGridColumnsApplied,
      documentsGridDefaultColumnsApplied,
      isContextMenuOpen = null,
      onPreview,
      preview,
      onShowLinked,
      resetIsLinkedPanelSelected,
      isLinkedPanelSelected,
    }: IGridProps,
    ref: GridRef,
  ) => {
    const {
      gridDataSource,
      savedDocumentListDataWithFilters,
      documentsById,
      headers,
      isRtl,
      isGranted,
      loadingGridData,
      gridFilters,
      gridSorting,
      gridColumnsState,
      shouldHighlightAmount,
    } = useSelector(mapStateToProps);

    const agGridDefaultProps = useAgGridDefaultConfig(useWorkspaceGridStyles);
    const { enableRtl, rowClassRules, getLocaleText } = useAgGridManualConfig();
    const { invertedPinnedIfRTL } = useAgGridMethods();
    const excelStyles = useAgGridExcelStyles();
    const gridApi: {| current: null | TGridApi |} = useRef();
    const columnApi: {| current: null | TColumnApi |} = useRef();
    const defaultColsHashRef: {| current: null | string |} = useRef(null);
    const skipSystemFilterApplierRef: {| current: boolean |} = useRef(0);
    const prevFilterModel: {| current: boolean |} = useRef(null);
    const prevSortModel: {| current: Array<any> |} = useRef([]);
    const [isGridInit, setGridInit] = useState(false);
    const [, updateState] = useState();
    const { gridApi: gridApiContext } = useContext(CompanyContext);
    const contextMenuCellRef = useRef(null);
    const { onRangeSelectionChanged } = useRangeSelectionChanged(gridApi);

    const forceUpdate = useCallback(() => updateState({}), []);

    const renderDetail = useCallback((props: TGridData) => <RowDetailsJE documentID={props.data.documentID} />, []);

    const setFilterSet = debounce(100, () => {
      if (isGridInit && gridFilters && gridApi.current) {
        const filterModel = gridApi.current.getFilterModel();
        if (JSON.stringify(filterModel) !== JSON.stringify(gridFilters)) {
          skipSystemFilterApplierRef.current++;
          gridApi.current.setFilterModel(gridFilters);
        }
      }
    });

    // set filter model if filters changed.
    // I tyied to find way to set it as props but couldn't do it.
    useEffect(() => {
      setFilterSet();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridApi.current, gridFilters, isGridInit, gridDataSource]);

    const setRowIdsList = useCallback(() => {
      if (gridApi.current) {
        setTimeout(() => {
          if (gridApi.current.rowModel) {
            const filteredRows = gridApi.current.rowModel.rowsToDisplay.map((node) => node.data.documentID);
            documentsGridSetListIdWithFilters(OrderedSet(filteredRows));
          }
        }, 500);
      }
    }, [documentsGridSetListIdWithFilters]);

    useEffect(() => {
      setRowIdsList();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridDataSource]);

    const saveColumnsProps = () => {
      if (columnApi.current) {
        const columnsState = columnApi.current.getColumnState();
        if (columnsState) {
          const columns = invertedPinnedIfRTL(columnsState);
          documentsGridColumnsApplied(columns);
        }
      }
    };

    const onDragStopped = () => {
      saveColumnsProps();
    };

    const handleColumnMoved = (event) => {
      const allCols = event.columnApi.getAllGridColumns();
      const serviceColIndex = allCols.findIndex((col) => col.colId === GRID_SERVICE_KEYS.COLUMNS_VISIBILITY_MENU);

      if (serviceColIndex !== allCols.length - 1) {
        event.columnApi.moveColumn(GRID_SERVICE_KEYS.COLUMNS_VISIBILITY_MENU, allCols.length - 1);
      }
    };

    const onSelelectionChanged = useCallback(
      (e: {| api: {| getSelectedNodes: any |}, source: string |}) => {
        const newSelected: Set<string> = e.api.getSelectedNodes().map((node) => node.data.documentID);
        const selectedDocument = Set(newSelected);

        if (!isLinkedPanelSelected || e.source !== 'apiSelectAll') {
          resetIsLinkedPanelSelected();
          onChangeSelected(selectedDocument);
        }
      },
      [onChangeSelected, resetIsLinkedPanelSelected, isLinkedPanelSelected],
    );

    const onRowGroupOpened = useCallback((e: { [key: string]: any, expanded: boolean }) => {
      if (e.expanded && gridApi.current) {
        gridApi.current.forEachNode((node) => {
          if (node.expanded && e.data.documentID !== node.data.documentID) {
            node.setExpanded(false);
          }
        });
      }
    }, []);

    const handleSavedColumnEvent = debounce(300, (e) => {
      if (ENABLED_UI_CHANGES.includes(e.source)) {
        saveColumnsProps();
      }
    });

    const filterSets = useMemo(() => {
      const filters = Object.entries(separateFilters(gridFilters, true));
      if (filters.length === 0) return null;
      return filters.reduce((res, [key, filter]) => {
        const gridDataValues = gridDataSource.map((item) => get(item, key) || null);
        const values = [...new Set([...FiltersAdapter(gridDataValues), ...FiltersAdapter(filter.values)])];
        return { ...res, [key]: { ...filter, values } };
      }, {});
    }, [gridFilters, gridDataSource]);

    const getChangedFilterFieldName = (nextFilterModel) => {
      const diff = compareFiltersChange(prevFilterModel.current || {}, nextFilterModel);
      const [first = null] = Object.keys(diff);
      return first;
    };

    const handleFilterModel = (prevFilters, nextFilters) => {
      const changeFieldName = getChangedFilterFieldName(nextFilters);
      if (!changeFieldName) {
        return null;
      }

      const change = changeFieldName ? { [changeFieldName]: nextFilters[changeFieldName] } : {};
      const entries = { ...prevFilters, ...change };
      return Object.entries(entries)
        .filter(([, filter]) => filter)
        .reduce((res, [key, filter]) => ({ ...res, [key]: filter }), {});
    };

    const onFilterChanged = (params: {| api: TGridApi |}) => {
      const filterModel = params.api.getFilterModel();

      // $FlowFixMe
      if (skipSystemFilterApplierRef.current) {
        skipSystemFilterApplierRef.current--;
      } else {
        const filters = handleFilterModel(gridFilters, filterModel);
        if (filters) {
          documentsGridFilterApplied({
            filters,
          });
        }
      }
      setRowIdsList();
      prevFilterModel.current = filterModel;
    };

    const onSortChanged = useCallback(
      (params: {| columnApi: TColumnApi |}) => {
        const sortModel = params.columnApi
          .getColumnState()
          .filter(({ sort }) => sort)
          .map(({ colId, sort }) => ({ colId, sort }));

        if (JSON.stringify(sortModel) !== JSON.stringify(prevSortModel.current)) {
          // $FlowFixMe
          documentsGridSortingApplied({
            sorting: sortModel.length ? sortModel : [],
          });
          setRowIdsList();
        }
        prevSortModel.current = sortModel;
      },
      [documentsGridSortingApplied, setRowIdsList],
    );

    const hasMasterDetail = isGranted(ACL.IS_BOOKKEEPER_IN_FINANCIAL_COMPANY);

    // TODO: dont know why its needed
    const headersComporator = headers
      .map((c) => c.field)
      .sort((a, b) => a - b)
      .join();

    const gridSortingMap = useMemo(() => keyBy(gridSorting, 'colId'), [gridSorting]);

    const mixSavedGridColumnsState = useCallback(
      (columns) => {
        const orderedColumnConfig = gridColumnsState || columns || [];
        return mixSavedGridColumnsStateAdapter(columns, orderedColumnConfig);
      },
      [gridColumnsState],
    );

    const mixSavedGridSorting = useCallback(
      (columns) =>
        columns.map((col) => {
          const colSort = gridSortingMap[col.colId] || { sort: null };
          return { ...col, ...colSort };
        }),
      [gridSortingMap],
    );

    const mixFilterSetValues = useCallback(
      (columns) => {
        if (!filterSets) return columns;
        return columns.map((col) => {
          const filterSet = get(filterSets, col.field);
          if (!filterSet) return col;
          return {
            ...col,
            filterParams: {
              ...col.filterParams,
              values: filterSet.values,
            },
          };
        });
      },
      [filterSets],
    );

    const defaultColumnDefs = useMemo(() => {
      const columnsWithSelect = compose(
        setExelModeFilter('windows'),
        addCustomColumns(shouldHighlightAmount),
        addColumnsVisibilityMenuColumn,
        addLinkedButtonColumn,
        addPreviewColumn,
        addContextMenuColumn,
        addColDefaultParams,
      )(headers);

      return changeWidthIfMasterViewNotAvailable(columnsWithSelect, hasMasterDetail);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headersComporator, isRtl, hasMasterDetail, shouldHighlightAmount]);

    const columnDefs = useMemo(
      () =>
        compose(
          // must be applied as latest function in chain
          invertedPinnedIfRTL,
          orderedColumns,
          mixFilterSetValues,
          mixSavedGridSorting,
          mixSavedGridColumnsState,
        )(defaultColumnDefs),
      [defaultColumnDefs, mixSavedGridColumnsState, mixSavedGridSorting, mixFilterSetValues, invertedPinnedIfRTL],
    );

    const suppressSelectAllShortcut = useCallback((params) => {
      const KEY_A = 'A';
      const { event } = params;
      const { key } = event;

      const keysToSuppress = [];
      if (event.ctrlKey || event.metaKey) {
        keysToSuppress.push(KEY_A);
      }
      const suppress = keysToSuppress.some(
        (suppressedKey) => suppressedKey === key || key.toUpperCase() === suppressedKey,
      );
      return suppress;
    }, []);

    const defaultConfig = useMemo(
      () =>
        defaultColumnDefs.map(({ field, width = 0, maxWidth, minWidth, pinned, hide = false }) => {
          const currentWidth = Math.max(minWidth || 1, Math.min(maxWidth || 1000, width));
          return {
            suppressKeyboardEvent: suppressSelectAllShortcut,
            aggFunc: null,
            colId: field,
            flex: null,
            hide,
            pinned: pinned || null,
            pivot: false,
            pivotIndex: null,
            rowGroup: false,
            rowGroupIndex: null,
            sort: null,
            sortIndex: null,
            width: currentWidth,
          };
        }),
      [defaultColumnDefs, suppressSelectAllShortcut],
    );

    useEffect(() => {
      const currentHash = defaultConfig.map((d) => d.colId).join(',');
      if (columnApi.current && isGridInit) {
        if (defaultColsHashRef.current !== currentHash) {
          defaultColsHashRef.current = currentHash;
          documentsGridDefaultColumnsApplied(defaultConfig);
        }
      }
    }, [defaultConfig, isGridInit, documentsGridDefaultColumnsApplied]);

    const setPreview = useCallback(
      (doc: Document | null) => {
        const [documentId, contextName] = doc ? [doc.documentID, PREVIEW_CONTEXT_NAME] : [null, ''];
        onPreview({ documentId, contextName });
      },
      [onPreview],
    );

    const onClickOpen = useCallback(
      (_, doc: Document) => {
        onDocumentOpen(doc.documentID);
      },
      [onDocumentOpen],
    );

    const handleContextMenu = useCallback(
      (e, rowDocumentData) => {
        onContextMenu(e, rowDocumentData);
        // context menu icon stay visible when menu is open
        const element = e.currentTarget.closest(`[col-id="${GRID_SERVICE_KEYS.CONTEXT_MENU_COLUMN_NAME}"]`);

        element.classList.add(IS_OPEN_CONTEXT_MENU_CLASS);
        contextMenuCellRef.current = element;
      },
      [onContextMenu],
    );

    useEffect(() => {
      if (!isContextMenuOpen && contextMenuCellRef.current) {
        contextMenuCellRef.current.classList.remove(IS_OPEN_CONTEXT_MENU_CLASS);
        contextMenuCellRef.current = null;
      }
    }, [isContextMenuOpen]);

    const frameworkComponents = {
      customSum: CustomSum,
      myDetailCellRenderer: renderDetail,
      preview: ({ data: { rowDocumentData }, ...rest }: TFrameworComponentProps) => (
        <WithDocumentViewButton
          {...rest}
          onClick={() => {
            setPreview(rowDocumentData);

            // it is way hard row resetting allows us to avoid grid's scrolling during navigation preview by arrow keys
            rest.api.redrawRows({ rowNodes: [rest.node] });
          }}
          to={getDocumentUrl(rowDocumentData.documentID)}
          onDocumentOpen={() => onDocumentOpen(rowDocumentData.documentID)}
        />
      ),
      linkedButton: ({ data: { rowDocumentData } }: TFrameworComponentProps) => (
        <LinkedButton linkid={rowDocumentData.linkid} onClick={onShowLinked} />
      ),
      approval: ({ valueFormatted, data: { approvers } }: TFrameworComponentProps) => (
        <AgGridApprovals nodes={valueFormatted} approvers={approvers} />
      ),
      amount: ({ value, valueFormatted }) => <Amount value={value} valueFormatted={valueFormatted} />,
      tag: ({ valueFormatted }: TFrameworComponentProps) => <AgGridTags tags={valueFormatted} />,
      statusCell: AgGridStatusCell,
      agDateInput: AgGridDateFilter,
      filterSetRender: FilterSetRender,
      agColumnHeader: (props) => <AgGridColumnHeader {...props} handleSavedColumnEvent={handleSavedColumnEvent} />,
      loadingOverlay: () => <CircularProgressWithBackdrop isOpen BackdropProps={{ sx: { position: 'absolute' } }} />,
      customTooltip: AgGridCustomTooltip,
      context: ({ data: { rowDocumentData }, ...rest }: TFrameworComponentProps) => (
        <ContextButton {...rest} onClick={(e) => handleContextMenu(e, rowDocumentData)} />
      ),
    };

    const previousSelectedSize = usePrevious(selected.size);

    const getRowsWithFilter = useCallback(() => {
      const rowIdsList = [];
      if (gridApi.current) {
        gridApi.current.forEachNodeAfterFilterAndSort((node) => rowIdsList.push(node.data.documentID));
      }
      return rowIdsList;
    }, []);

    useEffect(() => {
      if (gridApi.current && documentsById.size) {
        // reset selectted rows when we have empty array list
        if ((selected.size === 0 && previousSelectedSize) || isLinkedPanelSelected) {
          gridApi.current.deselectAll();
        } else if (selected.size === getRowsWithFilter().length && previousSelectedSize !== selected.size) {
          gridApi.current.selectAllFiltered();
        }
      }
    }, [selected.size, previousSelectedSize, documentsById.size, getRowsWithFilter, isLinkedPanelSelected]);

    useGridRef(ref, columnApi, gridApi, forceUpdate);
    useScrollManager({ view: 'grid', isTargetLoaded: !(loadingGridData || !gridApi.current) });
    // initialize grid when got it data
    if (loadingGridData && !gridApi.current) return null;

    const onGridReady = async (params: {| api: TGridApi, columnApi: TColumnApi |}) => {
      gridApi.current = params.api;
      gridApiContext.current = params.api;
      columnApi.current = params.columnApi;
      setRowIdsList();
      setGridInit(true);
    };

    const isPreviewOpen = preview.documentId && preview.contextName === PREVIEW_CONTEXT_NAME;

    return (
      <>
        <Box className="ag-theme-material" display="flex" flexDirection="column" height="100%">
          <AgGridReact
            masterDetail={hasMasterDetail}
            isRowMaster={(dataItem) => dataItem.hasMasterDetail}
            detailCellRenderer="myDetailCellRenderer"
            components={frameworkComponents}
            detailRowHeight={400}
            rowData={gridDataSource}
            onFilterChanged={onFilterChanged}
            onSortChanged={onSortChanged}
            onRowGroupOpened={onRowGroupOpened}
            onColumnPinned={handleSavedColumnEvent}
            onGridReady={onGridReady}
            onSelectionChanged={onSelelectionChanged}
            onDragStopped={onDragStopped}
            onColumnMoved={handleColumnMoved}
            onRangeSelectionChanged={onRangeSelectionChanged}
            onColumnResized={handleSavedColumnEvent}
            // props from useAgGridManualConfig hook
            enableRtl={enableRtl}
            getLocaleText={getLocaleText}
            rowClassRules={rowClassRules}
            // props from useAgGridDefaultConfig hook
            {...agGridDefaultProps}
            excelStyles={excelStyles}
            rowHeight={58}
            columnDefs={columnDefs}
          />
        </Box>

        {isPreviewOpen && (
          <DocumentPreviewNavigation
            documentId={preview.documentId}
            setPreview={setPreview}
            list={savedDocumentListDataWithFilters}
            allCount={savedDocumentListDataWithFilters.size}
            onContextMenu={onContextMenu}
            isContextMenuOpen={isContextMenuOpen}
            onClick={onClickOpen}
          />
        )}
      </>
    );
  },
);

const mapDispatchToProps = {
  documentsGridSetListIdWithFilters: documentsGridSetListIdWithFiltersAction,
  documentsGridDefaultColumnsApplied: documentsGridDefaultColumnsAppliedAction,
  documentsGridFilterApplied: documentsGridFilterAppliedAction,
  documentsGridSortingApplied: documentsGridSortingAppliedAction,
  documentsGridColumnsApplied: documentsGridColumnsAppliedAction,
};

export default compose(connect(null, mapDispatchToProps))(({ forwardedRef, ...props }) => (
  <Grid ref={forwardedRef} {...props} />
));

// @flow
import * as ACL from 'domain/restriction';
import { isFirefox } from 'lib/systemHelpers/browserHelpers';
import { type Document } from 'domain/documents/documentsModel';
import { Set } from 'immutable';

export type FilterParamsType = {|
  notes: string,
  doctype: string,
  restriction: number,
  status: string[],
  tags: $PropertyType<Document, 'tags'>,
  linkid: ?string,
|};

export const rights = (
  n: string,
  t: string,
  s: string[],
  r: number,
  tags: $PropertyType<Document, 'tags'> = new Set(),
  linkid: ?string,
) => ({
  notes: () => Boolean(n.length > 0),
  noNotes: () => n.length === 0,
  general: () => t === 'general',
  financial: () => t === 'financial',
  isNew: () => s.includes('new'),
  isNotAccepted: () => !s.includes('accepted'),
  readOnly: () => ACL.check(ACL.IS_USER, r),
  isAuthorized: () => ACL.check(ACL.IS_AUTHORIZED, r),
  isAccount: () => ACL.check(ACL.IS_ACCOUNT, r),
  isAccepted: () => s.includes('accepted'),
  isScheduledAcceptance: () => tags.has('_S_SCHEDULED_ACCEPTANCE'),
  companyDoesntSupportFinDoc: () => !ACL.check(ACL.IS_COMPANY_SUPPORT_FINANCIAL_DOC, r),
  allowCreateNotes: () => true,
  allowUpdateNotes: () => true,
  approvalNotInitiated: () => !tags.has('_S_APPROVAL_PENDING') && !tags.has('_S_APPROVAL_COMPLETE'),
  notLinked: () => !linkid,
});

export const localRights = ACL.advancedRights(rights);
// (d.notes, d.doctype, d.status, d.restriction

export const items = [
  {
    id: 'open',
    title: 'Open',
    disabled: false,
    action: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  {
    id: 'preview',
    title: 'Preview',
    action: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  {
    id: 'setAsCover',
    title: 'Set as cover',
    disabled: false,
    action: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  {
    id: 'linkDocuments',
    title: 'Link documents',
    disabled: false,
    action: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
    separator: true,
  },
  {
    id: 'editStickyNote',
    title: 'Edit sticky note',
    disabled: false,
    action: () => null,
    restriction: localRights.policy([localRights.paramsName.notes, localRights.paramsName.allowUpdateNotes]),
  },
  {
    id: 'addStickyNote',
    title: 'Add sticky note',
    disabled: false,
    action: () => null,
    restriction: localRights.policy([localRights.paramsName.noNotes, localRights.paramsName.allowCreateNotes]),
  },
  {
    id: 'tagsAs',
    title: 'Tags as',
    disabled: false,
    action: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  {
    id: 'markAsPaid',
    title: 'Mark as paid',
    disabled: false,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  {
    id: 'markAsUnpaid',
    title: 'Mark as unpaid',
    disabled: false,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  {
    id: 'markAsGeneral',
    title: 'Mark as general document',
    disabled: localRights.policy(localRights.paramsName.isAccepted, localRights.paramsName.isScheduledAcceptance),
    action: () => null,
    restriction: localRights.policy([localRights.paramsName.isAccount, localRights.paramsName.financial]),
  },
  {
    id: 'markAsFinancial',
    title: 'Mark as financial document',
    disabled: localRights.policy(localRights.paramsName.companyDoesntSupportFinDoc),
    action: () => null,
    restriction: localRights.policy([localRights.paramsName.isAccount, localRights.paramsName.general]),
  },
  {
    id: 'ignoreDocumentWarnings',
    title: 'Ignore warnings',
    disabled: false,
    action: () => null,
    restriction: localRights.policy([localRights.paramsName.isAuthorized]),
    separator: true,
  },
  {
    id: 'signDocument',
    title: 'Sign document',
    disabled: false,
    action: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
    separator: true,
  },
  {
    id: 'sendByEmail',
    title: 'Share via email',
    disabled: false,
    action: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  {
    id: 'downloadDocument',
    title: 'Download document',
    disabled: false,
    action: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  {
    id: 'editDocument',
    title: 'Edit document',
    disabled: false,
    action: () => null,
    restriction: localRights.policy([localRights.paramsName.isAuthorized, localRights.paramsName.approvalNotInitiated]),
  },
  {
    id: 'moveTo',
    title: 'Move to',
    disabled: false,
    action: () => null,
    restriction: localRights.policy([localRights.paramsName.isAccount]),
  },
  {
    id: 'linkedBundle',
    title: 'Linked bundle',
    disabled: false,
    action: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  {
    id: 'moveToCompany',
    title: 'Move to other company',
    action: () => null,
    restriction: localRights.policy(localRights.paramsName.isAccount),
  },
  {
    id: 'duplicateDocument',
    title: 'Duplicate document',
    disabled: false,
    action: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  {
    id: 'printDocument',
    title: 'Print document',
    disabled: isFirefox,
    action: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  {
    id: 'deleteDocument',
    title: 'Delete document',
    disabled: false,
    action: () => null,
    restriction: localRights.policy(localRights.paramsName.isAccount, [
      localRights.paramsName.readOnly,
      localRights.paramsName.isNotAccepted,
    ]),
    separator: true,
  },
];

export const linkedMenuItems = [
  {
    id: 'editLinkDocuments',
    title: 'Edit linked documents',
    disabled: false,
    action: () => null,
    restriction: localRights.policy(localRights.paramsName.isAccount),
  },
  {
    id: 'sendByEmailLinked',
    title: 'Share bundle via email',
    disabled: false,
    action: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  {
    id: 'downloadLinked',
    title: 'Download linked',
    disabled: false,
    action: () => null,
    restriction: localRights.policy(localRights.paramsName.isAccount),
  },
  {
    id: 'printLinked',
    title: 'Print linked',
    disabled: false,
    action: () => null,
    restriction: localRights.policy(localRights.paramsName.isAccount),
  },
  {
    id: 'unlink',
    title: 'Unlink documents',
    disabled: false,
    action: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  {
    id: 'moveTo',
    title: 'Move to',
    disabled: false,
    action: () => null,
    restriction: localRights.policy([localRights.paramsName.isAccount]),
  },
  {
    id: 'moveLinkedToCompany',
    title: 'Move bundle to company',
    action: () => null,
    restriction: localRights.policy(localRights.paramsName.isAccount),
  },
];

export const linkedBundleSubmenu = [
  {
    id: 'moveLinkedToCompany',
    title: 'Move bundle to company',
    action: () => null,
    restriction: localRights.policy(localRights.paramsName.isAccount),
  },
  {
    id: 'printLinked',
    title: 'Print linked',
    disabled: false,
    action: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  {
    id: 'sendByEmailLinked',
    title: 'Share bundle via email',
    disabled: false,
    action: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  {
    id: 'downloadLinked',
    title: 'Download linked',
    disabled: false,
    action: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
];

export const filtredLinkedItem = (d: FilterParamsType) =>
  linkedMenuItems.filter(ACL.arrayFilter(localRights.restriction(d.notes, d.doctype, d.status, d.restriction, d.tags)));

export const filtredItem = (d: FilterParamsType) => {
  const lr = localRights.restriction(d.notes, d.doctype, d.status, d.restriction, d.tags, d.linkid);
  const submenus = {
    linkedBundle: {
      submenu: linkedBundleSubmenu.filter(ACL.arrayFilter(lr)),
    },
  };
  return items
    .map((item) => ({ ...item, ...submenus[item.id] }))
    .filter(ACL.arrayFilter(lr))
    .map(ACL.arrayMap(lr, 'disabled'));
};

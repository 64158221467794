import { ColumnTypes } from 'pages/configurations/company/pages/approvals/components/Table/helpers';

export const Fields = {
  count: 'count',
  approver: 'approver',
};

export const HeadersAdapter = ({ formatMessage, handleDeleteApprover }) => {
  const headers = [
    {
      field: Fields.count,
      headerName: formatMessage({
        id: 'configurations.company.approvals.list.table.column.count',
        defaultMessage: 'No.',
      }),
      type: ColumnTypes.text,
      minWidth: 75,
      maxWidth: 75,
    },
    {
      field: Fields.approver,
      headerName: formatMessage({
        id: 'configurations.company.approvals.list.table.column.approver',
        defaultMessage: 'Approver',
      }),
      cellRenderer: ColumnTypes.approver,
    },
    {
      field: 'controls',
      cellRenderer: ColumnTypes.controls,
      width: 120,
      maxWidth: 120,
      cellRendererParams: {
        handleDeleteClick: handleDeleteApprover,
      },
    },
  ];

  return headers;
};

const RowFactory = ({ index, approver, ...rest }) => ({
  [Fields.count]: index,
  [Fields.approver]: approver,
  [ColumnTypes.controls]: approver,
  ...rest,
});

const RowAdapter = (approver, index) =>
  RowFactory(
    {
      index: index + 1,
      approver,
      id: approver.id,
    },
    [],
  );

export const RowsAdapter = (approvers) => approvers.toJS().map((a, index) => RowAdapter(a, index));

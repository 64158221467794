import React from 'react';

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import SvgIcon from '@mui/material/SvgIcon';

import MuiKeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ReactComponent as ParallelIcon } from './icons/ParallelArrows.svg';
import { ReactComponent as SequenceIcon } from './icons/SequenceArrows.svg';

import { LEVEL_FLOW_TYPE } from 'domain/approvals/constatns';

import styled from '@mui/material/styles/styled';
import { darken } from '@mui/material/styles';
import { green, orange, red } from '@mui/material/colors';

import elements from 'components/elements';

const [hover] = [0.05];

const sizes = {
  large: 1.5,
  medium: 1,
  small: 0.5,
};

const KeyboardArrowRightIcon = styled(MuiKeyboardArrowRightIcon, {
  shouldForwardProp: (prop) => prop !== 'arrowDirection',
})(({ theme, arrowDirection, fontSize }) => ({
  margin: theme.spacing(0, -sizes[fontSize]),
  transform: `rotate(${theme.direction === 'rtl' ? 180 : 0}deg)`,
  ...(arrowDirection === 'column' && { transform: 'rotate(90deg)' }),
}));

const createStatusColors = (theme, isLevel) => ({
  draft: {
    backgroundColor: theme.palette.common.white,
    color: 'inherit',
    '&&:hover': {
      backgroundColor: darken(theme.palette.common.white, hover),
    },
  },
  pending: {
    backgroundColor: isLevel ? orange[50] : orange[100],
    color: 'inherit',
    borderColor: isLevel ? theme.palette.warning.light : theme.palette.warning.main,
    '&&:hover': {
      backgroundColor: isLevel ? darken(orange[50], hover) : darken(orange[100], hover),
    },
  },
  approved: {
    backgroundColor: isLevel ? green[50] : green[100],
    color: 'inherit',
    borderColor: isLevel ? theme.palette.success.light : theme.palette.success.main,
    '&&:hover': {
      backgroundColor: isLevel ? darken(green[50], hover) : darken(green[100], hover),
    },
  },
  rejected: {
    backgroundColor: isLevel ? red[50] : red[100],
    color: 'inherit',
    borderColor: isLevel ? theme.palette.error.light : theme.palette.error.main,
    '&&:hover': {
      backgroundColor: isLevel ? darken(red[50], hover) : darken(red[100], hover),
    },
  },
});

const colors = {
  draft: undefined,
  pending: 'warning',
  approved: 'success',
  rejected: 'error',
};

const SChip = styled(Chip, { shouldForwardProp: (propName) => !['isLevel', 'direction'].includes(propName) })(
  ({ theme, status, isLevel, direction }) => ({
    ...(status
      ? createStatusColors(theme, isLevel)[status]
      : {
          backgroundColor: theme.palette.common.white,

          '& .MuiChip-deleteIcon': {
            color: theme.palette.grey[300],
          },
        }),
    ...(isLevel && direction === 'column' && { display: 'inline-table' }),
  }),
);

const getIconByType = (arrowIconType) => {
  switch (arrowIconType) {
    case LEVEL_FLOW_TYPE.PARALLEL:
      return ParallelIcon;
    case LEVEL_FLOW_TYPE.SEQUENTIAL:
      return SequenceIcon;
    default:
      return MuiKeyboardArrowRightIcon;
  }
};

const Approver = React.forwardRef(
  (
    {
      hasArrow = true,
      status,
      arrowDirection = 'row',
      isLevel = false,
      spacing = 1,
      size = 'medium',
      chipHeight = 'inherit',
      arrowIconType,
      sx,
      ...props
    },
    ref,
  ) => (
    <Stack justifyContent="center" alignItems="center" direction={arrowDirection} spacing={spacing} ref={ref} sx={sx}>
      <SChip
        data-element={elements.content.documents.approvals.approvalItem}
        variant="outlined"
        color={colors[status]}
        status={status}
        size={size}
        isLevel={isLevel}
        direction={arrowDirection}
        {...props}
      />
      {hasArrow && (
        <Stack>
          {arrowIconType ? (
            <SvgIcon component={getIconByType(arrowIconType)} inheritViewBox fontSize={size} />
          ) : (
            <KeyboardArrowRightIcon arrowDirection={arrowDirection} fontSize={size} />
          )}
        </Stack>
      )}
    </Stack>
  ),
);

export default Approver;

/* @flow */
import React, { memo, useState, useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { navigationSelector, rtlEnable } from 'domain/env';
import { nextDocumentAction, documentDocumentIdSelector, documentsClearLinkedAction } from 'domain/documents';
import { useConfirm } from 'material-ui-confirm';
import elements from 'components/elements';

import IconButton from '@mui/material/IconButton';
import { Navigation, NavigationPagination } from 'pages/document/DocumentNavigation/StyledComponents';

import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import LinkedDocsIcon from '@mui/icons-material/AttachFile';

type TDocumentNavigation = {
  hasConfirmBeforeLeave?: boolean,
};

type TNavDirection = 'prev' | 'next';

// 37 - left arrow
// 39 - right arrow
// 35 - end
// 36 - home
const keyCodes = [37, 39, 35, 36];

const mapStateToProps = (state) => ({
  navigation: navigationSelector(state),
  isRtl: rtlEnable(state),
  documentID: documentDocumentIdSelector(state),
});

const DocumentNavigation: React$StatelessFunctionalComponent<TDocumentNavigation> = ({
  hasConfirmBeforeLeave,
}: TDocumentNavigation) => {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const confirm = useConfirm();
  const { navigation, isRtl, documentID } = useSelector(mapStateToProps);
  const [nextPageShift, setNextPageShift] = useState(0);
  const [isTransitionComplete, setTransitionComplete] = useState(false);

  const { currentDocumentOrderNumber, totalNumberOfDocuments, isSetRequest } = useMemo(
    () => ({
      currentDocumentOrderNumber: navigation.get('currentDocumentOrderNumber'),
      totalNumberOfDocuments: navigation.get('totalNumberOfDocuments'),
      isSetRequest: navigation.get('isSetRequest'),
    }),
    [navigation],
  );

  const isNextDisabled = useMemo(() => !navigation.get('listLength') || navigation.get('listLength') < 2, [navigation]);
  const isPrevDisabled = useMemo(
    () => !navigation.get('fd') || documentID === navigation.get('fd'),
    [navigation, documentID],
  );

  const currentDocument = useMemo(() => currentDocumentOrderNumber || 1, [currentDocumentOrderNumber]);

  const total = useMemo(
    () => (totalNumberOfDocuments === 0 ? '-' : totalNumberOfDocuments || 1),
    [totalNumberOfDocuments],
  );

  const toNext = useCallback((shift: number) => dispatch(nextDocumentAction(shift)), [dispatch]);

  const toNextOrConfirmation = useCallback(
    (shift: number) => {
      if (!isTransitionComplete) {
        setTransitionComplete(true);
        const fn = hasConfirmBeforeLeave ? setNextPageShift : toNext;
        fn(shift);
      }
    },
    [hasConfirmBeforeLeave, toNext, isTransitionComplete],
  );

  const getDirection = useCallback(
    (defaultDirection: TNavDirection) => {
      if (isRtl && defaultDirection === 'prev') return 'next';
      if (isRtl && defaultDirection === 'next') return 'prev';

      return defaultDirection;
    },
    [isRtl],
  );

  const handleNext = useCallback(() => {
    if (isNextDisabled) return;
    toNextOrConfirmation(1);
  }, [isNextDisabled, toNextOrConfirmation]);

  const handlePrevious = useCallback(() => {
    if (isPrevDisabled) return;
    toNextOrConfirmation(-1);
  }, [isPrevDisabled, toNextOrConfirmation]);

  const handleNavigation = useCallback(
    (defaultDirection: TNavDirection) => {
      const direction = getDirection(defaultDirection);

      return direction === 'prev' ? handlePrevious : handleNext;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPrevDisabled, isNextDisabled, getDirection, handlePrevious, handleNext],
  );

  const onClickPrev = useCallback(() => {
    handleNavigation('prev')();
  }, [handleNavigation]);

  const onClickNext = useCallback(() => {
    handleNavigation('next')();
  }, [handleNavigation]);

  const handleDisabled = useCallback(
    (defaultDirection: TNavDirection) => {
      const direction = getDirection(defaultDirection);

      return direction === 'prev' ? isPrevDisabled : isNextDisabled;
    },
    [getDirection, isPrevDisabled, isNextDisabled],
  );

  const handleKeyDownNavigate = useCallback(
    (keyCode: number) => {
      switch (keyCode) {
        case 37: // 37 - left arrow
          handleNavigation('prev')();
          break;
        case 39: // 39 - right arrow
          handleNavigation('next')();
          break;
        case 35: // 35 - end
          if (currentDocument !== total) {
            toNextOrConfirmation(total - currentDocument);
          }
          break;
        case 36: // 36 - home
          if (currentDocument !== 1) {
            // few variants how back to first page
            // total + 1 = for current behavior we moved to first page
            // -currentDocument + 1 = get negative number(shift) and go to first page
            // eslint-disable-next-line max-len
            // currentDocument - ((currentDocument * 2) - 1) = get negative number(shift) and go to first page
            toNextOrConfirmation(-currentDocument + 1);
          }
          break;
        default:
          break;
      }
    },
    [total, handleNavigation, currentDocument, toNextOrConfirmation],
  );

  const handleKeyDown = useCallback(
    (e: SyntheticEvent<*>) => {
      const { keyCode, ctrlKey, metaKey } = e;
      const isCaught = (ctrlKey || metaKey) && keyCodes.includes(keyCode);

      if (isCaught) {
        e.stopPropagation();
        e.preventDefault();
        if (total < 2) return false;
        handleKeyDownNavigate(keyCode);
      }

      return false;
    },
    [total, handleKeyDownNavigate],
  );

  const onCancelConfirm = useCallback(() => {
    // on next attempt to go to next/prev doc this will remain true in case
    // user clicked cancel
    setTransitionComplete(false);
    setNextPageShift(0);
  }, []);

  const onConfirmNext = useCallback(() => {
    onCancelConfirm();
    toNext(nextPageShift);
  }, [nextPageShift, onCancelConfirm, toNext]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      if (!navigation.get('fromLinked')) {
        dispatch(documentsClearLinkedAction());
      }
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [dispatch, handleKeyDown, navigation]);

  useEffect(() => {
    if (nextPageShift !== 0) {
      confirm({
        title: formatMessage({
          id: 'document.leave.confirm.title',
          defaultMessage: 'Approval flow was not activated',
        }),
        description: formatMessage({
          id: 'document.leave.confirm.placeholder',
          defaultMessage: 'Are you sure you want to leave the document without Approval flow activation?',
        }),
        confirmationText: formatMessage({
          id: 'button.leave',
          defaultMessage: 'Leave',
        }),
        cancellationText: formatMessage({
          id: 'button.cancel',
          defaultMessage: 'Cancel',
        }),
        confirmationButtonProps: {
          coloe: 'error',
        },
      })
        .then(() => {
          onConfirmNext();
        })
        .catch(() => {
          onCancelConfirm();
        });
    }
  }, [nextPageShift, confirm, formatMessage, onCancelConfirm, onConfirmNext]);

  return (
    !isSetRequest && (
      <Navigation direction="row" dir="ltr" data-element={elements.content.documents.navigation.container}>
        <IconButton
          color="primary"
          onClick={onClickPrev}
          disabled={handleDisabled('prev')}
          data-element={elements.content.documents.navigation.prev}
        >
          <ChevronLeftOutlinedIcon />
        </IconButton>
        <NavigationPagination dir="ltr" isLinked={navigation.get('isLinked')}>
          {navigation.get('isLinked') && <LinkedDocsIcon />}
          <span>{currentDocument}</span>
          <span>/</span>
          <span>{total}</span>
        </NavigationPagination>
        <IconButton
          color="primary"
          onClick={onClickNext}
          disabled={handleDisabled('next')}
          data-element={elements.content.documents.navigation.next}
        >
          <ChevronRightOutlinedIcon />
        </IconButton>
      </Navigation>
    )
  );
};

export default memo(DocumentNavigation);

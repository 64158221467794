import { GRID_SERVICE_KEYS } from 'domain/documents/helpers';
import { IS_OPEN_CONTEXT_MENU_CLASS } from 'hooks/agGrid/agGridThemeClasses';
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';

const {
  PREVIEW_CHECKBOX_COLUMN_NAME,
  PREVIEW_BTN_COLUMN_NAME,
  COLUMNS_VISIBILITY_MENU,
  CONTEXT_MENU_COLUMN_NAME,
  COLUMNS_LINKED_ICON,
} = GRID_SERVICE_KEYS;

export default makeStyles((theme) => {
  const isRtl = theme.direction === 'rtl';
  const LEFT = isRtl ? 'right' : 'left';
  const RIGHT = isRtl ? 'left' : 'right';

  return {
    '@global': {
      '.ag-theme-material': {
        '&&, && .ag-header-row': {
          fontFamily: 'var(--ag-font-family)',
          fontSize: 'var(--ag-font-size)',
        },
        '& .ag-react-container': {
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
        },
        // for tooltip handler in filter set item
        '& .ag-set-filter-item .ag-react-container': {
          position: 'static',
        },
        '&': `
        --ag-font-family: ${theme.typography.fontFamily};
        --ag-font-size: 14px;
        --ag-material-primary-color: ${theme.palette.primary.main};
        --ag-material-accent-color: ${theme.palette.primary.main};
        // --ag-checkbox-indeterminate-color: ${theme.palette.primary.main};
        --ag-input-focus-border-color: ${theme.palette.primary.main};
        --ag-input-border-color-invalid: ${theme.palette.error.main};
        --ag-invalid-color: ${theme.palette.error.main};
        --ag-checkbox-unchecked-color: ${theme.palette.action.active};
        --ag-foreground-color: ${theme.palette.text.primary};
        --ag-header-foreground-color: ${theme.palette.text.primary};
        --ag-selected-row-background-color: ${alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)};
        --ag-range-selection-border-color: ${theme.palette.primary.main};
        --ag-row-hover-color: ${theme.palette.action.hover};
        --ag-header-background-color:  ${theme.palette.grey[100]};
        --ag-border-color:  ${theme.palette.grey[300]};
        --ag-background-color: ${theme.palette.common.white};
        --ag-cell-background-color-transparent: ${alpha(theme.palette.common.white, 0.4)};
        --ag-shadow-color: ${alpha(theme.palette.common.black, 0.5)};
      `,
        '&& .ag-theme-material .ag-picker-field-wrapper': {
          border: 'solid 1px',
          borderColor: 'var(--ag-border-color)',
        },

        '&& .ag-theme-material .ag-popup-child': {
          boxShadow: 'none',
        },

        '&& .ag-root-wrapper': {
          background: 'none !important',
        },

        '&& .ag-header': {
          marginBottom: 10,
          border: 'none',
          background: 'none',
          paddingRight: 8,
        },

        '&& .ag-header .ag-header-cell:not(.action-btns-header), .ag-header-viewport': {
          backgroundColor: 'var(--ag-header-background-color)',
        },

        '&& .ag-status-bar': {
          borderBottom: 'none',
          borderLeft: 'none',
          borderRight: 'none',
          backgroundColor: 'var(--ag-background-color)',
        },
        '&& .ag-row-group .ag-icon-tree-closed:before,&& .ag-row-group .ag-icon-tree-open:before': {
          fontSize: 22,
          lineHeight: '22px',
          color: theme.palette.action.active,
        },
        '&& .ag-row-group .ag-icon-tree-closed:before': {
          content: '"--ag-icon-font-code-small-right"',
        },
        '&& .ag-row-group .ag-icon-tree-open:before': {
          content: '"--ag-icon-font-code-small-down"',
        },
        '&& .ag-cell, && .ag-header-cell': {
          padding: theme.spacing(0, 2),
        },
        '&& .ag-cell-wrapper .ag-selection-checkbox': {
          marginLeft: theme.spacing(1),
          marginRight: 0,
        },

        '&& .ag-group-contracted, .ag-group-expanded': {
          margin: '0 !important',
        },

        '&& .ag-standard-button': {
          padding: theme.spacing(0.5, 1.25),
          borderRadius: 100,
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
            cursor: 'pointer',
          },
        },

        '&& .ag-header-cell-resize:before': {
          content: '""',
          position: 'absolute',
          zIndex: 1,
          left: 'calc(50% - 1px)',
          width: 2,
          height: '30%',
          top: '35%',
          backgroundColor: theme.palette.grey[300],
        },

        '&& input, && .ag-picker-field-wrapper.ag-picker-has-focus, && .ag-checkbox-input-wrapper, && .ag-floating-filter-button-button, && .ag-standard-button':
          {
            boxShadow: 'none!important',
            '&:focus': {
              borderColor: theme.palette.primary.main,
            },
          },

        '&& .ag-body-horizontal-scroll.ag-scrollbar-invisible': {
          pointerEvents: 'all !important',
        },

        '&& .cell-negative-number': {
          // Minus must be always on the left side DA-4725
          unicodeBidi: 'plaintext',
        },

        '&& .ag-header-row .action-btns-header': {
          display: 'none',
        },

        // !IMPORTANT
        // TECHNICAL PINNED COLUMNS with context menu
        [`&& .ag-pinned-${LEFT}-header .ag-header-row .ag-header-cell:nth-child(2)`]: {
          borderRadius: '8px 0 0 8px',
          border: '1px solid var(--ag-border-color)',
          borderRight: 'none',
        },

        [`&& .ag-pinned-${RIGHT}-header`]: {
          border: 'none',
        },

        [`&& .ag-pinned-right-header .ag-header-row .ag-header-cell[col-id="${PREVIEW_CHECKBOX_COLUMN_NAME}"]`]: {
          borderLeft: 'none /* @noflip */',
        },

        '&& .ag-header-row .ag-header-cell': {
          border: '1px solid var(--ag-border-color)',
          borderLeft: 'none',
          borderRight: 'none',
        },

        '&& .action-btns-header': {
          width: '0 !important',
          minWidth: '0 !important',
          display: 'none',
        },

        '&& .ag-cell.action-btns': {
          padding: 0,
        },

        '&& .ag-row .ag-cell:not(.action-btns)': {
          backgroundColor: 'var(--ag-cell-background-color-transparent)',
        },

        '&& .ag-row:not(.ag-row-hover) .action-btns': {
          opacity: 0, // for context menu element must be in DOM
        },

        [`
         && .ag-pinned-${LEFT}-cols-container .ag-row .ag-cell:not(.ag-cell[col-id="${CONTEXT_MENU_COLUMN_NAME}"])
        `]: {
          border: '1px solid var(--ag-border-color)',
          borderRight: 'none',
          borderLeft: 'none',
        },

        [`&& .ag-cell[col-id="${CONTEXT_MENU_COLUMN_NAME}"]`]: {
          border: 'none',
        },

        // context menu icon stay visible when menu is open
        [`&& .ag-cell.${IS_OPEN_CONTEXT_MENU_CLASS}[col-id="${CONTEXT_MENU_COLUMN_NAME}"]`]: {
          opacity: '1!important',
        },

        [`&& .ag-pinned-${LEFT}-cols-container .ag-row.ag-row-level-0 .ag-cell:nth-child(2)`]: {
          borderRadius: '8px 0 0 8px',
          border: '1px solid var(--ag-border-color)',
          borderRight: 'none',
        },

        '&& .ag-row.ag-row-level-0': {
          border: '1px solid var(--ag-border-color)',
          maxHeight: 48,
        },

        '&& .ag-row.ag-row-level-1': {
          backgroundColor: 'var(--ag-background-color) !important',
          width: 'calc(100% - 70px)',
          maxHeight: 390,
          marginLeft: 60,
          borderLeft: `3px solid ${theme.palette.common.black}`,
          outline: '1px solid var(--ag-border-color)',
          borderRadius: 8,
        },

        '&& .ag-pinned-left-cols-container .ag-row': {
          border: 'none',
        },

        [`&& .ag-pinned-${LEFT}-cols-container .ag-row .ag-cell.ag-cell-last-${LEFT}-pinned`]: {
          boxShadow: '5px 0px 5px -6px var(--ag-shadow-color)',
        },

        [`&& .ag-pinned-${LEFT}-cols-container .ag-row .ag-cell.ag-cell-first-${LEFT}-pinned`]: {
          boxShadow: '5px 0px 5px -6px var(--ag-shadow-color)',
        },

        [`&& .ag-pinned-${RIGHT}-cols-container .ag-row .ag-cell.ag-cell-last-${RIGHT}-pinned`]: {
          boxShadow: '-5px 0px 5px -6px var(--ag-shadow-color)',
        },

        [`&& .ag-pinned-${RIGHT}-cols-container .ag-row .ag-cell.ag-cell-first-${RIGHT}-pinned`]: {
          boxShadow: '-5px 0px 5px -6px var(--ag-shadow-color)',
        },

        '&& .ag-pinned-right-cols-container .ag-row': {
          border: 'none',
        },

        [`&& .ag-pinned-${RIGHT}-cols-container .ag-row .ag-cell`]: {
          border: '1px solid var(--ag-border-color) !important',
          borderRight: 'none',
          borderLeft: 'none',
        },

        '&& .ag-center-cols-container .ag-row': {
          borderLeft: 'none',
          borderRight: 'none',
        },

        [`
        && .ag-cell[col-id="${PREVIEW_CHECKBOX_COLUMN_NAME}"],
        && .ag-header-cell[col-id="${PREVIEW_CHECKBOX_COLUMN_NAME}"]
      `]: {
          border: 'none',
        },

        [`
        && .ag-header-cell[col-id="${PREVIEW_CHECKBOX_COLUMN_NAME}"]
      `]: {
          flexDirection: 'row-reverse',
        },

        [`
        && .ag-header-cell[col-id="${PREVIEW_CHECKBOX_COLUMN_NAME}"] .ag-checkbox
      `]: {
          margin: 0,
        },

        [`&& .ag-cell[col-id="${PREVIEW_CHECKBOX_COLUMN_NAME}"] .ag-cell-wrapper`]: {
          flexDirection: 'row-reverse',
        },

        [`&& .ag-cell[col-id="${PREVIEW_CHECKBOX_COLUMN_NAME}"] .ag-range-handle,
          && .ag-cell[col-id="${PREVIEW_BTN_COLUMN_NAME}"] .ag-range-handle,
          && .ag-cell[col-id="${CONTEXT_MENU_COLUMN_NAME}"] .ag-range-handle,
          && .ag-cell[col-id="${COLUMNS_LINKED_ICON}"] .ag-range-handle,
          && .ag-cell[col-id="${COLUMNS_VISIBILITY_MENU}"] .ag-range-handle`]: {
          width: 0,
        },

        [`&& .ag-row.ag-row-group-expanded .ag-cell[col-id="${PREVIEW_CHECKBOX_COLUMN_NAME}"]`]: {
          paddingLeft: 0,
        },

        [`&& .ag-row-group-expanded .ag-cell[col-id="${PREVIEW_CHECKBOX_COLUMN_NAME}"] > .ag-cell-wrapper`]: {
          borderLeft: `3px solid ${theme.palette.common.black}`,
          borderRadius: '4px 0 0 4px',
        },

        [`&& .ag-row .ag-cell[col-id="${COLUMNS_VISIBILITY_MENU}"]`]: {
          borderRight: '1px solid var(--ag-border-color) !important',
          borderRadius: '0 8px 8px 0',
        },
        // !IMPORTANT
        // TECHNICAL VISIBILITY COLUMNS always latest
        [`&& .ag-header-cell[col-id="${COLUMNS_VISIBILITY_MENU}"]`]: {
          padding: theme.spacing(0, 0.5),
          borderRadius: '0 8px 8px 0',
          border: '1px solid var(--ag-border-color) !important',
        },

        '& .ag-row': {
          background: 'none !important',
        },

        '& .ag-row:before': {
          background: 'none',
        },

        '& .ag-row-hover': {
          background: 'none !important',
        },

        '& .ag-row-selected': {
          backgroundColor: 'transparent !important',
        },

        '& .ag-row.ag-row-level-0.ag-row-hover .ag-cell:not(.action-btns)': {
          backgroundColor: 'var(--ag-row-hover-color) !important',
        },
        '& .ag-row.ag-row-level-0.ag-row-selected .ag-cell:not(.action-btns)': {
          backgroundColor: 'var(--ag-selected-row-background-color) !important',
        },

        '& .ag-row.ag-row-level-0 .ag-cell.ag-cell-range-selected-1:not(.ag-column-hover)': {
          backgroundColor: 'var(--ag-range-selection-background-color ,rgba(122,134,203,.1)) !important',
        },

        '& .ag-row.ag-row-level-0 .ag-cell.ag-cell-range-selected:not(.ag-column-hover)': {
          backgroundColor: 'var(--ag-range-selection-background-color ,rgba(122,134,203,.1)) !important',
        },
        '& .ag-row.ag-row-level-0 .ag-cell.ag-cell-range-selected.ag-cell-range-left.ag-cell-range-right': {
          border: '1px solid var(--ag-range-selection-border-color)',
        },

        '& .ag-body-viewport.ag-layout-normal': {
          overflowX: 'scroll',
          overflowY: 'scroll',
        },

        '& ::-webkit-scrollbar': {
          '-webkit-appearance': 'none',
          width: 8,
          height: 8,
        },
        '& ::-webkit-scrollbar-thumb': {
          borderRadius: 4,
          backgroundColor: alpha(theme.palette.common.black, 0.5),
          boxShadow: `0 0 1px ${alpha(theme.palette.common.white, 0.5)}`,
        },

        '& .ag-center-cols-viewport::-webkit-scrollbar': {
          display: 'none !important',
        },

        '& .ag-body-horizontal-scroll.ag-scrollbar-invisible.ag-apple-scrollbar': {
          opacity: 1,
          visibility: 'visible',
        },
        '& .ag-checkbox-cell': {
          justifyContent: 'center',
        },
      },
    },
  };
});

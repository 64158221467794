// @flow
import React from 'react';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import { STable, STableRow, STableHeaderCell, STableCell } from './StyledComponents';

import { createCellStyle, createRow } from './helpers';

import elements from 'components/elements';

const Table = ({ columnDefs, rowData, frameworkComponents }) => (
  <TableContainer>
    <STable>
      <TableHead>
        <TableRow data-element={elements.configuration.company.approvals.table.headerRow}>
          {columnDefs.map((header) => (
            <STableHeaderCell key={header.field} style={createCellStyle(header)}>
              {header.headerName}
            </STableHeaderCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rowData.map((r) => (
          <STableRow key={r.id} data-element={elements.configuration.company.approvals.table.row}>
            {createRow({
              rowData: r,
              columnDefs,
              renderCell: ({ header, value, key }) => (
                <STableCell
                  key={key}
                  style={createCellStyle(header)}
                  data-element={`${elements.configuration.company.approvals.table.cell}.${key}`}
                >
                  {frameworkComponents[header.cellRenderer]?.({ ...header.cellRendererParams, value }) || (
                    <Typography variant="body2">{value}</Typography>
                  )}
                </STableCell>
              ),
            })}
          </STableRow>
        ))}
      </TableBody>
    </STable>
  </TableContainer>
);

export default Table;
